import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions,getInfo } from '../../../actions/index';
import { withTranslation } from 'react-i18next';
class EditAdmin1User extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data.item;
        this.state = {
            user:{
                id:EditContent.id,
                firstName:EditContent.fullName.split(' ')[0],
                lastName:EditContent.fullName.split(' ')[1],
                address:EditContent.address,
                soc_card:EditContent.soc_card,
                position:EditContent.position,
                email:EditContent.email,
                phone:EditContent.phone,
                statusId:EditContent.statusId,
                companyId:EditContent.companyId
            },
            azsList:''

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_status_company_list({}, "get_status_company_list"))
        dispatch(getInfo.get_admin_transactions_balance({}, "get_admin1_head_offices"))
        if(!this.state.companyId){
            dispatch(getInfo.get_status_company_list_edit({id: this.state.user.id}, "get_status_company_list_edit"))
        }

    }
    componentWillReceiveProps(props){
        if(props.data1 && this.state.companyId){
            this.setState({
                azsList: props.data1.azsList
            });
        }
        if(props.data5 && !this.state.companyId){
            this.setState({
                azsList: props.data5
            });
        }
    }
    handleChangeMulty2 =(e, action) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { azsList } = this.state;
        let azsList1 = azsList.map((item)=>{
            if(item.stationId == name){
                return  {...item, checked: value}
            }
            else return item
        })
        this.setState({
            azsList: azsList1

        });

    }
    handleChangeMulty3 =(e, action) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { azsList } = this.state;
        let azsList1 = azsList.map((item)=>{
            return  {...item, checked: value}

        })
        this.setState({
            azsList: azsList1
        });

    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        if(name==='statusId'){
            this.setState({
                user: {
                    ...user,
                    [name]: value,
                    companyId: ''
                }
            });
        }
        else{
            this.setState({
                user: {
                    ...user,
                    [name]: value
                }
            });
        }

    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        const azsList = this.state.azsList.filter(item=>item.checked).map(item => {
            return {id: item.stationId}
        });
        if(azsList.length && (user.statusId == 512 || user.statusId == 8192) ){
            dispatch(userActions.EditTable({...user, companyId: null,  azsList},'edit_admin_user'));
        }
        else{
            dispatch(userActions.EditTable({...user, azsList: null},'edit_admin_user'));
        }
        // dispatch(userActions.EditTable(user,'edit_admin_user'));
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
        const { data1, data2 } = this.props;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('edit_user')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    <label className={message&& message.firstName &&'error_lab'}>
                        <span className="label_txt">{t('firstname')}</span>
                        <input type="text" className="form-control" name="firstName" value={user.firstName} onChange={this.handleChangeInput}/>
                        {message&& message.firstName && <span className="error_mes">{message.firstName[0]}</span> }
                    </label>
                    <label className={message&& message.lastName &&'error_lab'}>
                        <span className="label_txt">{t('lastname')}</span>
                        <input type="text" className="form-control" name="lastName" value={user.lastName} onChange={this.handleChangeInput}/>
                        {message&& message.lastName && <span className="error_mes">{message.lastName[0]}</span> }
                    </label>
                    <label className={message&& message.address &&'error_lab'}>
                        <span className="label_txt">{t('address')}</span>
                        <input type="text" className="form-control" name="address" value={user.address} onChange={this.handleChangeInput}/>
                        {message&& message.address && <span className="error_mes">{message.address[0]}</span> }
                    </label>
                    <label className={message&& message.soc_card &&'error_lab'}>
                        <span className="label_txt">{t('soc_card')}</span>
                        <input type="text" className="form-control" name="soc_card" value={user.soc_card} onChange={this.handleChangeInput}/>
                        {message&& message.soc_card && <span className="error_mes">{message.soc_card[0]}</span> }
                    </label>
                    <label className={message&& message.position &&'error_lab'}>
                        <span className="label_txt">{t('position')}</span>
                        <input type="text" className="form-control" name="position" value={user.position} onChange={this.handleChangeInput}/>
                        {message&& message.position && <span className="error_mes">{message.position[0]}</span> }
                    </label>
                    <label className={message&& message.email &&'error_lab'}>
                        <span className="label_txt">{t('email')}</span>
                        <input type="text" className="form-control" name="email" value={user.email} onChange={this.handleChangeInput}/>
                        {message&& message.email && <span className="error_mes">{message.email[0]}</span> }
                    </label>
                    <label className={message&& message.phone &&'error_lab'}>
                        <span className="label_txt">{t('phone')}</span>
                        <input type="text" className="form-control" name="phone" value={user.phone} onChange={this.handleChangeInput}/>
                        {message&& message.phone && <span className="error_mes">{message.phone[0]}</span> }
                    </label>
                    <label>
                        <span className="label_txt">{t('role')}</span>
                        <select name="statusId" value={user.statusId}  onChange={this.handleChangeInput}>
                            {   (data1 && data1.status && data1.status.length) && data1.status.map(item=>
                                <option value={item.id} key={item.id}>{item.name}</option>
                            )
                            }
                        </select>
                    </label>
                    {/*<label>*/}
                        {/*<span className="label_txt">Կազմակերպություն</span>*/}
                        {/*<select name="companyId" value={user.companyId}  onChange={this.handleChangeInput}>*/}
                            {/*{   (data1 && data1.company && data1.company.length) && data1.company.map(item=>*/}
                                {/*<option value={item.id} key={item.id}>{item.name}</option>*/}
                            {/*)*/}
                            {/*}*/}
                        {/*</select>*/}
                    {/*</label>*/}

                    {user && user.statusId && (user.statusId != 512 && user.statusId != 8192 ) &&
                    <label>
                        <span className="label_txt">{t('company_name')}</span>
                        <select name="companyId" value={user.companyId} onChange={this.handleChangeInput}>
                            <option value='' >{t('choose_company_name')}</option>
                            {   (data1 && data1.company && data1.company.length && user.statusId == 8) && data1.company.map(item =>
                                <option value={item.id} key={item.id}>{item.name}</option>
                            )
                            }
                            {   (data2 && data2.data.length && user.statusId == 16) && data2.data.map(item =>
                                <option value={item.value} key={item.value}>{item.label}</option>
                            )
                            }
                        </select>
                    </label>
                    }
                    {user && (user.statusId == 512 || user.statusId == 8192 ) &&
                    <div className="table-responsive fix-header">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th><input type="checkbox" onChange={e=>this.handleChangeMulty3(e)}/> {t('choose_gas_station')}</th>
                                <th>Station ID</th>
                                <th>{t('gas_station_chain')}</th>
                                <th>{t('gas_station')}</th>
                                <th>{t('address')}</th>
                                <th>{t('fuel_type')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/*0: {stationId: 17, fuelType: Array(1), address: "Խորենացի 21", azsName: "Ֆլեշ1", azsNetworkName: "«Ֆլեշ» ՍՊԸ"}*/}
                            {this.state.azsList  && this.state.azsList.length?
                                this.state.azsList .map( item =>
                                    <tr key={item.stationId}>
                                        <td>
                                            <input type="checkbox" name={item.stationId}  checked={item.checked} onChange={e=>this.handleChangeMulty2(e)}/>
                                        </td>
                                        <td>{item.stationId}</td>
                                        <td>{item.azsNetworkName}</td>
                                        <td>{item.azsName}</td>
                                        <td>{item.address}</td>
                                        <td>
                                            {item.fuelType && item.fuelType.length ?
                                                item.fuelType.map((item1, index) => {
                                                        if (index !== (item.fuelType.length - 1)) {
                                                            return `${item1.name}, `
                                                        }
                                                        else return item1.name
                                                    }
                                                ) : ''
                                            }
                                        </td>
                                    </tr>
                                ):false}

                            </tbody>
                        </table>
                    </div>
                    }

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const data1 = state.get_status_company_list.data1
    const data5 = state.get_status_company_list_edit.data5
    const data2 = state.get_admin_transactions_balance.data1
    return {
        modals,
        alert,
        data1,
        data2,
        data5
    };
}

export default connect(mapStateToProps)(withTranslation()(EditAdmin1User));
