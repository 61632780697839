import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  modalActions, userActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { Table } from 'reactstrap';
import FileBase64 from 'react-file-base64';
import {userService} from "../../../services";
import { withTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import * as moment from "moment";

class Cards1 extends Component {

    constructor(props) {
        super(props);
        this.state={
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                pageSize :10,
                searchToDate: null
            },
            removeSearch: false,
            pageRangeDisplayed: 5,
            searchToDate: null

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(event) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        });
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_attached_cards"))
        });

    }
    SortClass(event){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_attached_cards"))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_admin_attached_cards"))
    }
    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(getInfo.clear_table())
    }
    onBtnClick(event, data){
        const { dispatch } = this.props;
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_attached_cards"))
        });
    }
    handleSubmit =(event)=>{
        event.preventDefault();
        const {dispatch}= this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_admin_attached_cards"))
        const { tableData } = this.state;
        if(tableData.searchValue != ''){
            this.setState({
                removeSearch: true
            })
        }
    }
    // sendFile =(file)=>{
    //     const {dispatch}= this.props;
    //     dispatch(userActions.sendFile(file, "add_cards_admin"));
    //     $('.inp_file input').val(null);
    // }
    removeSearch =()=>{
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_attached_cards"))
        });
    }
    downloadExcel= (method, name, data) =>{
        userService.downloadExcel(method, data).then(response => {
            if(response.ok){
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${name}.xlsx`;
                    a.click();
                })
            }
            else{
                this.props.dispatch(modalActions.open_modal('Fail_popup','Bad request'));
            }

        });
    }
    onChangeDate = (date) => {
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchToDate: moment(new Date(date)).format('L')
            },
            searchToDate: date

        })
    }
    render() {
        const userRole=JSON.parse( localStorage.getItem('userRole'));
        const {data, Count} =this.props;
        const {tableData} =this.state;
		const { t } = this.props;
        return (
            <div>
                <div className="top_container_search">
                    <form onSubmit={this.handleSubmit} className="form_search m_form">
                        {(userRole ==='operator2' || userRole ==='operator2Viewer') &&
                            <div className="calendar_cont mrg_l" >
                                <span className="label_txt">{t('date')}</span>
                                <DatePicker
                                    selected={this.state.searchToDate}
                                    onChange={date => this.onChangeDate(date)}
                                    dateFormat="MMMM d, yyyy"
                                />
                            </div>
                        }

                        <label className="search">
                            <span className="label_txt">{t('search')}</span>
                            <input type="text" value={tableData.searchValue} onChange={this.handleChange} placeholder="" className="form-control"/>
                            {this.state.removeSearch && <span className="remove_search" onClick={this.removeSearch}>X</span> }
                        </label>
                        <button  className="search1">{t('search_now')}</button>
                        <div className="add_but exel_but_trans" onClick={()=>this.downloadExcel('get_cards_excel', t('fuel_cards'), this.state.tableData)}>{t('export')}</div>
                    </form>
                    {(userRole==='admin1' || userRole==='superAdmin' || userRole ==='operator2' ) && <div className="but_cont_search">
                        {/*{userRole !=='operator2'  &&*/}
                        {/*    <label className="add_but inp_file">*/}
                        {/*        <FileBase64*/}
                        {/*            multiple={ false }*/}
                        {/*            onDone={ this.sendFile}*/}
                        {/*        />*/}
                        {/*        <span>{t('add_new_cards')}</span>*/}
                        {/*    </label>*/}
                        {/*}*/}
                        {userRole !=='operator2Viewer'  && <div className="add_but but2"  onClick={(e) => { this.onBtnClick(e, 'card1' ) }}  data_modal="AttachCardAdmin1">{t('attach_cards')}</div> }
                    </div>}
                </div>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="card">{t('by_card')}</option>
                    <option value="corporateClient">{userRole ==='operator2' ? t('by_branch') : t('cor_client')} </option>
                    <option value="driver">{t('by_driver')}</option>
                    <option value="auto">{t('by_car')}</option>
                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="card">{t('card')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="corporateClient">{userRole==='operator2' ? t('branch') : t('corp_client')} </th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="driver">{t('driver')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="auto">{t('car')}</th>
                        <th  nowrap='true'>{t('gas_stations')}</th>
                        <th  nowrap='true'>{t('fuel')}</th>

                        {(userRole==='superAdmin' || userRole==='operator2') && <th >{t('action')}</th>}
                    </tr>
                    </thead>
                        <tbody>{data ? data.map((item, index)=>
                            <tr key={index}>
                                <td style={{width:'3%'}}  nowrap='true'><div className='table_head_txt'>{t('number')}:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('card')}։</div>{item.card}</td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('corp_client')}։</div>{item.corporateClient}</td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('driver')}։</div>{item.driver}</td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('car')}։</div>{item.auto}</td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('gas_stations')}։</div>
                                    {item.azsList && item.azsList.length ?
                                        item.azsList.map((item1, index)=>{
                                            if(index!==(item.azsList.length - 1)){
                                                return `${item1.azs_Name}, `
                                            }
                                            else return item1.azs_Name
                                        }

                                        ):''
                                    }
                                    {item.selectAll === 'true' &&
                                    'Բոլորը'}
                                </td>
                                <td style={{width:'35%'}} nowrap='true'><div className='table_head_txt'>{t('fuel')}։</div>
                                    <Table responsive striped>
                                        <thead>
                                            <tr>
                                                <th nowrap='true'>{t('balance')}</th>
                                                <th>{t('fuel_type')}</th>
                                                <th>{t('daily_limit')}</th>
                                                <th>{t('one_time_limit')}</th>
                                                <th>{t('month_limit')}</th>
                                                <th>{t('company_limit')}</th>

                                            </tr>

                                        </thead>
                                        <tbody>
                                            {item.balance && item.balance.length ?
                                                item.balance.map(item1 =>
                                                    <tr key={item1.fuelType}>
                                                        <td>
                                                            <div className='table_head_txt'>{t('balance')}։</div>
                                                            {item1.balance} {item1.fuelUnit}</td>
                                                        <td>
                                                            <div className='table_head_txt'>{t('fuel_type')}։</div>
                                                            {item1.fuelType}</td>
                                                        <td>
                                                            <div className='table_head_txt'>{t('daily_limit')}։</div>
                                                            {item1.dayLimit} {item1.fuelUnit}</td>
                                                        <td>
                                                            <div className='table_head_txt'>{t('one_time_limit')}։</div>
                                                            {item1.oneLimit} {item1.fuelUnit}</td>
                                                        <td>
                                                            <div className='table_head_txt'>{t('month_limit')}։</div>
                                                            {item1.monthLimit} {item1.fuelUnit}</td>
                                                        <td>
                                                            <div className='table_head_txt'>{t('company_limit')}։</div>
                                                            {item1.corpLimit?t('in_use'):''}</td>
                                                    </tr>
                                                ):''}

                                        </tbody>

                                    </Table>
                                </td>


                                {( userRole==='superAdmin' || userRole==='operator2') && <td style={{width:'10%'}} nowrap='true' className="actions">
                                    <div className='table_head_txt'>{t('action')}:</div>
                                    <span className="but_table"  onClick={(e) => { this.onBtnClick(e, item ) }}  data_modal="UnpinCardAdmin1">{t('remove_card')}</span>
                                </td>}
                            </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>
                {/*<AppTable />*/}

            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data, Count} = state.getTable
    return {
        data,
        Count
    };
}

export default connect(mapStateToProps)(withTranslation()(Cards1));