import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  modalActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { Table } from 'reactstrap';

class RefuelingCardHolder extends Component {

    constructor(props) {
        super(props);
        this.state={
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                pageSize :10,
            },
            removeSearch: false,
            pageRangeDisplayed: 5

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(event) {
        $('table tr').removeClass('edited')
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        });
    }
    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(getInfo.clear_table())
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_card_holder_refueling"))
        });

    }
    SortClass(event){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_card_holder_refueling"))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_card_holder_refueling"))
    }

// && tableData.sortValue == this.value
    onBtnClick(event, data){
        const { dispatch } = this.props;
        // $(this).parent().parent()
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    //
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_card_holder_refueling"))
        });
    }
    handleSubmit =(event)=>{
        event.preventDefault();
        const {dispatch}= this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_card_holder_refueling"))
        const { tableData } = this.state;
        if(tableData.searchValue != ''){
            this.setState({
                removeSearch: true
            })
        }
    }
    removeSearch =()=>{
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_card_holder_refueling"))
        });
    }
    onBtnClick1(event, data){
        const { dispatch } = this.props;
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    render() {
        const {data, Count} =this.props
        const {tableData} =this.state;
        return (
            <div className="main_container">
                <div className="top_container_search">
                    <form onSubmit={this.handleSubmit} className="form_search m_form">
                        <label className="search">
                            <span className="label_txt">Որոնում</span>
                            <input type="text" value={tableData.searchValue} onChange={this.handleChange} placeholder="" className="form-control"/>
                            {this.state.removeSearch && <span className="remove_search" onClick={this.removeSearch}>X</span> }
                        </label>
                        <button  className="search1">Որոնել</button>
                    </form>
                </div>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">Սորտավորել ըստ</option>
                    <option value="azsNetworkName">Լցակայանի ցանցի անունի</option>
                    <option value="azsName">Լցակայանի  անունի</option>
                    <option value="address">Հասցեի</option>
                    <option value="phone">Հեռախոսահամարի</option>
                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th  className="clickable" nowrap='true' onClick={this.handleSort} data-val="azsNetworkName">Լցակայանի ցանցի անուն</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="azsName">Լցակայանի  անուն</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="address">Հասցե</th>
                        <th nowrap='true' >Վառելիքի տեսակ</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="phone">Հեռախոսահամար</th>

                    </tr>
                    </thead>
                        <tbody>{data ? data.map((item, index)=>
                           <tr key={index}>
                                <td style={{width:'3%'}}  nowrap='true'><div className='table_head_txt'>Համար:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                                <td style={{width:'15%'}}  nowrap='true' ><div className='table_head_txt'>Լցակայանի ցանցի անուն։</div>{item.azsNetworkName}</td>
                                <td style={{width:'15%'}}  nowrap='true' ><div className='table_head_txt'>Լցակայանի  անուն։</div>{item.azsName}</td>
                                <td style={{width:'10%'}} nowrap='true' className="address_table">
                                    <div className='table_head_txt'>Հասցե: </div>
                                <span onClick={(e) => { this.onBtnClick1(e, {lat:item.lat, long:item.long} ) }}  data_modal="Map">{item.address}</span>
                                </td>
                                <td style={{width:'12%'}}  nowrap='true' ><div className='table_head_txt'>Վառելիքի տեսակ։</div>
                                {item.fuelType && item.fuelType && Array.isArray( item.fuelType ) && item.fuelType.length ?
                                    item.fuelType.map((item1, index) => {
                                            if (index !== (item.fuelType.length - 1)) {
                                                return `${item1.name}, `
                                            }
                                            else return item1.name
                                        }
                                    ) : ''
                                }
                                </td>
                                <td style={{width:'10%'}}  nowrap='true' ><div className='table_head_txt'>Հեռախոսահամար</div>{item.phone}</td>
                            </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>First</i>}
                            lastPageText={<i className='b_link1'>Last</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data, Count} = state.getTable
    return {
        data,
        Count
    };
}

const connectedRefuelingCardHolder = connect(mapStateToProps)(RefuelingCardHolder);
export { connectedRefuelingCardHolder as RefuelingCardHolder };