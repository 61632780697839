import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { withTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: null,
        }
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_balance({},  "get_balance"))
    }
    onChangeDate = (date) => {
        const { dispatch } = this.props;
        this.setState({date})
        dispatch(getInfo.get_balance({searchToDate: date},  "get_balance"))

    }

    onBtnClick =(event, data)=>{
        const { dispatch } = this.props;
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }

    render() {
        const userRole=JSON.parse( localStorage.getItem('userRole'));
        const {data1} =this.props;
		const { t } = this.props;
        return (
            <div className="main_container">
                <div className="balance">
                    <div className="d-flex">
                        {(userRole === 'operator1' || userRole === 'operator1Viewer') && <span> {data1 && data1.CorpName}</span>}
                        {(userRole === 'operator2' || userRole === 'operator2Viewer') && data1 && data1.balance && data1.balance.map((item, index) =>
                                index===0 && <span> {item.corpName}</span>
                            )
                        }
                    </div>
                </div>
                <div className="row">
                        <div className="cont2 mod_dashb">
                            <div className="widget color2">
                                <div className="table-responsive">
                                    {(userRole === 'operator1' || userRole === 'operator2' || userRole === 'operator1Viewer') &&
                                        <div className="calendar_cont mrg_l" style={{marginBottom: '15px'}}>
                                            <span className="label_txt">{t('date')}</span>
                                            <DatePicker
                                                isClearable
                                                onClearClick={()=> this.onChangeDate('')}
                                                selected={this.state.date}
                                                onChange={date => this.onChangeDate(date)}
                                                dateFormat="MMMM d, yyyy"
                                            />
                                        </div>
                                    }
                                      <table className="table table-striped">
                                          <thead>
                                            <tr>
                                                {(userRole === 'operator1' || userRole === 'operator1Viewer') && <th>{t('name_tax_code')}</th>}
                                                <th>{t('fuel')}</th>
                                                <th>{t('volume')}</th>
                                                <th>{t('unit')}</th>
                                                <th>{t('unit_price')}</th>
                                                <th>{t('summary')}</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {data1 && data1.balance && data1.balance.map((item, index)=>
                                              <tr key={index}>
                                                  {(userRole==='operator1'|| userRole === 'operator1Viewer') && <td><div className='table_head_txt'>{t('name_tax_code')}:</div>{item.corpName}</td>}
                                                  <td><div className='table_head_txt'>{t('fuel')}:</div>{item.fuelType}</td>
                                                  <td><div className='table_head_txt'>{t('volume')}:</div>{item.fuelQty}</td>
                                                  <td><div className='table_head_txt'>{t('unit')}:</div>{item.fuelUnit}</td>
                                                  <td><div className='table_head_txt'>{t('unit_price')}:</div>{item.fuelPrice} AMD</td>
                                                  <td><div className='table_head_txt'>{t('price')}:</div>{item.moneyAmount} AMD</td>
                                              </tr>
                                          )}

                                          </tbody>
                                      </table>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data1} = state.GetBalance
    return {
        data1
    };
}

const connectedDashboard = connect(mapStateToProps)(withTranslation()(Dashboard));
export { connectedDashboard as Dashboard };