import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions,modalActions,alertActions } from '../../actions/index';
class Fail_popup extends Component{
    constructor(props){
        super(props)



    }
    componentDidMount(){
        const EditContent =this.props.modals.data.error_description;
        if(EditContent == "Client is not authorized."){
            const { dispatch } = this.props;
            dispatch(modalActions.close_modal())
            dispatch(alertActions.clear())
            dispatch(userActions.logout())
        }
    }

    render() {

        return (
            <form  className="m_form  d-flex flex-column">

                <div  className="m_form  d-flex flex-column">
                    <span className="fa fa-warning"  ></span>
                    <div className="head_mess"> {(this.props.modals.data && this.props.modals.data.error_description && this.props.modals.data.error_description!= "Client is not authorized.")?this.props.modals.data.error_description: (this.props.modals.data && !this.props.modals.data.error_description)? this.props.modals.data:'' }</div>


                </div>
            </form>
        )
    }
}
function mapStateToProps(state) {
    const {modals} = state
    return {
        modals
    };
}
export default connect(mapStateToProps)(Fail_popup);
