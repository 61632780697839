import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions';
import { alertActions } from '../../../actions';
import { userActions } from '../../../actions';
import { withTranslation } from 'react-i18next';
import {Table} from "reactstrap";

class ApproveReplenish extends Component{
    constructor(props){
        super(props)
        const content =this.props.modals.data;
        this.state = {
            data: content.sendData,
            title: content.title,
            replenish: content.replenish,
            loader: false

        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    async handleSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        const { data, replenish } = this.state;
        this.setState({ loader: true });

        try {
            let method = replenish ? "add_admin_transactions_balance" : "return_admin_transactions_balance";

            const sendData = {
                ...data,
                fuelTypeDetails: data.fuelTypeDetails.map(item => ({
                    fuel_amount: Number(item.fuelAmount),
                    fuel_type_id: item.fuelType,
                    price: Number(item.price)
                }))
            };

            await dispatch(userActions.add_transaction(sendData, method));
            this.setState({ loader: false });
        } catch (error) {
            this.setState({ loader: false });
        }
    }    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }

    render() {
        const { t } = this.props;
        const { data, title, replenish, loader } = this.state;
        return (
            <div>
                <div className="modal_head">{title}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                    <Table responsive striped>
                        <thead>
                        <tr>
                            <th nowrap='true'>{t('fuel_type')}</th>
                            <th nowrap='true'>{t('volume')}</th>
                            {replenish &&  <th nowrap='true'>{t('unit_price_all')}</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.fuelTypeDetails.length ?
                            data.fuelTypeDetails.map(item1 =>
                                <tr key={item1.fuelType}>
                                    <td>
                                        <div className='table_head_txt'>{t('fuel_type')}։</div>
                                        {item1.name}</td>
                                    <td>
                                        <div className='table_head_txt'>{t('volume')}։</div>
                                        {item1.fuelAmount} ({item1.unit})</td>
                                    {replenish && <td>
                                        <div className='table_head_txt'>{t('unit_price_amd')}։</div>
                                        {item1.price} (AMD)</td>}
                                </tr>
                            ) : false}
                        </tbody>
                    </Table>
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b" disabled={loader}>{t('approve')}</button>
                        <button type="button" onClick={this.close_modal} className="btn btn-grey block  m-b">{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals} = state
    return {
        modals
    };
}
export default connect(mapStateToProps)(withTranslation()(ApproveReplenish));
