import React, { Component } from 'react';
import { connect } from 'react-redux';
import {modalActions, alertActions, getInfo, userActions} from '../../actions';
import { withTranslation } from 'react-i18next';
import {Table} from "reactstrap";
import $ from "jquery";
import FileBase64 from 'react-file-base64';
import { GrAttachment } from "react-icons/gr";
import {BiMailSend} from "react-icons/bi";
class AddNewApplication extends Component{
    constructor(props){
        super(props)
        // const EditContent =this.props.modals.data;
        this.state = {
            fuelType:  [],
            supplier:'',
            file:''
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        // dispatch(getInfo.get_azs_network_list({}, "get_azs_network_list"))
        // dispatch(getInfo.get_corporate_discounts({},  "get_corporate_discounts"))
        dispatch(getInfo.get_corporate_suppliers({},  "get_corporate_suppliers"))

    }
    componentWillReceiveProps(nextProps, nextContext) {
        const { dispatch } = this.props;
        if(nextProps.suppliers && nextProps.suppliers !== this.props.suppliers){
            this.setState({
                supplier: nextProps.suppliers[0].id
            });
            dispatch(getInfo.get_corporate_discounts({supplierCorpId: nextProps.suppliers[0].id},  "get_corporate_discounts"))
            // dispatch(getInfo.get_corporate_discounts({},  "get_corporate_discounts"))
        }
        if(nextProps.prices && nextProps.prices !== this.props.prices){
            this.setState({
                fuelType: nextProps.prices
            });
        }
    }

    handleChangeMulti =(e, nameInp) => {
        const target = e.target;
        const name = target.name;
        const { fuelType } = this.state;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let fuelTypeNew = fuelType.map((item)=>{
            if(item.fueltypeid == name){
                return  {...item, [nameInp]: value}
            }
            else return item
        })
        this.setState({
            fuelType: fuelTypeNew
        });

    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleChange = (e) => {
        const { dispatch } = this.props;
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        }, function (){
            dispatch(getInfo.get_corporate_discounts({supplierCorpId: value},  "get_corporate_discounts"))
        });
    }
    onBtnClick(event, data){
        const { dispatch } = this.props;
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    handleSubmit(event) {
        event.preventDefault();
        const { fuelType, supplier, file } = this.state;
        const { dispatch } = this.props;
        let totalPrice = 0;
        fuelType.filter(item=>item.fuel_volume)
        // const sendData = {supplierCorpId : Number(supplier), fuelBidDetails: fuelType.filter(item=>item.fuel_volume).map(item=>{return { fuel_type_id: item.fueltypeid, fuel_price: item.price, discount_price: item.discount, fuel_volume : item.fuel_volume ? Number(item.fuel_volume) : ''}})}
        const sendData = {id : Number(supplier), file,
            fuelbiddetails: fuelType.filter(item=>item.fuel_volume).map(
                item=>{totalPrice = (totalPrice + (fuelType[0].unit !== "KG" ? Number(item.fuel_volume) * item.discount :Number(item.fuel_volume)));
                    return { unit: fuelType[0].unit, name:item.name, fuel_type_id: item.fueltypeid, fuel_price: item.price, discount_price: item.discount, fuel_volume : item.fuel_volume ? Number(item.fuel_volume) : '', total_price: (fuelType[0].unit !== "KG" ? Number(item.fuel_volume) * item.discount :Number(item.fuel_volume))}})
        }
        // dispatch(userActions.AddTable(sendData,'create_new_corporate_bid'));

        dispatch(modalActions.open_modal("ApproveConfirmation", {...sendData, total_amount: totalPrice}))
    }
    sendFile =(file)=> {
        this.setState({
            file
        })
        $('.inp_file input').val(null);
    }
    render() {
        const { fuelType, supplier, file } = this.state;
        const { message } = this.props.alert;
		const { t, suppliers } = this.props;
        return (
            <div>
                <div className="modal_head">{t('fuel_application')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column fix-label">
                    <label className="file_new_input">
                        <FileBase64
                            multiple={ false }
                            onDone={ this.sendFile}
                        />
                        <span><GrAttachment size={18}/>
                            {file ? file.name :
                                <span className="file_text">{t('attach_price_file')}</span>
                            }

                        </span>
                        <span className="red_color">{t('attach_file_if_not_empty')}</span>
                    </label>
                    <label className="mrg_l">
                        <span className="label_txt">{t('select_supplier')}</span>
                        <select name="supplier" value={supplier}  onChange={this.handleChange} >
                            {suppliers ?
                                suppliers.map(item=>
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                )
                                :''}

                        </select>
                    </label>
                    <div className="multy_check">
                        <Table responsive striped>
                            <thead>
                            <tr>
                                <th nowrap='true'>{t('name')}</th>
                                <th nowrap='true'>{t('application_price')}</th>
                                <th nowrap='true'>{t('volume')}({(fuelType && fuelType.length) ? fuelType[0].unit === "KG" ? "AMD" : fuelType[0].unit : ''})</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(fuelType && fuelType.length) ? fuelType.map(item =>
                                    <tr key={item.fueltypeid}>
                                        <td>
                                            <div className='table_head_txt'>{t('name')}։</div>
                                            {item.name}</td>
                                        <td>
                                            <div className='table_head_txt'>{t('sale_price')}։</div>
                                            {item.discount} AMD</td>
                                        <td>
                                            <div className='table_head_txt'>{t('volume')}։</div>

                                            <label className="txt_lab mod_margin">
                                                <span className="label_txt"></span>
                                                <input type="number" pattern="[0-9]{10}" className="form-control"  name={item.fueltypeid}  value={item.fuel_volume} onChange={e=>this.handleChangeMulti(e,"fuel_volume")}/>
                                            </label>
                                        </td>
                                    </tr>
                                ) : false}
                            </tbody>
                        </Table>


                    </div>
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('send_application')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                    {message && <div className="global_error">{message.error_description}</div>}
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const {prices} = state.get_corporate_discounts
    const {suppliers} = state.get_corporate_suppliers
    return {
        modals,
        alert,
        prices,
        suppliers
    };
}

export default connect(mapStateToProps)(withTranslation()(AddNewApplication));
