import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions,getInfo } from '../../../actions/index';
import { withTranslation } from 'react-i18next';
class EditOperator2User extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data.item;
        this.state = {
            user:{
                id:EditContent.id,
                firstName:EditContent.fullName ? EditContent.fullName.split(' ')[0] : '',
                lastName:EditContent.fullName ? EditContent.fullName.split(' ')[1] : '',
                address:EditContent.address,
                soc_card:EditContent.soc_card,
                position:EditContent.position,
                email:EditContent.email,
                phone:EditContent.phone,
                selectedBranch: EditContent.selectedBranch && EditContent.selectedBranch.value,
                viewer:EditContent.viewer,
            }
        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_status_company_list({}, "get_operator2_branches_list"))
    }

    handleChangeInput(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.EditTable({...user, viewer: !!user.viewer },'edit_operator2_user'));
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
        const { data1 } = this.props;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('edit_user')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    <label className={message&& message.firstName &&'error_lab'}>
                        <span className="label_txt">{t('firstname')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="firstName" value={user.firstName} onChange={this.handleChangeInput}/>
                        {message&& message.firstName && <span className="error_mes">{message.firstName[0]}</span> }
                    </label>
                    <label className={message&& message.lastName &&'error_lab'}>
                        <span className="label_txt">{t('lastname')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="lastName" value={user.lastName} onChange={this.handleChangeInput}/>
                        {message&& message.lastName && <span className="error_mes">{message.lastName[0]}</span> }
                    </label>
                    <label className={message&& message.address &&'error_lab'}>
                        <span className="label_txt">{t('address')}</span>
                        <input type="text" className="form-control" name="address" value={user.address} onChange={this.handleChangeInput}/>
                        {message&& message.address && <span className="error_mes">{message.address[0]}</span> }
                    </label>
                    <label className={message&& message.soc_card &&'error_lab'}>
                        <span className="label_txt">{t('soc_card')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="soc_card" value={user.soc_card} onChange={this.handleChangeInput}/>
                        {message&& message.soc_card && <span className="error_mes">{message.soc_card[0]}</span> }
                    </label>
                    <label className={message&& message.position &&'error_lab'}>
                        <span className="label_txt">{t('position')}</span>
                        <input type="text" className="form-control" name="position" value={user.position} onChange={this.handleChangeInput}/>
                        {message&& message.position && <span className="error_mes">{message.position[0]}</span> }
                    </label>
                    <label className={message&& message.email &&'error_lab'}>
                        <span className="label_txt">{t('email')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="email" value={user.email} onChange={this.handleChangeInput}/>
                        {message&& message.email && <span className="error_mes">{message.email[0]}</span> }
                    </label>
                    <label className={message&& message.phone &&'error_lab'}>
                        <span className="label_txt">{t('phone')}</span>
                        <input type="text" className="form-control" name="phone" value={user.phone} onChange={this.handleChangeInput}/>
                        {message&& message.phone && <span className="error_mes">{message.phone[0]}</span> }
                    </label>
                    {data1 && data1.data && data1.data.length &&
                    <label>
                        <span className="label_txt">{t('select_branch')}</span>
                        <select name="selectedBranch" disabled={user.viewer === "true"} value={user.selectedBranch}  onChange={this.handleChangeInput}>
                            {data1.data.map(item=>
                                <option value={item.value} key={item.value}>{item.label}</option>
                            )}
                        </select>
                    </label>
                    }
                    <label  className={message&& message.viewer &&'error_lab'}>
                        <span className="label_txt">{t('select_user_type')}</span>
                        <select name="viewer" value={user.viewer} disabled  onChange={this.handleChangeInput}>
                            <option value={false}>{t('driver')}</option>
                            <option value={true}>{t('viewer')}</option>
                        </select>
                        {message&& message.viewer && <span className="error_mes">{message.viewer[0]}</span> }
                    </label>
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const data1 = state.get_status_company_list.data1
    const data5 = state.get_status_company_list_edit.data5
    return {
        modals,
        alert,
        data1,
        data5
    };
}

export default connect(mapStateToProps)(withTranslation()(EditOperator2User));
