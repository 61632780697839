import React, { Component } from 'react';
import {RouteComponent} from './components/RouteComponent';
import LoadingBar from 'react-redux-loading-bar'
import  {Popup}  from './components/Popups';
class App extends Component {
  render() {
    return (
      <div className="App">
        <Popup />
        <LoadingBar className="loading" />
        <RouteComponent/>
      </div>
    );
  }
}


export default (App);
