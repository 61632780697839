import React, {Component} from 'react';
import {connect} from 'react-redux';
import {modalActions} from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import Avatar from '../../../img/avatar.svg';
import {Table} from 'reactstrap';
import {userService} from "../../../services";
import { withTranslation } from 'react-i18next';
import * as moment from "moment";
import DatePicker from "react-datepicker";

class Branches extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableData: {
                searchValue: '',
                sortValue: '',
                sortPosition: '',
                activePage: 1,
                pageSize: 10,
                searchToDate: null
            },
            removeSearch: false,
            pageRangeDisplayed: 5,
            searchToDate: null

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }

    handleChange(event) {
        $('table tr').removeClass('edited')
        const {dispatch} = this.props;
        const {tableData} = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        });
    }

    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const {dispatch} = this.props;
        const {tableData} = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_branches"))
        });

    }

    SortClass(event) {
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event) {
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if (!element.contains('active_up') && !element.contains('active_down')) {
            this.SortClass();
            element.add('active_up');

        }
        else if (element.contains('active_up') && !element.contains('active_down')) {
            element.remove('active_up')
            element.add('active_down')
        }
        else if (!element.contains('active_up') && element.contains('active_down')) {
            element.remove('active_down')
            element.add('active_up')
        }
        const {dispatch} = this.props;
        const x = this.state.tableData.sortPosition
        const {tableData} = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x === '' || x === 'down' ? 'up' : 'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_branches"))
        });
    }

    componentDidMount() {
        if ($(window).width() <= 768) {
            this.setState({
                pageRangeDisplayed: 3
            })
        }
        const {dispatch} = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_branches"))
    }

    onBtnClick(event, data) {
        const {dispatch} = this.props;
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }

    handleChangeSelect(event) {
        const {tableData} = this.state;
        const {dispatch} = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_branches"))
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const {dispatch} = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_branches"))
        const {tableData} = this.state;
        if (tableData.searchValue != '') {
            this.setState({
                removeSearch: true
            })
        }
    }

    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(getInfo.clear_table())
    }

    removeSearch = () => {
        const {tableData} = this.state;
        const {dispatch} = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_branches"))
        });
    }
    downloadExcel= (method, name, data) =>{
        userService.downloadExcel(method, data).then(response => {
            if(response.ok){
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${name}.xlsx`;
                    a.click();
                })
            }
            else{
                this.props.dispatch(modalActions.open_modal('Fail_popup','Bad request'));
            }

        });
    }
    onChangeDate = (date) => {
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchToDate: moment(new Date(date)).format('L')
            },
            searchToDate: date

        })
    }
    render() {
        const {data, Count} = this.props;
        const {tableData} = this.state;
		const { t } = this.props;
        const userRole = JSON.parse( localStorage.getItem('userRole'));
        return (
            <div className="main_container ">
                <form onSubmit={this.handleSubmit} className="form_search m_form">
                    <div className="calendar_cont mrg_l" >
                        <span className="label_txt">{t('date')}</span>
                        <DatePicker
                            selected={this.state.searchToDate}
                            onChange={date => this.onChangeDate(date)}
                            dateFormat="MMMM d, yyyy"
                        />
                    </div>
                    <label className="search">
                        <span className="label_txt">{t('search')}</span>
                        <input type="text" value={tableData.searchValue} onChange={this.handleChange} placeholder=""
                               className="form-control"/>
                        {this.state.removeSearch &&
                        <span className="remove_search" onClick={this.removeSearch}>X</span> }
                    </label>
                    <button className="search1">{t('search_now')}</button>
                    <div className="add_but exel_but_trans" onClick={()=>this.downloadExcel('get_branches_excel', t('branches'), this.state.tableData)}>{t('export')}</div>
                </form>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="name">{t('by_full_name')}</option>
                    <option value="address">{t('by_address')}</option>
                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="name">{t('full_name')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="address">{t('address')}</th>
                        <th >{t('balance')}</th>
                        { userRole !== 'operator2Viewer' && <th >{t('action')}</th>}
                    </tr>
                    </thead>
                    <tbody>{data ? data.map((item, index) =>
                        <tr key={index}>
                            <td style={{width: '3%'}} nowrap='true'>
                                <div className='table_head_txt'>{t('number')}:</div>
                                {(this.state.tableData.activePage - 1) * 10 + 1 + index } </td>
                            <td style={{width: '10%'}} nowrap='true'>
                                <div className='table_head_txt'>{t('full_name')}։</div>
                                {item.name}</td>
                            <td style={{width: '10%'}} nowrap='true'>
                                <div className='table_head_txt'>{t('address')}։</div>
                                {item.address}</td>
                            <td style={{width: '35%'}} nowrap='true'>
                                <div className='table_head_txt'>{t('balance')}։</div>
                                <Table responsive striped>
                                    <thead>
                                    <tr>
                                        <th nowrap='true'>{t('fuel_type')}</th>
                                        <th nowrap='true'>{t('company_balance')}</th>
                                        <th nowrap='true'>{t('card_balance')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {item.balance && item.balance.length ?
                                        item.balance.map(item1 =>
                                            <tr key={item1.fuelType}>
                                                <td>
                                                    <div className='table_head_txt'>{t('fuel_type')}։</div>
                                                    {item1.fuelType}</td>
                                                <td>
                                                    <div className='table_head_txt'>{t('company_balance')}։</div>
                                                    {item1.branchBalance} {item1.fuelUnit}</td>
                                                <td>
                                                    <div className='table_head_txt'>{t('card_balance')}։</div>
                                                    {item1.cardBalance} {item1.fuelUnit}</td>
                                            </tr>
                                        ) : false}
                                    </tbody>
                                </Table>
                            </td>
                            { userRole !== 'operator2Viewer' &&
                                    <td style={{width: '20%'}} nowrap='true' className="actions mod1">
                                        <div className='table_head_txt'>{t('action')}:</div>
                                        <div className="but_table" onClick={(e) => {
                                            this.onBtnClick(e, {...item, type: 'add'})
                                        }} data_modal="Operator2AddCash">{t('replenish_balance')}
                                        </div>
                                        <div className="but_table but1"onClick={(e) => {
                                            this.onBtnClick(e, {...item, type: 'return'})
                                        }} data_modal="Operator2AddCash">{t('refund')}
                                        </div>

                                    </td>
                            }
                        </tr>
                    )
                        : false
                    }</tbody>
                </Table>


                <div className="pagin_cont">

                    {Count && Count > 10 ?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count : ''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        : ''}
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data, Count} = state.getTable
    return {
        data,
        Count
    };
}
const connectedBranches= connect(mapStateToProps)(withTranslation()(Branches));
export { connectedBranches as Branches };