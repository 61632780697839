import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';
class Map extends Component{
    constructor(props){
        super(props)
        const Content = this.props.modals.data;
        this.state = {
                lat:Content.lat,
                long: Content.long,
        }


        this.close_modal = this.close_modal.bind(this)
    }


    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal());
        dispatch(alertActions.clear());
    }

    render() {
        return (
            <div>
                <div className="modal_head">Լցակայան</div>
                <form  className="m_form d-flex flex-column">
                    {this.state.lat && this.state.long ?
                        <iframe src={`https://maps.google.com/maps?q=${this.state.lat},${this.state.long}&t=&z=15&ie=UTF8&iwloc=&output=embed`} width="100%" height="450" frameBorder="0" allowFullScreen=""></iframe>
                        : <div>Wrong address lat, long</div>
                    }

                    <button type="button" className="btn btn-grey block  m-b" style={{marginTop: "25px"}} onClick={this.close_modal}>Close</button>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert
    };
}

export default connect(mapStateToProps)(Map);
