import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { loading } from './request';
import { modals } from './modal.reducer';
import * as getInfo from './getInfo.reducer';
import { loadingBarReducer } from 'react-redux-loading-bar'

const rootReducer = combineReducers({
      authentication,
      alert,
      loading,
      modals,
    ...getInfo,
      loadingBar: loadingBarReducer,
});

export default rootReducer;