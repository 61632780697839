
export const modalActions = {
    open_modal,
    close_modal
};
 function open_modal(modal_name, data) {
    return{
        type:"OPEN_MODAL",
        payLoad: modal_name,
        data: data
    }
}
function close_modal() {
    return{
        type:"CLOSE_MODAL"
    }
}