import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';
import { withTranslation } from 'react-i18next';
class EditUser extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data;
        this.state = {
            user:{
                id:EditContent.id,
                firstName: EditContent.first_name,
                lastName:EditContent.last_name,
                patronymic:EditContent.patronymic,
                soc_card:EditContent.soc_card,
                position:EditContent.position,
                auto:EditContent.auto,
                phone:EditContent.phone,
                email:EditContent.email,
                viewer:EditContent.viewer,
            }

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.EditTable({...user, viewer: !!user.viewer },'edit_driver'));
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('edit_driver')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    <label className={message&& message.firstName &&'error_lab'}>
                        <span className="label_txt">{t('firstname')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="firstName" value={user.firstName?user.firstName:''} onChange={this.handleChangeInput}/>
                        {message&& message.firstName && <span className="error_mes">{message.firstName[0]}</span> }
                    </label>
                    <label className={message&& message.lastName &&'error_lab'}>
                        <span className="label_txt">{t('lastname')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="lastName" value={user.lastName?user.lastName:''} onChange={this.handleChangeInput}/>
                        {message&& message.lastName && <span className="error_mes">{message.lastName[0]}</span> }
                    </label>
                    <label className={message&& message.patronymic &&'error_lab'}>
                        <span className="label_txt">{t('middlename')}</span>
                        <input type="text" className="form-control" name="patronymic" value={user.patronymic?user.patronymic:''} onChange={this.handleChangeInput}/>
                        {message&& message.patronymic && <span className="error_mes">{message.patronymic[0]}</span> }
                    </label>
                    <label className={message&& message.soc_card &&'error_lab'}>
                        <span className="label_txt">{t('soc_card')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="soc_card" value={user.soc_card?user.soc_card:''} onChange={this.handleChangeInput}/>
                        {message&& message.soc_card && <span className="error_mes">{message.soc_card[0]}</span> }
                    </label>
                    <label className={message&& message.position &&'error_lab'}>
                        <span className="label_txt">{t('position')}</span>
                        <input type="text" className="form-control" name="position" value={user.position?user.position:''} onChange={this.handleChangeInput}/>
                        {message&& message.position && <span className="error_mes">{message.position[0]}</span> }
                    </label>
                    <label className={message&& message.auto &&'error_lab'}>
                        <span className="label_txt">{t('car')}</span>
                        <input type="text" className="form-control" name="auto" value={user.auto?user.auto:''} onChange={this.handleChangeInput}/>
                        {message&& message.auto && <span className="error_mes">{message.auto[0]}</span> }
                    </label>
                    <label className={message&& message.phone &&'error_lab'}>
                        <span className="label_txt">{t('phone')}</span>
                        <input type="text" className="form-control" name="phone" value={user.phone?user.phone:''} onChange={this.handleChangeInput}/>
                        {message&& message.phone && <span className="error_mes">{message.phone[0]}</span> }
                    </label>
                    <label className={message&& message.email &&'error_lab'}>
                        <span className="label_txt">{t('email')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="email" value={user.email?user.email:''} onChange={this.handleChangeInput}/>
                        {message&& message.email && <span className="error_mes">{message.email[0]}</span> }
                    </label>
                    <label  className={message&& message.viewer &&'error_lab'}>
                        <span className="label_txt">{t('select_user_type')}</span>
                        <select name="viewer" value={user.viewer} disabled  onChange={this.handleChangeInput}>
                            <option value={false}>{t('driver')}</option>
                            <option value={true}>{t('viewer')}</option>
                        </select>
                        {message&& message.viewer && <span className="error_mes">{message.viewer[0]}</span> }
                    </label>

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert
    };
}

export default connect(mapStateToProps)(withTranslation()(EditUser));
