import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions , getInfo } from '../../../actions/index';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

export const customStyles = {
    container: (base) => ({
        ...base,
        display:'inline-block',
        width: '100%'
    }),
    control: provided => ({
        ...provided,
        minHeight: "30px",
        height: "30px"
    }),
    valueContainer: (base) => ({
        ...base,
        height: "30px",
        padding: "0 8px"
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: "30px"
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
};

class EditTermianl extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data;
        this.state = {
            user:{
                id:EditContent.id,
                terminalId:EditContent.terminalId ,
                serialNumber:EditContent.serialNumber,
                merchantId:EditContent.merchantId,
                azs:EditContent.azsId,
                masterKey:EditContent.masterKey,
                fuelType: EditContent.fuelType ? EditContent.fuelType.value :''
            }

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_admin_transactions_balance({}, "get_azs_list_for_terminal"));
        dispatch(getInfo.get_fuelTypes_list({}, "get_fuelTypes_list"));
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleChange = (selectedOption, name) => {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: selectedOption.value
            }
        })
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.EditTable(user,'edit_terminal'));
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
        const { data1, data2 } = this.props;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('edit_terminal')}</div>
                               <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    <label className={message&& message.terminalId &&'error_lab'}>
                        <span className="label_txt">Terminal ID</span>
                        <input type="text" className="form-control" name="terminalId" value={user.terminalId} onChange={this.handleChangeInput}/>
                        {message&& message.terminalId && <span className="error_mes">{message.terminalId[0]}</span> }
                    </label>
                    <label className={message&& message.serialNumber &&'error_lab'}>
                        <span className="label_txt">Serial number</span>
                        <input type="text" className="form-control" name="serialNumber" value={user.serialNumber} onChange={this.handleChangeInput}/>
                        {message&& message.serialNumber && <span className="error_mes">{message.serialNumber[0]}</span> }
                    </label>
                    <label className={message&& message.merchantId &&'error_lab'}>
                        <span className="label_txt">Merchant ID</span>
                        <input type="text" className="form-control" name="merchantId" value={user.merchantId} onChange={this.handleChangeInput}/>
                        {message&& message.merchantId && <span className="error_mes">{message.merchantId[0]}</span> }
                    </label>
                    <label className={message&& message.masterKey &&'error_lab'}>
                        <span className="label_txt">Master key</span>
                        <input type="text" className="form-control" name="masterKey" value={user.masterKey} onChange={this.handleChangeInput}/>
                        {message&& message.masterKey && <span className="error_mes">{message.masterKey[0]}</span> }
                    </label>
                   {data1 &&
                       <label>
                           <span className="label_txt">{t('choose_gas_station')}</span>
                           <Select
                               className="select-box"
                               classNamePrefix="react-select"
                               styles={customStyles}
                               defaultValue={data1 && data1.data.filter(item=>item.value === this.state.user.azs)}
                               onChange={e=>this.handleChange(e, 'azs')}
                               options={data1 && data1.data}
                               placeholder={t('choose_gas_station')}
                           />
                       </label>
                   }
                   {data2 &&
                       <label>
                           <span className="label_txt">{t('choose_fuel_type')}</span>
                           <Select
                               className="select-box"
                               classNamePrefix="react-select"
                               styles={customStyles}
                               defaultValue={data2 && data2.filter(item=>item.value === this.state.user.fuelType)}
                               onChange={e=>this.handleChange(e, 'fuelType')}
                               options={data2}
                               placeholder={t('choose_fuel_type')}
                           />
                       </label>
                   }

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const data1 = state.get_admin_transactions_balance.data1
    const data2 = state.get_fuelTypes_list.data5 && state.get_fuelTypes_list.data5.data && state.get_fuelTypes_list.data5.data.fuelTypeList
    return {
        data1,
        modals,
        alert,
        data2
    };
}

export default connect(mapStateToProps)(withTranslation()(EditTermianl));
