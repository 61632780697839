import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import { withTranslation } from 'react-i18next';



class AveragePriceCardHolder extends Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { dispatch } = this.props;
        // dispatch(getInfo.averagePrices({}, "get_average_prices"))
        dispatch(getInfo.get_balance({},  "get_card_holder_balance"))
    }
    componentWillReceiveProps(nextProps){



    }
    onBtnClick =(event, data)=>{
        const { dispatch } = this.props;
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }

    render() {
        const {data1} =this.props;
		const { t } = this.props;
        return (

            <div className="main_container">
                <div className="balance">
                    <div className="d-flex">
                        <span>{t('available_fuel')}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="cont2 mod_dashb">
                        <div className="widget color2">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>{t('fuel_type')}</th>
                                        <th>{t('balance')}</th>
                                        <th>{t('daily_limit')}</th>
                                        <th>{t('one_time_limit')}</th>
                                        <th>{t('month_limit')}</th>
                                        <th>{t('company_limit')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data1 && data1.data && data1.data.map((item, index)=>
                                        <tr key={item.fuelType}>
                                            <td>
                                                <div className='table_head_txt'>{t('fuel_type')}:</div>{item.fuelType}</td>
                                            <td>
                                                <div className='table_head_txt'>{t('balance')}:</div> {item.balance} {item.fuelUnit}</td>
                                            <td>
                                                <div className='table_head_txt'>{t('daily_limit')}:</div>{item.dayLimit} {item.fuelUnit}</td>
                                            <td>
                                                <div className='table_head_txt'>{t('one_time_limit')}:</div>{item.oneLimit} {item.fuelUnit}</td>
                                            <td>
                                                <div className='table_head_txt'>{t('month_limit')}:</div>{item.monthLimit} {item.fuelUnit}</td>
                                            <td>
                                                <div className='table_head_txt'>{t('company_limit')}:</div>{item.corpLimit? t('in_use'):t('none')}</td>
                                        </tr>
                                    )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data1} = state.GetBalance
    return {
        data1
    };
}

const connectedAveragePriceCardHolder = connect(mapStateToProps)(withTranslation()(AveragePriceCardHolder));
export { connectedAveragePriceCardHolder as AveragePriceCardHolder };