import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from '../../img/avatar.svg';
import { userActions } from '../../actions/index';
class ViewGroup extends Component{
    constructor(props){
        super(props)
        const UserContent =this.props.modals.data;
        this.state = {
           group_id: UserContent._id,
           group_name: UserContent.name

        }


    }
    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(userActions.ViewGroup(this.state.group_id));
    }

    render() {
        const {ViewList} =this.props
        return (
            <div>
                <div className="modal_head">Users: {this.state.group_name} group</div>
                {ViewList && ViewList.List && ViewList.List.users &&  ViewList.List.users.length?

                    <ul className="list_data">
                        {ViewList.List.users.map((item, index)=>
                        <li key={index}><img src={item.picName?'http://www.ifixgroup.com:4050/pictures/' + item.picName : Avatar} width="20" alt=""/> {item.name}  {item.lastName}</li>
                    )}
                        </ul>
                    :<div className="dont_list">This user does not have a group.</div>

                }


            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, ViewList} = state
    return {
        modals,
        ViewList
    };
}
export default connect(mapStateToProps)(ViewGroup);
