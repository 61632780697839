import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  modalActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { Table } from 'reactstrap';
import ReactExport from "react-export-excel";
import {userService} from "../../../services";
import { withTranslation } from 'react-i18next';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Admin1Users extends Component {

    constructor(props) {
        super(props);
        this.state={
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                pageSize :10
            },
            removeSearch: false,
            pageRangeDisplayed: 5

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(event) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        });
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_users"))
        });

    }
    SortClass(event){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_users"))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_admin_users"))
    }

// && tableData.sortValue == this.value
    onBtnClick(event, data){
        const { dispatch } = this.props;
        // $(this).parent().parent()
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    //
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_users"))
        });
    }
    handleSubmit =(event)=>{
        event.preventDefault();
        const {dispatch}= this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_admin_users"))
        const { tableData } = this.state;
        if(tableData.searchValue != ''){
            this.setState({
                removeSearch: true
            })
        }
    }
    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(getInfo.clear_table())
    }
    removeSearch =()=>{
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_users"))
        });
    }
    downloadExcel= (method, name, data) =>{
        userService.downloadExcel(method, data).then(response => {
            if(response.ok){
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${name}.xlsx`;
                    a.click();
                })
            }
            else{
                this.props.dispatch(modalActions.open_modal('Fail_popup','Bad request'));
            }

        });
    }
    render() {
        const userRole=JSON.parse( localStorage.getItem('userRole'));
        const {data, Count} =this.props;
        const {tableData} =this.state;
		const { t } = this.props;
        return (
            <div className="main_container admin1">
                <div className="top_container_search">
                    <form onSubmit={this.handleSubmit} className="form_search m_form">
                        <label className="search">
                            <span className="label_txt">{t('search')}</span>
                            <input type="text" value={tableData.searchValue} onChange={this.handleChange} placeholder="" className="form-control"/>
                            {this.state.removeSearch && <span className="remove_search" onClick={this.removeSearch}>X</span> }
                        </label>
                        <button  className="search1">{t('search_now')}</button>
                        <div className="add_but exel_but_trans" onClick={()=>this.downloadExcel('get_users_excel', t('users'), this.state.tableData)}>{t('export')}</div>
                    </form>
                    {(userRole==='admin1' || userRole==='superAdmin') &&
                    <div className="but_cont_search">
                        {/*<ExcelFile element={<div className="add_but exel_but" >Excel</div>}>*/}
                        {/*    <ExcelSheet data={data ?  data:[]} name="Users">*/}
                        {/*        <ExcelColumn label="Անուն/Ազգանուն" value='fullName' />*/}
                        {/*        <ExcelColumn label="Կարգավիճակ" value='role'/>*/}
                        {/*        <ExcelColumn label="Հասցե" value='address'/>*/}
                        {/*        <ExcelColumn label="Սոց քարտ" value='soc_card'/>*/}
                        {/*        <ExcelColumn label="Պաշտոն" value='position'/>*/}
                        {/*        <ExcelColumn label="Էլ․ փոստ" value='email'/>*/}
                        {/*        <ExcelColumn label="Հեռախոսահամար" value='phone'/>*/}
                        {/*        <ExcelColumn label="Կազմակերպություն" value='companyName'/>*/}
                        {/*        <ExcelColumn label="Լցակայաններ" value={*/}
                        {/*            (data) => {*/}
                        {/*                if (data.azs.length) {*/}
                        {/*                  return  data.azs.map(item => {*/}
                        {/*                        return item.Name*/}
                        {/*                    }).join()*/}

                        {/*                }*/}
                        {/*                else return ""*/}
                        {/*            }*/}
                        {/*        } />*/}

                        {/*    </ExcelSheet>*/}

                        {/*</ExcelFile>*/}
                        <div className="add_but but2 mod_height"  onClick={(e) => { this.onBtnClick(e, {size: "large"} ) }}  data_modal="AddAdmin1User">{t('add_new_user')}</div>
                    </div>}
                </div>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="fullName">{t('by_full_name')}</option>
                    <option value="role">{t('by_role')}</option>
                    <option value="address">{t('by_address')}</option>
                    <option value="soc_card">{t('by_soc_card')}</option>
                    <option value="position">{t('by_position')}</option>
                    <option value="email">{t('by_email')}</option>
                    <option value="phone">{t('by_phone')}</option>
                    <option value="companyName">{t('by_company_name')}</option>


                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th  className="clickable" nowrap='true' onClick={this.handleSort} data-val="fullName">{t('full_name')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="role">{t('role')}</th>
                        <th  className="clickable" nowrap='true' onClick={this.handleSort} data-val="address">{t('address')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="soc_card">{t('soc_card')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="position">{t('position')}</th>
                        <th className="clickable" onClick={this.handleSort} data-val="companyName">{t('company_name')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="email">{t('email')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="phone">{t('phone')}</th>
                        {(userRole==='admin1' || userRole==='superAdmin') && <th >{t('action')}</th>}
                    </tr>
                    </thead>
                        <tbody>{data ?  data.map( (item, index)=>
                           <tr key={index}>
                                <td style={{width:'3%'}}  nowrap='true'><div className='table_head_txt'>Id:</div>
                                    {item.id} </td>
                                <td style={{width:'7%'}}  nowrap='true' ><div className='table_head_txt'>{t('full_name')}։</div>{item.fullName}</td>
                                <td style={{width:'15%'}}  nowrap='true' ><div className='table_head_txt'>{t('role')}։</div>{item.role}</td>
                                <td style={{width:'10%'}}  nowrap='true' ><div className='table_head_txt'>{t('address')}։</div>{item.address}</td>
                                <td style={{width:'12%'}}  nowrap='true' ><div className='table_head_txt'>{t('soc_card')}։</div>{item.soc_card}</td>
                                <td style={{width:'12%'}}  nowrap='true' ><div className='table_head_txt'>{t('position')}։</div>{item.position}</td>
                                <td style={{width:'12%'}}  ><div className='table_head_txt'>
                                    {t('company')}։</div>
                                    {item.companyName?item.companyName:
                                        item.azs && item.azs.length ?
                                                item.azs.map(item1=> item1.Name).join()
                                            :''
                                    }
                                    </td>
                                <td style={{width:'10%'}}  nowrap='true' ><div className='table_head_txt'>{t('email')}։</div>{item.email}</td>
                                <td style={{width:'10%'}}  nowrap='true' ><div className='table_head_txt'>{t('phone')}։</div>{item.phone}</td>
                               {(userRole==='admin1' || userRole==='superAdmin') && <td style={{width:'10%'}} nowrap='true' className="actions">
                                    <div className='table_head_txt'>{t('action')}:</div>
                                    <span className="edit fa"  onClick={(e) => { this.onBtnClick(e, {item, size: "large"} ) }}  data_modal="EditAdmin1User"></span>
                                    {!item.deleted_at?<span className="delete fa"  onClick={(e) => { this.onBtnClick(e, item ) }}  data_modal="DeleteAdmin1User" ></span>:''}
                                </td>}
                            </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
     const {data, Count} = state.getTable
    return {
        data,
        Count
    };
}

const connectedAdmin1Users = connect(mapStateToProps)(withTranslation()(Admin1Users));
export { connectedAdmin1Users as Admin1Users };