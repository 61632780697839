import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getInfo, modalActions} from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions } from '../../../actions/index';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';


const customStyles = {
    container: (base) => ({
        ...base,
        display:'inline-block',
        width: '100%'
    }),
    control: provided => ({
        ...provided,
        minHeight: "30px",
        height: "30px"
    }),
    valueContainer: (base) => ({
        ...base,
        height: "30px",
        padding: "0 8px"
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: "30px"
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
};
const styles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};
class EditCorporateUser extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data;
        this.state = {
            user:{
                id:EditContent.id,
                nameEnglish:EditContent.nameEnglish ,
                name:EditContent.name,
                inn:EditContent.inn,
                address:EditContent.address,
                phone:EditContent.phone,
                email:EditContent.email,
                bankAccount:EditContent.bankAccount,
                headOffice: EditContent.headOffice,
                branchOffice: EditContent.branchOffice,
                selectHeadOffice:EditContent.selectHeadOffice ? EditContent.selectHeadOffice.value :null,
                fuelType: [],
                external_id: EditContent.external_id ? EditContent.external_id: ''
            },
            sellerFuelType: [],
            selectedOptions: [],
            defaultCheck: [],
            fuelType: EditContent.fuelType && EditContent.fuelType.length ? EditContent.fuelType : [],

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_admin_transactions_balance({}, "get_admin1_head_offices"))
        dispatch(getInfo.get_azs_network_list({}, "get_azs_network_list"))
    }
    componentWillReceiveProps(props){
        if(props.data2 && props.data2 !== this.props.data2){
            const { user } = this.state;
            const x = this.state.fuelType.filter(item => item.checked)
            const sellerFuelType = props.data2.map(item=>{
                return{ value: item.sellerId, label: item.sellerName, fuelTypes: item.fuelTypes}

            })
            const defaultCheck = sellerFuelType.filter(item=> x.filter(item1=> item.fuelTypes.find(item2=>item2.id === item1.id) ).length).map(item=>{return {...item, isFixed: true}})
            // const defaultCheck = sellerFuelType.filter(item => x.find(e => Number(e.id) === Number(item.fuelTypes)))
            let fuelTypes = [];
            defaultCheck.forEach((item)=>{
                fuelTypes.push(item.fuelTypes)
            })
            fuelTypes = fuelTypes.flat()
            const newArr = fuelTypes.map(item=>{
                return {...item, checked: this.state.fuelType.filter(item1=> item1.id === item.id && item1.checked).length}
            })
            this.setState({
                user:{
                    ...user,
                    fuelType: newArr
                },
                sellerFuelType,
                defaultCheck
            });
        }
    }
    handleChangeMulty =(e, nameInp) => {
        const target = e.target;
        const name = target.name;
        const { user } = this.state;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let fuelType = user.fuelType.map((item)=>{
            if(item.id.toString() === name.toString()){
                return  {...item, [nameInp]: value}
            }
            else return item
        })
        this.setState({
            user: {
                ...user,
                fuelType
            }

        });

    }
    handleChangeInput(e) {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const { user } = this.state;
        if(name !== 'branchOffice'){
            this.setState({
                user: {
                    ...user,
                    [name]: value
                }
            });
        }
        else{
            if(value){
                this.setState({
                    user: {
                        ...user,
                        [name]: value
                    }
                });
            }
            else{
                this.setState({
                    user: {
                        ...user,
                        [name]: value,
                        selectHeadOffice: null
                    }
                });
            }

        }

    }
    handleChange = (selectedOption) => {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                selectHeadOffice: selectedOption.value
            }
        })
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleChangeMultiSelect = (selectedOption, option) => {
        const { user } = this.state;
        let selectedOptions = this.state.selectedOptions;
        let fuelType1;
        let fuelType = user.fuelType;
        if(selectedOption && selectedOption.length > selectedOptions.length){
            fuelType1 = [...fuelType, ...option.option.fuelTypes]
        }
        else if(selectedOption && selectedOption.length < selectedOptions.length){
            fuelType1 = fuelType.reduce((acc, item) => {
                if (!option.removedValue.fuelTypes.find(e => e.id === item.id)) acc.push(item);
                return acc;} , []);
        } else{
            fuelType1= []
        }

        this.setState({
            user: {
                ...user,
                fuelType: fuelType1
            },
            selectedOptions: selectedOption ? selectedOption : [],
        })
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.EditTable(user,'edit_corporate_user'));
    }
    render() {
        const { user } = this.state;
        const { data1 } = this.props;
        const { message } = this.props.alert;
        const userRole=JSON.parse( localStorage.getItem('userRole'));
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('edit_corporate_client')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    <label className={message&& message.nameEnglish &&'error_lab'}>
                        <span className="label_txt">{t('name_english')}</span>
                        <input type="text" className="form-control" name="nameEnglish" value={user.nameEnglish} onChange={this.handleChangeInput}/>
                        {message&& message.nameEnglish && <span className="error_mes">{message.nameEnglish[0]}</span> }
                    </label>
                    <label className={message&& message.name &&'error_lab'}>
                        <span className="label_txt">{t('name')}</span>
                        <input type="text" className="form-control" name="name" value={user.name} onChange={this.handleChangeInput}/>
                        {message&& message.name && <span className="error_mes">{message.name[0]}</span> }
                    </label>
                    <label className={message&& message.inn &&'error_lab'}>
                        <span className="label_txt">{t('tax_code')}</span>
                        <input type="text" className="form-control" name="inn" value={user.inn} onChange={this.handleChangeInput}/>
                        {message&& message.inn && <span className="error_mes">{message.inn[0]}</span> }
                    </label>
                    <label className={message&& message.address &&'error_lab'}>
                        <span className="label_txt">{t('address')}</span>
                        <input type="text" className="form-control" name="address" value={user.address} onChange={this.handleChangeInput}/>
                        {message&& message.address && <span className="error_mes">{message.address[0]}</span> }
                    </label>

                    <label className={message&& message.phone &&'error_lab'}>
                        <span className="label_txt">{t('phone')}</span>
                        <input type="text" className="form-control" name="phone" value={user.phone} onChange={this.handleChangeInput}/>
                        {message&& message.phone && <span className="error_mes">{message.phone[0]}</span> }
                    </label>
                    <label className={message&& message.email &&'error_lab'}>
                        <span className="label_txt">{t('email')}</span>
                        <input type="text" className="form-control" name="email" value={user.email} onChange={this.handleChangeInput}/>
                        {message&& message.email && <span className="error_mes">{message.email[0]}</span> }
                    </label>
                    <label className={message&& message.bankAccount &&'error_lab'}>
                        <span className="label_txt">{t('bank_account')}</span>
                        <input type="text" className="form-control" name="bankAccount" value={user.bankAccount} onChange={this.handleChangeInput}/>
                        {message&& message.bankAccount && <span className="error_mes">{message.bankAccount[0]}</span> }
                    </label>
                    <label className={message && message.external_id &&'error_lab'}>
                        <span className="label_txt">{t('external_system_id')}</span>
                        <input type="text" className="form-control" name="external_id" value={user.external_id} onChange={this.handleChangeInput}/>
                        {message&& message.external_id && <span className="error_mes">{message.external_id[0]}</span> }
                    </label>
                    {!user.branchOffice && this.state.sellerFuelType.length>0 && this.state.defaultCheck &&
                        <label>
                            <span className="label_txt">{t('choose_supplier')}</span>
                            <Select
                                isMulti
                                className="select-box"
                                classNamePrefix="react-select"
                                styles={styles}
                                // isDisabled={!user.branchOffice}
                                // value={this.state.selectedOptions}
                                defaultValue={this.state.defaultCheck}
                                onChange={this.handleChangeMultiSelect}
                                options={this.state.sellerFuelType}
                                placeholder={t('choose_supplier')}
                                isClearable={false}
                            />
                        </label>
                    }
                    {!user.branchOffice &&
                    <div className="multy_check">
                        <span className="label_txt">{t('choose_fuel_type')}</span>
                        {(user.fuelType && user.fuelType.length) ? user.fuelType.map(
                            (item, index) =>
                                <div className="flex-inp-price">
                                    <label className='checkbox_lab' key={item.id}>
                                        <input type="checkbox" className="form-control" name={item.id}
                                               checked={item.checked} disabled={this.state.fuelType.find(item1=> item1.id === item.id && item1.checked)}
                                               onChange={e => this.handleChangeMulty(e, "checked")}/>
                                        <span className="label_txt">{item.name}</span>
                                    </label>
                                </div>
                        ) : ''}

                    </div>
                    }
                    <div className="select_office">
                        <label>
                            <span>{t('head_office')}</span>
                            <input type="checkbox" name="headOffice" disabled checked={user.headOffice} onChange={this.handleChangeInput}/>
                        </label>
                        <label>
                            <span>{t('branch')}</span>
                            <input type="checkbox"  name="branchOffice" disabled checked={user.branchOffice} onChange={this.handleChangeInput}/>
                        </label>
                    </div>
                    {user.branchOffice && data1 && data1.data &&
                    <label>
                        <span className="label_txt">{t('choose_head_office')}</span>
                        <Select
                            className="select-box"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            isDisabled={!user.branchOffice}
                            defaultValue={data1 && data1.data.filter(item=>item.value === user.selectHeadOffice)}
                            onChange={this.handleChange}
                            options={data1 && data1.data}
                            placeholder="Ընտրել Գլխամասը"
                        />
                    </label>
                    }

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const data1 = state.get_admin_transactions_balance.data1;
    const data2 = state.GetAzsNetworkList.data2;
    return {
        modals,
        alert,
        data1,
        data2,
    };
}

export default connect(mapStateToProps)(withTranslation()(EditCorporateUser));
