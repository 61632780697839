import React, {Component} from 'react';
import {connect} from 'react-redux';
import {modalActions} from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import {Table} from 'reactstrap';
import {userService} from '../../../services';
import {withTranslation} from 'react-i18next';


class DiscountManagements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: {
                searchValue: '',
                sortValue: '',
                sortPosition: '',
                activePage: 1,
                pageSize: 10,
                date: null
            },
            removeSearch: false,
            pageRangeDisplayed: 5

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }

    handleChange(event) {
        $('table tr').removeClass('edited')
        const {dispatch} = this.props;
        const {tableData} = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        });
    }

    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const {dispatch} = this.props;
        const {tableData} = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_corporate_discount_managements"))
        });

    }

    SortClass(event) {
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(getInfo.clear_table())
    }

    handleSort(event) {
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if (!element.contains('active_up') && !element.contains('active_down')) {
            this.SortClass();
            element.add('active_up');

        } else if (element.contains('active_up') && !element.contains('active_down')) {
            element.remove('active_up')
            element.add('active_down')
        } else if (!element.contains('active_up') && element.contains('active_down')) {
            element.remove('active_down')
            element.add('active_up')
        }
        const {dispatch} = this.props;
        const x = this.state.tableData.sortPosition
        const {tableData} = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x === '' || x === 'down' ? 'up' : 'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_corporate_discount_managements"))
        });
    }

    componentDidMount() {
        if ($(window).width() <= 768) {
            this.setState({
                pageRangeDisplayed: 3
            })
        }
        const {dispatch} = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_corporate_discount_managements"))
    }

// && tableData.sortValue == this.value
    onBtnClick(event, data) {
        const {dispatch} = this.props;
        // $(this).parent().parent()
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }

    //
    handleChangeSelect(event) {
        const {tableData} = this.state;
        const {dispatch} = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData))
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        const {dispatch} = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_corporate_discount_managements"));
        const {tableData} = this.state;
        if (tableData.searchValue != '') {
            this.setState({
                removeSearch: true
            })
        }
    }
    removeSearch = () => {
        const {tableData} = this.state;
        const {dispatch} = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_corporate_discount_managements"))
        });
    }

    downloadExcel = (method, name, data) => {
        const {data1} = this.props;
        userService.downloadExcel(method, {...data, CorporateUserId: data1 && data1.id}).then(response => {
            if (response.ok) {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${name}.xlsx`;
                    a.click();
                })
            } else {
                this.props.dispatch(modalActions.open_modal('Fail_popup', 'Bad request'));
            }
        });
    }

    render() {
        const userRole = JSON.parse(localStorage.getItem('userRole'));
        const {data, Count} = this.props;
        const {tableData} = this.state;
        const {t} = this.props;
        return (
            <div className="main_container admin1">

                <div className="top_container_search">
                    <form onSubmit={this.handleSubmit} className="form_search m_form mod_transactions">

                        <label className="search">
                            <span className="label_txt">{t('search')}</span>
                            <input type="text" value={tableData.searchValue} onChange={this.handleChange} placeholder=""
                                   className="form-control"/>
                            {this.state.removeSearch &&
                            <span className="remove_search" onClick={this.removeSearch}>X</span>}
                        </label>
                        <button className="search1">{t('search_now')}</button>
                        <div className="add_but exel_but_trans"
                             onClick={() => this.downloadExcel('get_corporate_discount_excel', t('corporate_clients'), this.state.tableData)}>{t('export')}</div>
                    </form>
                    {(userRole === 'admin1' || userRole === 'superAdmin') && <div className="add_but" onClick={(e) => {
                        this.onBtnClick(e, '')
                    }} data_modal="AddCorporateUser">{t('add_new_corporate_client')}</div>}

                </div>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="name">{t('company_discount_managements')}</option>
                    <option value="id">{t('by_taxcode')}</option>
                    <option value="id">{t('address')}</option>
                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th nowrap='true'>#</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort}
                            data-val="company_discount_managements">{t('company_discount_managements')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="tax_code">{t('tax_code')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="address">{t('address')}</th>
                        <th nowrap='true' ></th>
                        <th>{t('action')}</th>
                    </tr>
                    </thead>
                    <tbody>{data ? data.map((item, index) =>
                            <tr key={index}>
                                <td style={{width:'3%'}}  nowrap='true'><div className='table_head_txt'>{t('number')}:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                                <td style={{width: '14%'}} nowrap='true'>
                                    <div className='table_head_txt'>
                                        {t('company_discount_managements')}։
                                    </div>
                                    {item.name}
                                </td>
                                <td style={{width: '12%'}} nowrap='true'>
                                    <div className='table_head_txt'>{t('tax_code')}։</div>
                                    {item.tax_id}
                                </td>
                                <td style={{width: '18%'}} nowrap='true'>
                                    <div className='table_head_txt'>{t('address')}։</div>
                                    {item.address}
                                </td>
                                <td style={{width: '35%'}} nowrap='true'>
                                    <div className='table_head_txt'>{t('discounts')}։</div>
                                    <Table responsive striped>
                                        <thead>
                                        <tr>
                                            <th nowrap='true'>{t('fuel_type')}</th>
                                            <th nowrap='true'>{t('discount')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {item.fueltype && item.fueltype.length ?
                                            item.fueltype.map(item1 =>
                                                <tr key={item1.fuelType}>
                                                    <td>
                                                        <div className='table_head_txt'>{t('fuel_type')}։</div>
                                                        {item1.name}
                                                    </td>
                                                    <td>
                                                        <div className='table_head_txt'>{t('discount')}։</div>
                                                        {item1.discount}
                                                    </td>

                                                </tr>
                                            ) : ''}

                                        </tbody>

                                    </Table>
                                </td>

                                <td style={{width: '10%'}} nowrap='true' className="actions">
                                    <div className='table_head_txt'>{t('action')}:</div>
                                    <span className="edit fa" onClick={(e) => {
                                        this.onBtnClick(e, item)
                                    }} data_modal="EditFuelDiscounts"></span>

                                </td>
                            </tr>
                        )
                        : false
                    }</tbody>

                </Table>
                <div className="pagin_cont">
                    {Count && Count > 10 ?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count : ''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        : ''}
                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    const {data, Count} = state.getTable
    const {data1} = state.GetUserInfo
    return {
        data,
        data1,
        Count
    };
}

const connectedDiscountManagements = connect(mapStateToProps)(withTranslation()(DiscountManagements));
export {connectedDiscountManagements as DiscountManagements};
