import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions,getInfo } from '../../../actions/index';
import { withTranslation } from 'react-i18next';
class EditAveragePrice extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data;
        this.state = {
            user:{
                id:EditContent.id,
                stationName: EditContent.stationName,
                prices: EditContent.prices
            }

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }

    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleChangeInput =(e) => {
        const target = e.target;
        const { name, value } = target;
        const { user } = this.state;
        let prices = user.prices.map((item)=>{
            if(item.id == name){
                return  {...item, price: parseInt(value)}
            }
            else return item
        })
        this.setState({
            user: {
                ...user,
                prices: prices
            }

        });

    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.EditTable(user,'edit_fuel_price_table'));
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('edit')} {this.state.user.stationName} {t('the_fuel_price')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                    {this.state.user.prices ?  this.state.user.prices.map( (item, index)=>
                        <label key={item.id}>
                            <span className="label_txt">{item.name}</span>
                            <input type="number" className="form-control" min="0" name={item.id} value={item.price} onChange={this.handleChangeInput}/>
                        </label>
                    ):''}

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state

    return {
        modals,
        alert,
    };
}

export default connect(mapStateToProps)(withTranslation()(EditAveragePrice));
