import { userService } from '../services';
import { alertActions } from './';
import { modalActions } from './';

export const getInfo = {
    GetUsersTable,
    GetOperator1Card1Table,
    get_fuelTypes_by_corpId,

    getTable,
    clear_table,

    get_corporate_sell_price,
    averagePrices,
    get_balance,
    get_user_info,
    get_azs_network_list,
    get_status_company_list,
    get_cards_attach_lists,
    get_admin_transactions_header,
    get_admin_attach_card,
    get_operator_update_balance,
    get_admin_transactions_balance,
    get_status_company_list_edit,

    get_fuelTypes_list,
    get_corporate_discounts,
    get_corporate_suppliers,
    get_buyer_corporate_fuel_types,
    get_corporate_users_fueltypes_balance,
    get_refuelings_fuelTypes

};
function GetUsersTable(data) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, 'get_users')
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'GET_USER_TABLE', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function getTable(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'GET_TABLE', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function GetOperator1Card1Table(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'GET_OPERATOR1CARD1_TABLE', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function averagePrices(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'GET_AVERAGE_PRICES', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_balance(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_BALANCE', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_corporate_discounts(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_OPERATORS_AVERAGE_BALANCE', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_user_info(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_USER_INFO', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_azs_network_list(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_AZS_NETWORK_LIST', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_refuelings_fuelTypes(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_REFUELINGS_FUELTYPES', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_admin_transactions_balance(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_ADMIN_TRANSACTIONS_BALANCE', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_buyer_corporate_fuel_types(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data4) { return { type: 'GET_BUYER_CORPORATE_FUEL_TYPES', data4 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function  get_status_company_list(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_STATUS_COMPANY_LIST', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function  get_cards_attach_lists(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_CARDS_ATTACH_LISTS', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function  get_admin_transactions_header(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_ADMIN_TRANSACTIONS_HEADER', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function clear_table() {
    return dispatch => {
        dispatch(success());
    };

    function success() { return { type: 'CLEAR_TABLE',  } }

}
function  get_admin_attach_card(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_ADMIN_ATTACH_CARD', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function  get_operator_update_balance(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data1) { return { type: 'GET_OPERATOR_UPDATE_BALANCE', data1 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function  get_status_company_list_edit(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data5) { return { type: 'GET_STATUS_COMPANY_LIST_EDIT', data5 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}

function get_fuelTypes_list(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data5) { return { type: 'GET_FUEL_TYPES_LIST', data5 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_corporate_sell_price(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(corpUserPrices) { return { type: 'GET_CORPORATE_SELL_PRICE', corpUserPrices } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_corporate_suppliers(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(suppliers) { return { type: 'GET_CORPORATE_SUPPLIERS', suppliers } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_fuelTypes_by_corpId(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(fuelTypes) { return { type: 'GET_FUELTYPES_BY_CORPID', fuelTypes } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function get_corporate_users_fueltypes_balance(sendData, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(sendData, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else {
                        dispatch(success({data, corporateUserId: sendData.corporateUserId}));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(corpFuelTypeBalance) { return { type: 'GET_CORPORATE_USERS_FUELTYPES_BALANCE', corpFuelTypeBalance } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}