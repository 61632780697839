
import React, { Component } from "react";
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import {  Modal, ModalBody } from 'reactstrap';
import { alertActions } from '../../actions/index';
import  Empty  from './empty.js';
import  ChangePassword  from './changePassword.js';
import  ResetPassword  from './resetPassword.js';

import  Success  from './success.js';

import  Fail_popup  from './fail_poup.js';
import  Map  from './map.js';
import  MultyMap  from './multyMap.js';



import  ViewUser  from './view_user.js';
import  ViewGroup  from './view_group.js';
import  DeleteRide  from './delete_ride.js';
import  MessageGroup  from './message_group.js';

// operator1
import  AddUser  from './add_user';
import  DeleteUser  from './delete_user.js';
import  EditUser  from './edit_user.jsx';
import  AttachCard  from './attach_card';
import  UnpinCard  from './unpinCard.js';
import  AddCash  from './card_add_cash';
import  EditOperatorCard  from './edit_card';
import  ReturnCash  from './card_return_cash';
import  ReturnCardBalance  from './returnCardBalance';
import  CancellationConfirmation  from './cancellation_confirmation';
import  AddNewApplication  from './addNewApplication';
import  ApproveConfirmation  from './approveConfirmation';

// admin1
import  AddCorporateUser  from './admin1/add_corporate_user';
import  EditCorporateUser  from './admin1/edit_corporate_user';
import  DeleteCorporateUser  from './admin1/delete_corporate_user';
import  AddRefuelingNetwork  from './admin1/add_refueling_network';
import  EditRefuelingNetwork  from './admin1/edit_refueling_network';
import  DeleteRefuelingNetwork  from './admin1/delete_refueiling_network';
import  AddRefueling  from './admin1/add_refueling';
import  EditRefueling  from './admin1/edit_refueling';
import  EditRefuelingPrices  from './admin1/edit_refueling_prices';
import  DeleteRefueling  from './admin1/delete_refueiling';
import  AddAdmin1User  from './admin1/add_admin1_user';
import  EditAdmin1User  from './admin1/edit_admin1_user';
import  DeleteAdmin1User  from './admin1/delete_admin1_user';
import  AttachCardAdmin1  from './admin1/attach_card_admin1';
import  UnpinCardAdmin1  from './admin1/unpinCard_admin1';
import  AddTerminal  from './admin1/add_terminal';
import  EditTerminal  from './admin1/edit_terminal';
import  DeleteTerminal  from './admin1/delete_terminal';
import  AddRefuelingDetals  from './admin1/add_refueling_detals';
import  EditAveragePrice  from './admin1/edit_average_price';
import  EditFuelPrices  from './admin1/edit_fuel_prices';
import  EditRefuelingNetworkPrices  from './admin1/edit_refuling_network_prices';

// admin2
import  ReturnFunds  from './admin2/return_funds';
import  ReplenishBalance  from './admin2/replenish_balance';
import  EditFuelDiscounts  from './admin2/edit_fuel_discounts';
import  ApproveReplenish  from './admin2/approveReplenish';

// operator2
import  Operator2AddCash  from './operator2/operator2_add_cash';
import  DeleteOperator2User  from './operator2/delete_operator2_user';
import  EditOperator2User  from './operator2/edit_operator2_user';
import  AddOperator2User  from './operator2/add_operator2_user';

import  CancelTransaction  from './FuelSupervisor/cancel_transaction';



class Popup extends Component {
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    render() {
        const components = {
            'Empty': Empty,
            'Success': Success,
            'Fail_popup':Fail_popup,
            'DeleteUser':DeleteUser,
            'Map':Map,
            'MultyMap':MultyMap,
            'ChangePassword':ChangePassword,
            'ReturnCash':ReturnCash,
            'EditFuelPrices':EditFuelPrices,
            'ReturnCardBalance':ReturnCardBalance,
            'CancellationConfirmation':CancellationConfirmation,
            'AddNewApplication':AddNewApplication,
            'ApproveConfirmation':ApproveConfirmation,
            'EditRefuelingPrices':EditRefuelingPrices,
            'EditRefuelingNetworkPrices': EditRefuelingNetworkPrices,

            'ViewUser':ViewUser,
            'ViewGroup':ViewGroup,
            'DeleteRide':DeleteRide,
            'MessageGroup':MessageGroup,

            'UnpinCard':UnpinCard,
            'AttachCard':AttachCard,
            'AddUser':AddUser,
            'EditUser':EditUser,
            'AddCash':AddCash,
            'EditOperatorCard':EditOperatorCard,


            'AddCorporateUser':AddCorporateUser,
            'EditCorporateUser':EditCorporateUser,
            'DeleteCorporateUser':DeleteCorporateUser,
            'AddRefuelingNetwork':AddRefuelingNetwork,
            'EditRefuelingNetwork':EditRefuelingNetwork,
            'DeleteRefuelingNetwork':DeleteRefuelingNetwork,
            'AddRefueling':AddRefueling,
            'EditRefueling':EditRefueling,
            'DeleteRefueling':DeleteRefueling,
            'AddAdmin1User':AddAdmin1User,
            'EditAdmin1User':EditAdmin1User,
            'DeleteAdmin1User':DeleteAdmin1User,
            'AttachCardAdmin1':AttachCardAdmin1,
            'UnpinCardAdmin1':UnpinCardAdmin1,
            'AddTerminal':AddTerminal,
			'EditTerminal':EditTerminal,
            'DeleteTerminal':DeleteTerminal,
            'AddRefuelingDetals':AddRefuelingDetals,
            'EditAveragePrice':EditAveragePrice,


            'ReturnFunds':ReturnFunds,
            'ReplenishBalance':ReplenishBalance,
            'EditFuelDiscounts':EditFuelDiscounts,


            'CancelTransaction':CancelTransaction,
            'ResetPassword':ResetPassword,

            'Operator2AddCash':Operator2AddCash,
            'DeleteOperator2User':DeleteOperator2User,
            'EditOperator2User':EditOperator2User,
            'AddOperator2User':AddOperator2User,
            'ApproveReplenish':ApproveReplenish,

        };
       let FuncMod =  components[this.props.modals.modal_name || 'Empty' ];
        return (

            <div>
                <Modal isOpen={this.props.modals.open_modal} toggle={()=>this.close_modal()}
                       className={this.props.modals && this.props.modals.data && this.props.modals.data.size=="large"?'modal-dialog-centered large':'modal-dialog-centered'}>
                    <button type="button" className="close_mod" onClick={()=>this.close_modal()}><i className="fa fa-times" aria-hidden="true"></i></button>
                    <ModalBody className="mdb">
                          <FuncMod />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        modals: state.modals
    };
}

const connectedPopup = connect(mapStateToProps)(Popup);
export { connectedPopup as Popup};


