/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getInfo, modalActions} from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions } from '../../../actions/index';
import { withTranslation } from 'react-i18next';

class Operator2AddCash extends Component{
    constructor(props){
        super(props)
        const content = this.props.modals.data;
        this.state = {
            cash:{
                id: content.id,
                fuelType: '',
                balance: '',
            },
            content: content,
            unit:''
        }

        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_status_company_list(this.state.cash, "get_head_office_fuel_types"))
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { cash } = this.state;
        this.setState({
            cash: {
                ...cash,
                [name]: value
            }
        });
        if(name==='fuelType'){

            const list  = this.state.content.balance.filter(item => item.fuelId == value)

            if(list.length){
                this.setState({
                    unit: list[0].fuelUnit
                });
            }
            else{
                this.setState({
                    unit: ''
                });
            }

        }
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { cash, content } = this.state;
        const { dispatch } = this.props;
        if(content.type === 'add'){
            dispatch(userActions.EditTable( cash, "replenish_balance_branche"))
        }
       else{
            dispatch(userActions.EditTable( cash, "return_balance_branche"))
        }
    }
    render() {
        const { cash, content } = this.state;
        const { message } = this.props.alert;
        const { data1 } = this.props;
        const { t } = this.props;
        // const { data } = this.props.data1;
        return (
            <div>
                {}
                <div className="modal_head">{content.type === 'add' ? t('replenish_balance') :  t('return_fuel')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                    <div className="driver_data">
                        <div className="col1"><span>{t('branch')}։</span> <div>{content.name}</div></div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>{t('fuel')}</th>
                                <th>{t('head_balance')}</th>
                                <th>{t('branch_balance')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.data1 && this.props.data1.data && this.props.data1.data.balance.map((item, index)=>
                                <tr key={index}>
                                    <td>{item.fuelType}</td>
                                    <td>{item.headOfficeBalance} {item.fuelUnit}</td>
                                    <td>{item.branchBalance} {item.fuelUnit}</td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                    </div>
                    <label>
                        <span className="label_txt">{t('choose_fuel_type')}</span>
                        {data1 && data1.data &&  data1.data.fueltypes &&
                            <select value={cash.fuelType} name="fuelType" onChange={this.handleChangeInput}>
                                <option value="">{t('choose_fuel_type')}</option>
                                {data1.data.fueltypes.map(
                                    (item, index) =>
                                        <option key={index} value={item.fuelId}>{item.fuelType}</option>
                                )}
                            </select>
                        }
                        {message&& message.fuelType && <span className="error_mes">{message.fuelType[0]}</span> }
                    </label>

                    <label className={message&& message.balance &&'error_lab'}>
                        <span className="label_txt">{t('enter_fuel_amount')} {this.state.unit && <span>({this.state.unit})</span>}</span>
                        <input type="number" className="form-control" name="balance" value={cash.balance} onChange={this.handleChangeInput}/>
                        {message && message.balance && <span className="error_mes">{message.balance[0]}</span> }
                    </label>

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b" disabled={ this.props.loadingBar && this.props.loadingBar.default}> {t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}> {t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { data1 } =  state.get_status_company_list
    const {modals, alert, loadingBar} = state;
    return {
        modals,
        alert,
        loadingBar,
        data1
    };
}
export default connect(mapStateToProps)(withTranslation()(Operator2AddCash));
