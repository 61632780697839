import React, { Component } from 'react';
import { connect } from 'react-redux';
import  Cards1  from './cards1';
import  Cards2  from './cards2';
import  Cards3  from './cards3';
import { withTranslation } from 'react-i18next';

class Admin1Cards extends Component {

    constructor(props) {
        super(props);
        this.state={
           activeTab:'tab1'
        }

    }
    changeTab = (e, tab)=>{
       this.setState({
           activeTab:tab
       })
    }

    render() {
        const userRole=JSON.parse( localStorage.getItem('userRole'));
		const { t } = this.props;
        return (

            <div className={`main_container ${userRole ==='operator2' ? "" : "admin1"} `}>
                <div className="tab_top">
                    <div className={this.state.activeTab==='tab1'?"card-tab active":"card-tab"} onClick={ e => this.changeTab(e,'tab1')}>{t('assigned_cards')}</div>
                    <div className={this.state.activeTab==='tab2'?"card-tab active":"card-tab"} onClick={ e => this.changeTab(e,'tab2')}>{t('unassigned_cards')}</div>
                    {(userRole === 'admin1' || userRole === 'superAdmin') &&
                        <div className={this.state.activeTab === 'tab3' ? "card-tab active" : "card-tab"}
                         onClick={e => this.changeTab(e, 'tab3')}>{t('invalid_cards')}</div>
                    }
                </div>
                {this.state.activeTab==='tab1'&& <Cards1 />}
                {this.state.activeTab==='tab2'&& <Cards2 />}
                {(userRole === 'admin1' || userRole === 'superAdmin') && this.state.activeTab==='tab3'&& <Cards3 />}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
    };
}

const connectedAdmin1Cards = connect(mapStateToProps)(withTranslation()(Admin1Cards));
export { connectedAdmin1Cards as Admin1Cards };