import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import { userActions } from '../../../actions/index';
import { withTranslation } from 'react-i18next';
import Select from "react-select";
class EditRefuelingPrices extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data;
        const userRole=JSON.parse( localStorage.getItem('userRole'))
        this.state = {
            user:{
                refuelingNetwork:EditContent.refuelingNetwork,
                id:EditContent.id,
                fuelType: userRole !=='fuelSupervisor' ? [] : EditContent.fuelType,
                external_id: EditContent.external_id ? EditContent.external_id : '',
                stationChainId: EditContent.stationChainId ? EditContent.stationChainId : ''
            },
            sellerFuelType: [],
            selectedOptions: [],
            defaultCheck: [],
            fuelType: EditContent.fuelType && EditContent.fuelType.length ? EditContent.fuelType : [],

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        const userRole=JSON.parse( localStorage.getItem('userRole'))
        if( userRole !=='fuelSupervisor'){
            dispatch(getInfo.get_azs_network_list({}, "get_azs_network_list"))
        }
    }
    componentWillReceiveProps(props){
        if(props.data2 && props.data2 !== this.props.data2){
            const { user } = this.state;
            const x = this.state.fuelType.filter(item => item.checked)
            const sellerFuelType = props.data2.map(item=>{
                return{ value: item.sellerId, label: item.sellerName, fuelTypes: item.fuelTypes}

            })
            const defaultCheck = sellerFuelType.filter(item=> x.filter(item1=> item.fuelTypes.find(item2=>item2.id === item1.id) ).length)
            let fuelTypes = [];
            defaultCheck.forEach((item)=>{
                fuelTypes.push(item.fuelTypes)
            })
            fuelTypes = fuelTypes.flat()
            const newArr = fuelTypes.map(item=>{
                let priceLength = this.state.fuelType.filter(item1=> item1.id === item.id && item1.checked)
                let price;
                if(priceLength.length > 0) {price = priceLength[0].price}
                return {...item, price, checked: this.state.fuelType.filter(item1=> item1.id === item.id && item1.checked).length}
            })
            this.setState({
                user:{
                    ...user,
                    fuelType: newArr
                },
                sellerFuelType,
                defaultCheck
            });
        }
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    handleChangeMulty =(e, nameInp) => {
        const target = e.target;
        const name = target.name;
        const { user } = this.state;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let fuelType = user.fuelType.map((item)=>{
            if(item.id == name){
                return  {...item, [nameInp]: value}
            }
            else return item
        })
        this.setState({
            user: {
                ...user,
                fuelType
            }
        });

    }

    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.EditTable(user,'edit_refuelings_prices'));
    }
    handleChangeMultiSelect = (selectedOption, option) => {
        const { user } = this.state;
        let selectedOptions = this.state.selectedOptions;
        let fuelType1;
        let fuelType = user.fuelType;
        if(selectedOption && selectedOption.length > selectedOptions.length){
            fuelType1 = [...fuelType, ...option.option.fuelTypes]
        }
        else if(selectedOption && selectedOption.length < selectedOptions.length){
            fuelType1 = fuelType.reduce((acc, item) => {
                if (!option.removedValue.fuelTypes.find(e => e.id === item.id)) acc.push(item);
                return acc;} , []);
        } else{
            fuelType1= []
        }

        this.setState({
            user: {
                ...user,
                fuelType: fuelType1
            },
            selectedOptions: selectedOption ? selectedOption : [],
        })
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
        const userRole=JSON.parse( localStorage.getItem('userRole'));
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('edit_gas_price_station')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    {this.state.sellerFuelType.length > 0 && this.state.defaultCheck &&
                        <label>
                            <span className="label_txt">{t('choose_supplier')}</span>
                            <Select
                                isMulti
                                className="select-box"
                                classNamePrefix="react-select"
                                defaultValue={this.state.defaultCheck}
                                onChange={this.handleChangeMultiSelect}
                                options={this.state.sellerFuelType}
                                placeholder={t('choose_supplier')}
                                isClearable={false}
                            />
                        </label>
                    }

                    <div className="multy_check">
                        <span className="label_txt">{t('choose_fuel_type')}</span>
                        {(user.fuelType && user.fuelType.length) ? user.fuelType.map(
                            (item, index)=>
                                userRole==='fuelSupervisor' ?
                                        item.checked ?
                                            <div className="flex-inp-price mod1" key={item.id}>
                                                <label className='checkbox_lab' >
                                                    <span className="label_txt">{item.name}</span>
                                                </label>
                                                <label className="txt_lab">
                                                    <span className="label_txt">{t('price')}</span>
                                                    <input type="number" min={1} pattern="[0-9]{10}" className="form-control" disabled={!item.checked } name={item.id}  value={item.price} onChange={e=>this.handleChangeMulty(e,"price")}/>
                                                </label>
                                            </div>
                                            :''
                                    :
                                    <div className="flex-inp-price" key={item.id}>
                                        <label className='checkbox_lab fix_width_label' >
                                            <input type="checkbox" className="form-control" name={item.id}  checked={item.checked} onChange={e=>this.handleChangeMulty(e, "checked")}/>
                                            <span className="label_txt">{item.name}</span>
                                        </label>
                                        <label className="txt_lab">
                                            <span className="label_txt">{t('price')}</span>
                                            <input type="number" min={1} pattern="[0-9]{10}" className="form-control" disabled={!item.checked } name={item.id}  value={item.price} onChange={e=>this.handleChangeMulty(e,"price")}/>
                                        </label>
                                    </div>
                        ):''}

                    </div>
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const data1 = state.GetAzsNetworkList.data1
    const data2 = state.GetAzsNetworkList.data2;
    return {
        modals,
        alert,
        data1,
        data2
    };
}

export default connect(mapStateToProps)(withTranslation()(EditRefuelingPrices));
