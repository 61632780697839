import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';
import { withTranslation } from 'react-i18next';
import {Table} from "reactstrap";

class ApproveConfirmation extends Component{
    constructor(props){
        super(props)
        const Content =this.props.modals.data;
        this.state = {
            id: Content.id,
            data: Content.fuelbiddetails,
            totalPrice: Content.total_amount,
            file: Content.file,
        }


        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    handleSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        const userRole = JSON.parse( localStorage.getItem('userRole'));
        const {id, data, file} = this.state
        if(userRole === 'admin2'){
            dispatch(userActions.EditTable({bidId: id},'approve_corporate_bid'));
        } else {
            const sendData = {
                supplierCorpId : id,
                file,
                fuelBidDetails: data.map(item=>{return { fuel_type_id: item.fuel_type_id, fuel_price: item.fuel_price, discount_price: item.discount_price, fuel_volume : item.fuel_volume ? Number(item.fuel_volume) : ''}})}
            dispatch(userActions.AddTable(sendData,'create_new_corporate_bid'));
        }


    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }

    render() {
        const { t } = this.props;
        const { data, totalPrice } = this.state;
        return (
            <div>
                <div className="modal_head">{t('approve_confirmation')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                    {this.state.file && <span className="file_text">{this.state.file.name}</span>}
                    <Table responsive striped>
                        <thead>
                        <tr>
                            <th nowrap='true'>{t('fuel_type')}</th>
                            <th nowrap='true'>{t('volume')}</th>
                            <th nowrap='true'>{t('price')}</th>
                            <th nowrap='true'>{t('amount')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.length ?
                            data.map(item1 =>
                                <tr key={item1.fuel_type_id}>
                                    <td>
                                        <div className='table_head_txt'>{t('fuel_type')}։</div>
                                        {item1.name}</td>
                                    <td>
                                        <div className='table_head_txt'>{t('volume')}։</div>
                                        {item1.fuel_volume} {item1.unit === "KG" ? 'AMD' :'L'}</td>
                                    <td>
                                        <div className='table_head_txt'>{t('amount')}։</div>
                                        {item1.discount_price} AMD</td>
                                    <td>
                                        <div className='table_head_txt'>{t('summary')}։</div>
                                        {item1.total_price} AMD</td>
                                </tr>
                            ) : false}
                        </tbody>
                    </Table>
                    <div>{t('total_price')}: {totalPrice} AMD</div>
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('approve')}</button>
                        <button type="button" onClick={this.close_modal} className="btn btn-grey block  m-b">{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals} = state
    return {
        modals
    };
}
export default connect(mapStateToProps)(withTranslation()(ApproveConfirmation));
