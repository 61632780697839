import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  modalActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { Table } from 'reactstrap';
import {userService} from "../../../services";
import { withTranslation } from 'react-i18next';

class Users extends Component {

    constructor(props) {
        super(props);
        this.state={
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                pageSize :10
            },
            removeSearch: false,
            pageRangeDisplayed: 5

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(event) {
        $('table tr').removeClass('edited')
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        });
    }
    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(getInfo.clear_table())
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, 'get_users'))
        });

    }
    SortClass(event){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, 'get_users'))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.getTable(this.state.tableData, 'get_users'))
    }

// && tableData.sortValue == this.value
    onBtnClick(event, data){
        const { dispatch } = this.props;
        // $(this).parent().parent()
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    //
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, 'get_users'))
        });
    }
    handleSubmit =(event)=>{
        event.preventDefault();
        const {dispatch}= this.props;
        dispatch(getInfo.getTable(this.state.tableData, 'get_users'))
        const { tableData } = this.state;
        if(tableData.searchValue !== ''){
            this.setState({
                removeSearch: true
            })
        }
    }
    removeSearch =()=>{
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_users"))
        });
    }
    downloadExcel= (method, name, data) =>{
        userService.downloadExcel(method, data).then(response => {
            if(response.ok){
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${name}.xlsx`;
                    a.click();
                })
            }
            else{
                this.props.dispatch(modalActions.open_modal('Fail_popup','Bad request'));
            }

        });
    }
    render() {
        const {data, Count} =this.props;
        const {tableData} =this.state;
		const { t } = this.props;
        const userRole = JSON.parse( localStorage.getItem('userRole'));
        return (
            <div className="main_container">
                <div className="top_container_search">
                    <form onSubmit={this.handleSubmit} className="form_search m_form">
                        <label className="search">
                            <span className="label_txt">{t('search')}</span>
                            <input type="text" value={tableData.searchValue} onChange={this.handleChange} placeholder="" className="form-control"/>
                            {this.state.removeSearch && <span className="remove_search" onClick={this.removeSearch}>X</span> }
                        </label>
                        <button  className="search1">{t('search_now')}</button>
                        <div className="add_but exel_but_trans" onClick={()=>this.downloadExcel('get_users_excel', t('users'), this.state.tableData)}>{t('export')}</div>
                    </form>
                    {userRole !== 'operator1Viewer' &&
                        <div className="but_cont_search">
                            <div className="add_but"  onClick={(e) => { this.onBtnClick(e, '' ) }}  data_modal="AddUser">{t('add_new_user')}</div>
                        </div>
                    }

                </div>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="id">Id</option>
                    <option value="first_name">{t('by_full_name')}</option>
                    <option value="soc_card">{t('by_soc_card')}</option>
                    <option value="position">{t('by_position')}</option>
                    <option value="auto">{t('by_car')}</option>
                    <option value="phone">{t('by_phone')}</option>
                    <option value="email">{t('by_email')}</option>

                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th  className="clickable" nowrap='true' onClick={this.handleSort} data-val="id">Id</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="first_name">{t('full_name')}</th>
                        <th  className="clickable" nowrap='true' onClick={this.handleSort} data-val="soc_card">{t('soc_card')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="position">{t('position')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="auto">{t('car')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="phone">{t('phone')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="email">{t('email')}</th>
                        {userRole !== 'operator1Viewer' && <th >{t('action')}</th> }
                    </tr>
                    </thead>
                        <tbody>{data ?  data.map( (item, index)=>
                           <tr key={index}>
                                <td style={{width:'3%'}}  nowrap='true'><div className='table_head_txt'>{t('number')}:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                                <td style={{width:'7%'}}  nowrap='true' ><div className='table_head_txt'>Id։</div>{item.id}</td>
                                <td style={{width:'15%'}}  nowrap='true' ><div className='table_head_txt'>{t('full_name')}։</div>{item.first_name} {item.last_name}</td>
                                <td style={{width:'10%'}}  nowrap='true' ><div className='table_head_txt'>{t('soc_card')}։</div>{item.soc_card}</td>
                                <td style={{width:'12%'}}  nowrap='true' ><div className='table_head_txt'>{t('position')}։</div>{item.position}</td>
                                <td style={{width:'12%'}}  nowrap='true' ><div className='table_head_txt'>{t('car')}։</div>{item.auto}</td>
                                <td style={{width:'10%'}}  nowrap='true' ><div className='table_head_txt'>{t('phone')}։</div>{item.phone}</td>
                                <td style={{width:'10%'}}  nowrap='true' ><div className='table_head_txt'>{t('email')}։</div>{item.email}</td>
                               {userRole !== 'operator1Viewer' &&
                                   <td style={{width: '10%'}} nowrap='true' className="actions">
                                       <div className='table_head_txt'>{t('action')}:</div>
                                       <span className="edit fa" onClick={(e) => {
                                            this.onBtnClick(e, item)
                                        }} data_modal="EditUser"/>
                                       {!item.deleted_at ? <span className="delete fa" onClick={(e) => {
                                            this.onBtnClick(e, item)
                                        }} data_modal="DeleteUser"/> : ''}
                                   </td>
                               }
                            </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
     const {data, Count} = state.getTable
    return {
        data,
        Count
    };
}

const connectedUsers = connect(mapStateToProps)(withTranslation()(Users));
export { connectedUsers as Users };