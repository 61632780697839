
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions } from '../../../actions/index';
import { withTranslation } from 'react-i18next';
class DeleteCorporateUser extends Component{
    constructor(props){
        super(props)
        const UserContent =this.props.modals.data;
        this.state = {
           user_id: UserContent.id,
           user_name: UserContent.name

        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    handleSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(userActions.DeleteTable({id:this.state.user_id},'delete_corporate_user'));
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    render() {
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('remove')} {this.state.user_name} {t('the_user')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('yes')}</button>
                        <button type="button" onClick={this.close_modal} className="btn btn-grey block  m-b">{t('no')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals} = state
    return {
        modals
    };
}
export default connect(mapStateToProps)(withTranslation()(DeleteCorporateUser));
