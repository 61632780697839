import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import { userActions } from '../../../actions/index';
import { withTranslation } from 'react-i18next';
import Select from "react-select";
class EditRefueling extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data;
        const userRole=JSON.parse( localStorage.getItem('userRole'))
        this.state = {
            user:{
                nameEnglish:EditContent.azsNameEnglish ,
                name:EditContent.azsName,
                inn:EditContent.inn,
                address:EditContent.address,
                phone:EditContent.phone,
                bankAccount:EditContent.bankAccount,
                refuelingNetwork:EditContent.refuelingNetwork,
                lat:EditContent.lat,
                long:EditContent.long,
                id:EditContent.id,
                fuelType: userRole !=='fuelSupervisor' ? [] : EditContent.fuelType,
                external_id: EditContent.external_id ? EditContent.external_id : '',
                stationChainId: EditContent.stationChainId ? EditContent.stationChainId : ''
            },
            sellerFuelType: [],
            selectedOptions: [],
            defaultCheck: [],
            fuelType: EditContent.fuelType && EditContent.fuelType.length ? EditContent.fuelType : [],

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        const userRole = JSON.parse( localStorage.getItem('userRole'))
        if( userRole !=='fuelSupervisor'){
            dispatch(getInfo.get_azs_network_list({}, "get_azs_network_list"))
        }
    }
    componentWillReceiveProps(props){
        if(props.data2 && props.data2 !== this.props.data2){
            const { user } = this.state;
            const x = this.state.fuelType.filter(item => item.checked)
            const sellerFuelType = props.data2.map(item=>{
                return{ value: item.sellerId, label: item.sellerName, fuelTypes: item.fuelTypes}

            })
            const defaultCheck = sellerFuelType.filter(item=> x.filter(item1=> item.fuelTypes.find(item2=>item2.id === item1.id) ).length)
            let fuelTypes = [];
            defaultCheck.forEach((item)=>{
                fuelTypes.push(item.fuelTypes)
            })
            fuelTypes = fuelTypes.flat()
            const newArr = fuelTypes.map(item=>{
                let priceLength = this.state.fuelType.filter(item1=> item1.id === item.id && item1.checked)
                let price;
                if(priceLength.length > 0) {price = priceLength[0].price}
                return {...item, price, checked: this.state.fuelType.filter(item1=> item1.id === item.id && item1.checked).length}
            })
            this.setState({
                user:{
                    ...user,
                    fuelType: newArr
                },
                sellerFuelType,
                defaultCheck
            });
        }
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    handleChangeMulty =(e, nameInp) => {
        const target = e.target;
        const name = target.name;
        const { user } = this.state;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let fuelType = user.fuelType.map((item)=>{
            if(item.id == name){
                return  {...item, [nameInp]: value}
            }
            else return item
        })
        this.setState({
            user: {
                ...user,
                fuelType
            }
        });

    }

    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        const userRole = JSON.parse( localStorage.getItem('userRole'))
        if( userRole !=='fuelSupervisor') {
            dispatch(userActions.EditTable(user, 'edit_refueling'));
        } else {
            dispatch(userActions.EditTable(user, 'edit_refuelings_prices'));
        }
    }
    handleChangeMultiSelect = (selectedOption, option) => {
        const { user } = this.state;
        let selectedOptions = this.state.selectedOptions;
        let fuelType1;
        let fuelType = user.fuelType;
        if(selectedOption && selectedOption.length > selectedOptions.length){
            fuelType1 = [...fuelType, ...option.option.fuelTypes]
        }
        else if(selectedOption && selectedOption.length < selectedOptions.length){
            fuelType1 = fuelType.reduce((acc, item) => {
                if (!option.removedValue.fuelTypes.find(e => e.id === item.id)) acc.push(item);
                return acc;} , []);
        } else{
            fuelType1= []
        }

        this.setState({
            user: {
                ...user,
                fuelType: fuelType1
            },
            selectedOptions: selectedOption ? selectedOption : [],
        })
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
        const userRole=JSON.parse( localStorage.getItem('userRole'));
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('edit_gas_station')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    {/*<label className={message&& message.nameEnglish &&'error_lab'}>*/}
                        {/*<span className="label_txt">Անուն(English)</span>*/}
                        {/*<input type="text" className="form-control" name="nameEnglish" value={user.nameEnglish} onChange={this.handleChangeInput}/>*/}
                        {/*{message&& message.nameEnglish && <span className="error_mes">{message.nameEnglish[0]}</span> }*/}
                    {/*</label>*/}
                    {userRole!=='fuelSupervisor' &&
                        <>
                        <label className={message&& message.name &&'error_lab'}>
                            <span className="label_txt">{t('name')}</span>
                            <input type="text" className="form-control" name="name" value={user.name} onChange={this.handleChangeInput}/>
                            {message&& message.name && <span className="error_mes">{message.name[0]}</span> }
                        </label>
                        <label className={message&& message.inn &&'error_lab'}>
                            <span className="label_txt">{t('tax_code')}</span>
                            <input type="text" className="form-control" name="inn" value={user.inn} onChange={this.handleChangeInput}/>
                            {message&& message.inn && <span className="error_mes">{message.inn[0]}</span> }
                        </label>
                        <label className={message&& message.address &&'error_lab'}>
                            <span className="label_txt">{t('address')}</span>
                            <input type="text" className="form-control" name="address" value={user.address} onChange={this.handleChangeInput}/>
                            {message&& message.address && <span className="error_mes">{message.address[0]}</span> }
                        </label>

                        <label className={message&& message.phone &&'error_lab'}>
                            <span className="label_txt">{t('phone')}</span>
                            <input type="text" className="form-control" name="phone" value={user.phone} onChange={this.handleChangeInput}/>
                            {message&& message.phone && <span className="error_mes">{message.phone[0]}</span> }
                        </label>
                        <label className={message&& message.bankAccount &&'error_lab'}>
                            <span className="label_txt">{t('bank_account')}</span>
                            <input type="text" className="form-control" name="bankAccount" value={user.bankAccount} onChange={this.handleChangeInput}/>
                            {message&& message.bankAccount && <span className="error_mes">{message.bankAccount[0]}</span> }
                        </label>
                        <label className={message&& message.lat &&'error_lab'}>
                            <span className="label_txt">Lat</span>
                            <input type="text" className="form-control" name="lat" value={user.lat} onChange={this.handleChangeInput}/>
                            {message&& message.lat && <span className="error_mes">{message.lat[0]}</span> }
                        </label>
                        <label className={message&& message.long &&'error_lab'}>
                            <span className="label_txt">Long</span>
                            <input type="text" className="form-control" name="long" value={user.long} onChange={this.handleChangeInput}/>
                            {message&& message.long && <span className="error_mes">{message.long[0]}</span> }
                        </label>
                        <label className={message&& message.external_id &&'error_lab'}>
                            <span className="label_txt">{t('external_system_id')}</span>
                            <input type="text" className="form-control" name="external_id" value={user.external_id} onChange={this.handleChangeInput}/>
                            {message&& message.external_id && <span className="error_mes">{message.external_id[0]}</span> }
                        </label>

                        <label>
                            <span className="label_txt">{t('choose_gas_station_chain')}</span>
                            <select name="stationChainId" value={user.stationChainId}  onChange={this.handleChangeInput}>
                                <option value="">{t('choose_gas_station_chain')}</option>
                                {(this.props.data1 && this.props.data1.length) && this.props.data1.map(item=>
                                    <option value={item.id} key={item.id}>{item.name}</option>
                                )
                                }
                            </select>
                        </label>
                        </>
                    }
                    {/*{userRole !=='fuelSupervisor' && this.state.sellerFuelType.length > 0 && this.state.defaultCheck &&*/}
                    {/*    <label>*/}
                    {/*        <span className="label_txt">{t('choose_supplier')}</span>*/}
                    {/*        <Select*/}
                    {/*            isMulti*/}
                    {/*            className="select-box"*/}
                    {/*            classNamePrefix="react-select"*/}
                    {/*            // styles={styles}*/}
                    {/*            // isDisabled={!user.branchOffice}*/}
                    {/*            // value={this.state.selectedOptions}*/}
                    {/*            defaultValue={this.state.defaultCheck}*/}
                    {/*            onChange={this.handleChangeMultiSelect}*/}
                    {/*            options={this.state.sellerFuelType}*/}
                    {/*            placeholder={t('choose_supplier')}*/}
                    {/*            isClearable={false}*/}
                    {/*        />*/}
                    {/*    </label>*/}
                    {/*}*/}
                    {userRole ==='fuelSupervisor' &&
                        <div className="multy_check">
                            <span className="label_txt">{t('choose_fuel_type')}</span>
                            {(user.fuelType && user.fuelType.length) ? user.fuelType.map(
                                (item, index)=>
                                    userRole==='fuelSupervisor' ?
                                        item.checked ?
                                            <div className="flex-inp-price mod1" key={item.id}>
                                                <label className='checkbox_lab' >
                                                    <span className="label_txt">{item.name}</span>
                                                </label>
                                                <label className="txt_lab">
                                                    <span className="label_txt">{t('price')}</span>
                                                    <input type="number" min={1} pattern="[0-9]{10}" className="form-control" disabled={!item.checked } name={item.id}  value={item.price} onChange={e=>this.handleChangeMulty(e,"price")}/>
                                                </label>
                                            </div>
                                            :''
                                        :
                                        <div className="flex-inp-price" key={item.id}>
                                            <label className='checkbox_lab fix_width_label' >
                                                <input type="checkbox" className="form-control" name={item.id}  checked={item.checked} onChange={e=>this.handleChangeMulty(e, "checked")}/>
                                                <span className="label_txt">{item.name}</span>
                                            </label>
                                            <label className="txt_lab">
                                                <span className="label_txt">{t('price')}</span>
                                                <input type="number" min={1} pattern="[0-9]{10}" className="form-control" disabled={!item.checked } name={item.id}  value={item.price} onChange={e=>this.handleChangeMulty(e,"price")}/>
                                            </label>
                                        </div>
                            ):''}

                        </div>

                    }
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const data1 = state.GetAzsNetworkList.data1
    const data2 = state.GetAzsNetworkList.data2;
    return {
        modals,
        alert,
        data1,
        data2
    };
}

export default connect(mapStateToProps)(withTranslation()(EditRefueling));
