import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions';
import { alertActions } from '../../../actions';
import { userActions } from '../../../actions';
import { withTranslation } from 'react-i18next';

class EditFuelDiscounts extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data;
        this.state = {
            user:{
                fuelType: EditContent.fueltype,
                id: EditContent.id,
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }

    handleChangeMulti =(e, nameInp) => {
        const target = e.target;
        const name = target.name;
        const { user } = this.state;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        let fuelType = user.fuelType.map((item)=>{
            if(item.fuel_type_id == name){
                return  {...item, [nameInp]: value}
            }
            else return item
        })
        this.setState({
            user: {
                ...user,
                fuelType
            }
        });

    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        const sendData = {CorporateUserId : user.id, FuelTypes: user.fuelType.map(item=>{return {fuelTypeId: item.fuel_type_id , discount : Number(item.discount)}})}
        dispatch(userActions.EditTable(sendData,'update_corporate_discount'));
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('change_fuel_discount')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column fix-label">
                    <div className="multy_check">

                        {(user.fuelType && user.fuelType.length) ? user.fuelType.map(
                            (item, index)=>
                                <div className="flex-inp-price mod1" key={item.fuel_type_id}>
                                    <label className='checkbox_lab' >
                                        <span className="label_txt">{item.name}</span>
                                    </label>
                                    <label className="txt_lab">
                                        <span className="label_txt">{t('discount')}</span>
                                        <input type="number" pattern="[0-9]{10}" className="form-control"  name={item.fuel_type_id}  value={item.discount ? item.discount : ''} onChange={e=>this.handleChangeMulti(e,"discount")}/>
                                    </label>
                                </div>

                        ):''}

                    </div>
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                    {message && <div className="global_error">{message.error_description}</div>}
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const data1 = state.GetAzsNetworkList.data1
    return {
        modals,
        alert,
        data1
    };
}

export default connect(mapStateToProps)(withTranslation()(EditFuelDiscounts));
