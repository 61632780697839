import React from 'react';
import { render } from 'react-dom';
import './css/animate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
import './css/media.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './helpers';
import * as serviceWorker from './serviceWorker';
import './i18n';

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('app')
);


serviceWorker.unregister();
