import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux';
import {  modalActions } from '../../../actions/index';
import {getInfo, userActions} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../../../img/loader.gif'
import {userService} from "../../../services";
import FileBase64 from 'react-file-base64';
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import {useTranslation, withTranslation} from 'react-i18next';
import Select from 'react-select';
import {customStyles} from "../../Popups/admin1/edit_terminal";
import {components } from "react-select";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props} >
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
const ValueContainer = ({ children, ...props }) => {
    const { t, i18n } = useTranslation();
    const totalCount = Array.isArray(props.selectProps.value) ? props.selectProps.value.length : 0;
    const handleOutsideClick = (e) => {
        if (!e.target.closest('.react-select__menu') && !e.target.closest('.select-box') && props.selectProps.menuIsOpen) {
            props.selectProps.onMenuClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [props.selectProps.menuIsOpen]);
    return (
        <div onClick={()=> !props.selectProps.menuIsOpen ? props.selectProps.onMenuOpen() : props.selectProps.onMenuClose()} style={{width: 'calc(100% - 80px)'}}>
            <components.ValueContainer  {...props} >
                {totalCount > 0 ? `${t('selected_count')} ${totalCount}` : ''}
            </components.ValueContainer>
        </div>

    );
};
class Admin1Transactions extends Component {
    constructor(props) {
        super(props);
        const userRoleDate = JSON.parse( localStorage.getItem('userRole'));
        const userRoleDateBool = userRoleDate === "operator1"
            || userRoleDate === "admin1"
            || userRoleDate === "superAdmin"
            || userRoleDate === "admin2"
            || userRoleDate === "admin2Viewer"
        ;
        const days = userRoleDateBool ?  1 : 30;
        const newDate = new Date(Date.now() - days*24*60*60*1000);
        this.state = {
            menuIsOpen: false,
            secondMenuIsOpen: false,
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                cardType:'',
                transactionTypeIds:'',
                from: newDate,
                to:null,
                fuelTypeIds:'',
                pageSize :10
            },
            tableData1:{
                from: null,
                to:null,
                id:''
            },
            removeSearch: false,
            pageRangeDisplayed: 5,
            loader: false,
            validationStation: true

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChange1 = this.handleChange1.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(e) {
        const { name, value } = e.target;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                [name]: value,
            }
        });
    }
    handleChange1(e) {
        const { name, value } = e.target;
        const { tableData1 } = this.state;
        this.setState({
            tableData1: {
                ...tableData1,
                [name]: value,
            },
            validationStation: true
        });
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_transactions"))
        });

    }
    SortClass(){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');
    };
    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_transactions"))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        // dispatch(getInfo.GetUsersTable(this.state.tableData))
        dispatch(getInfo.getTable(this.state.tableData, "get_transactions"))
        dispatch(getInfo.get_admin_transactions_header('', 'get_transactions_header'))
        dispatch(getInfo.get_fuelTypes_by_corpId('', 'get_fuelTypes_by_corpId'))
        const userRole=JSON.parse( localStorage.getItem('userRole'))
        if(userRole==="fuelSupervisor" || userRole==="fuelSupervisorViewer"){
            dispatch(userActions.get_report_list('', 'get_station_list'))
        }
        if(userRole==="operator2"  || userRole ==="operator2Viewer"){
            dispatch(getInfo.get_status_company_list({}, "get_operator2_branches_list"))
            // dispatch(userActions.get_report_list('', 'get_operator2_branches_list'))
        }
    }
    onBtnClick(event, data){
        const { dispatch } = this.props;
        // $(this).parent().parent()
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_transactions"))
        });
    }
    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(getInfo.clear_table())
    }
    onChangeDate1 = (date) => {
        const { tableData, tableData1 } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                from: date
            },
            tableData1: {
                ...tableData1,
                from: date
            },

        })
    }
    onChangeDate2 = (date) => {
        const { tableData, tableData1 } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                to: date
            },
            tableData1: {
                ...tableData1,
                to: date
            }

        })
    }
    handleSubmit =(event)=>{
        event.preventDefault();
        const {dispatch}= this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_transactions"))
        const { tableData } = this.state;
        if(tableData.searchValue !== ''){
            this.setState({
                removeSearch: true
            })
        }
    }
    removeSearch =()=>{
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_transactions"))
        });
    }

    saveExcel =()=>{
        const {dispatch}= this.props;
        const userRole=JSON.parse( localStorage.getItem('userRole'));
		const { t } = this.props;
        if(userRole==="fuelSupervisor" || userRole==="fuelSupervisorViewer" ){
            if(!this.state.tableData1.id){
                this.setState({validationStation: false})
            }
            else{
                this.downloadExcelTransactions('get_transactionReportForStation', t('fuel_receipt_summary'), this.state.tableData1)
            }

        }
        else if(userRole==="operator1" || userRole==="operator2"  || userRole==="operator1Viewer"  || userRole==="operator2Viewer"){
            this.downloadExcelTransactions('get_transactionReportForCorp', t('fuel_receipt_summary'), this.state.tableData1)
        }

    }
    downloadExcelTransactions = (method, name, data) =>{
        this.setState({loader: true})
        userService.downloadExcel(method, data).then(response => {
            if(response.ok){
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${name}.xlsx`;
                    a.click();
                    this.setState({loader: false})
                })
            }
            else{
                this.setState({loader: false})
                this.props.dispatch(modalActions.open_modal('Fail_popup','Bad request'));
            }
        });
    }
    sendFile = (file)=>{
        const {dispatch}= this.props;
        dispatch(userActions.sendFile(file, "import_1c"));
        $('.inp_file input').val(null);
    }
    handleChangeMultiple = (selectedOption, name) => {
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                [name]: selectedOption.length ? `[${selectedOption.map(item => item.value)}]` : ''
            }
        })
    }

    render() {
        const userRole = JSON.parse( localStorage.getItem('userRole'));
        const {data, Count, data1, data4, data5, fuelTypes} =this.props;
        const {tableData, tableData1} = this.state;
		const { t } = this.props;
        return (
            <div className={userRole!=='operator1'
            && userRole!=='cardHolder'
            && userRole!=='fuelSupervisor'
            && userRole!=='fuelSupervisorViewer'
            && userRole!=='operator2'
            && userRole!=='operator1Viewer'
            && userRole!=='operator2Viewer'
                ?"main_container admin1":"main_container"
            }>
                <div className="top_container_search">
                    <form onSubmit={this.handleSubmit} className="form_search m_form mod_transactions">
                        <label className="search">
                            <span className="label_txt">{t('search')}</span>
                            <input type="text" name="searchValue" value={tableData.searchValue} onChange={this.handleChange} placeholder="" className="form-control"/>
                            {this.state.removeSearch && <span className="remove_search" onClick={this.removeSearch}>X</span> }
                        </label>
                        {/*<label className="mrg_l">*/}
                        {/*    <span className="label_txt">{t('choose_transaction_type')}</span>*/}
                        {/*    <select name="transactionType" value={tableData.transactionType}  onChange={this.handleChange} >*/}
                        {/*        <option value="" >{t('choose_transaction_type')}</option>*/}
                        {/*        {data1 ?*/}
                        {/*            data1.map(item=>*/}
                        {/*                <option value={item.id} key={item.id}>{item.name}</option>*/}
                        {/*            )*/}
                        {/*        :''}*/}

                        {/*    </select>*/}
                        {/*</label>*/}
                        <div className="mrg_l select-multi-react" style={{width:'350px'}}>
                            <span className="label_txt">{t('choose_transaction_type')}</span>
                            <Select
                                openMenuOnFocus={true}
                                className="select-box"
                                classNamePrefix="react-select"
                                styles={customStyles}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option,
                                    ValueContainer
                                }}
                                isMulti
                                onChange={e=>this.handleChangeMultiple(e, 'transactionTypeIds')}
                                options={data1 && data1.map(item=> {return {value: item.id, label: item.name}})}
                                placeholder={t('choose_transaction_type')}
                                menuIsOpen={this.state.menuIsOpen}
                                // menuIsOpen={true}
                                onMenuOpen={() => this.setState({menuIsOpen: true, secondMenuIsOpen: false})}
                                onMenuClose={() => this.setState({menuIsOpen: false})}
                            />
                        </div>
                        <div className="mrg_l select-multi-react" style={{width:'550px'}}>
                            <span className="label_txt">{t('choose_fuel_type')}</span>
                            <Select
                                className="select-box"
                                classNamePrefix="react-select"
                                styles={customStyles}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                onBlur={() => this.setState({secondMenuIsOpen: false})}
                                components={{
                                    Option,
                                    ValueContainer
                                }}
                                isMulti
                                menuIsOpen={this.state.secondMenuIsOpen}
                                onMenuOpen={()=> this.setState({menuIsOpen: false, secondMenuIsOpen: true})}
                                onMenuClose={() => this.setState({secondMenuIsOpen: false})}
                                onChange={e=>this.handleChangeMultiple(e, 'fuelTypeIds')}
                                options={fuelTypes && fuelTypes.map(item=> {return {value: item.fueltypeid, label: item.fueltypename}})}
                                placeholder={t('choose_transaction_type')}
                            />
                        </div>
                        {/*<label className="mrg_l">*/}
                        {/*    <span className="label_txt">{t('choose_fuel_type')}</span>*/}
                        {/*    <select name="fuelTypeId" value={tableData.fuelType}  onChange={this.handleChange} >*/}
                        {/*        <option value="" >{t('choose_fuel_type')}</option>*/}
                        {/*        {fuelTypes ?*/}
                        {/*            fuelTypes.map(item=>*/}
                        {/*                <option value={item.fueltypeid} key={item.fueltypeid}>{item.fueltypename}</option>*/}
                        {/*            )*/}
                        {/*        :''}*/}

                        {/*    </select>*/}
                        {/*</label>*/}
                        <div className="calendar_cont mrg_l" >
                            <span className="label_txt">{t('start')}</span>
                            <DatePicker
                                selected={tableData.from}
                                onChange={date => this.onChangeDate1(date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy HH:mm aa"
                                injectTimes={[
                                    setHours(setMinutes(new Date(), 59), 23)
                                ]}
                                // showTimeInput
                                // customTimeInput={<ExampleCustomTimeInput />}
                            />
                        </div>
                        <div className="calendar_cont mrg_l" >
                            <span className="label_txt">{t('end')}</span>

                            <DatePicker
                                selected={tableData.to}
                                onChange={date => this.onChangeDate2(date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy HH:mm"
                                injectTimes={[
                                    setHours(setMinutes(new Date(), 59), 23)
                                ]}
                            />

                        </div>

                        <button  className="search1">{t('search')}</button>

                        <div className="add_but exel_but_trans"  onClick={()=>this.downloadExcelTransactions("get_transactionsForReport", t('fuel_transaction_report'), this.state.tableData)}>{t('report')}</div>
                        {(userRole === "admin2" || userRole === 'admin2Viewer') &&
                         <>
                             <div className="add_but exel_but_trans"
                                  onClick={()=>this.downloadExcelTransactions("filling_station_report", t('filling_station_report'),  this.state.tableData)}>
                                 {t('filling_station_report')}
                             </div>
                             <div className="add_but exel_but_trans"
                                  onClick={()=>this.downloadExcelTransactions("get_TransactionsFor1C", "1C_export",  this.state.tableData)}>
                                 {t('export')} 1c
                             </div>
                         </>
                        }
                    </form>

                    {
                        (userRole==="fuelSupervisor" || userRole==="fuelSupervisorViewer" || userRole==="operator1" || userRole==="operator2" || userRole==="operator2Viewer"|| userRole==="operator1Viewer") ?
                            <form className="form_search m_form mod_transactions">
                                {data4 && (userRole==="fuelSupervisor" || userRole==="fuelSupervisorViewer") && data4.length ?
                                    <label className="mrg_l">
                                        <span className="label_txt">{t('choose_gas_station')}</span>
                                        <select name="id" value={tableData1.id}  onChange={this.handleChange1} >
                                            <option value="" >{t('choose_gas_station')}</option>
                                            {data4.map(item=>
                                                <option value={item.id} key={item.id}>{item.name}</option>
                                            )}
                                        </select>
                                        {!this.state.validationStation && <span className="error-mess">{t('choose_gas_station')}</span>}
                                    </label>
                                    :''
                                }
                                {data5 && (userRole==="operator2" || userRole==="operator2Viewer") && data5.data && data5.data.length ?
                                    <label className="mrg_l">
                                        <span className="label_txt">{t('choose_branch')}</span>
                                        <select name="id" value={tableData1.id}  onChange={this.handleChange1} >
                                            <option value="" >{t('choose_branch')}</option>
                                            {data5.data.map(item=>
                                                <option value={item.value} key={item.value}>{item.label}</option>
                                            )}
                                        </select>
                                        {!this.state.validationStation && <span className="error-mess">{t('choose_branch')}</span>}
                                    </label>
                                    :''
                                }

                                <div className="add_but exel_but_trans"  onClick={this.saveExcel}>{(userRole==="fuelSupervisor" || userRole==="fuelSupervisorViewer") ? t('report_sumary_fuelSupervisor') : t('report_sumary')}</div>
                                {( userRole==="operator1" || userRole==="operator1Viewer" || userRole==="operator2") &&
                                    <div className="add_but exel_but_trans"
                                         onClick={()=>this.downloadExcelTransactions("card_summary_report", t('card_summary_report'), this.state.tableData1)}>
                                        {t('card_summary_report')}
                                    </div>}
                            </form>
                            :''

                    }
                </div>
                {(userRole==='admin2' || userRole==='superAdmin') && <div className="but_container_bot but_cont_search">
                    <div className="add_but" onClick={(e) => { this.onBtnClick(e, {size: 'large', replenish: true} ) }}  data_modal="ReplenishBalance">{t('replenish_balance')}</div>
                    <div className="add_but but2" onClick={(e) => { this.onBtnClick(e, {size: 'large', replenish: false} ) }}  data_modal="ReplenishBalance">{t('refund')}</div>
                    <label className="add_but inp_file">
                        <FileBase64
                            multiple={ false }
                            onDone={ this.sendFile} />
                        <span>{t('import_autocard')}</span>
                    </label>
                </div>
                }

                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="transactionNumber">{t('by_transaction_number')}</option>
                    <option value="transactionType">{t('by_transaction_type')}</option>
                    <option value="transactionDate">{t('by_transaction_date')}</option>
                    {(userRole ==="fuelSupervisor" || userRole ==="fuelSupervisorViewer" || userRole==="operator1" || userRole==="operator1Viewer" || userRole==="admin1") && <option value="carPlate">{t('car_plate')}</option>}
                    <option value="senderName">{t('sender')}</option>
                    {userRole !=="fuelSupervisor" && userRole !=="fuelSupervisorViewer" && userRole!=="operator1" && userRole!=="operator1Viewer" && userRole!=="cardHolder" && userRole!=="operator2" && userRole!=="operator2Viewer" && <option value="senderAccount">{t('debet_account')}</option>                     }
                    <option value="receiverName">{t('receiver')}</option>
                    {userRole!=="fuelSupervisor" && userRole!=="fuelSupervisorViewer" && userRole!=="operator1" && userRole!=="operator1Viewer" && userRole!=="cardHolder" && userRole!=="operator2"  && userRole!=="operator2Viewer" && <option value="receiverAccount">{t('credit_account')}</option>}
                    <option value="fuelType">{t('by_fuel_type')}</option>

                </select>

                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th  className="clickable" onClick={this.handleSort} data-val="transactionNumber">{t('transaction_number')}</th>
                        <th  className="clickable" onClick={this.handleSort} data-val="transactionType">{t('transaction_type')}</th>
                        <th className="clickable"  onClick={this.handleSort} data-val="transactionDate">{t('transaction_date')}</th>
                        {(userRole ==="fuelSupervisor" || userRole ==="fuelSupervisorViewer" || userRole==="operator1"  || userRole==="operator1Viewer" || userRole==="admin1") && <th className="clickable"  onClick={this.handleSort} data-val="carPlate">{t('car_plate')}</th>}
                        <th  className="clickable" nowrap='true' onClick={this.handleSort} data-val="senderName">{t('sender')}</th>
                        {userRole !== "fuelSupervisor" && userRole !== "fuelSupervisorViewer" && userRole !== "operator1" && userRole!=="operator1Viewer"  && userRole!=="operator2Viewer"  && userRole !== "cardHolder" && userRole!=="operator2" && userRole !== "admin2" && userRole !== 'admin2Viewer' &&
                        <th className="clickable" onClick={this.handleSort} data-val="senderAccount">{t('debet_account')}</th>
                        }
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="receiverName">{t('receiver')}</th>
                        {userRole !== "fuelSupervisor" && userRole !== "fuelSupervisorViewer" && userRole !== "operator1"  && userRole!=="operator1Viewer"  && userRole!=="operator2Viewer" && userRole !== "cardHolder" && userRole!=="operator2" && userRole !== "admin2" && userRole !== 'admin2Viewer' &&
                        <th className="clickable" onClick={this.handleSort} data-val="receiverAccount">{t('credit_account')}</th>
                        }
                        <th className="clickable"  onClick={this.handleSort} data-val="fuelType">{t('fuel_type')}</th>
                        <th className="clickable"  >{t('fuel_unit')}</th>
                        <th className="clickable"  >{t('transaction_amount')}</th>

                    </tr>
                    </thead>
                        <tbody>{data && data.length ?  data.map( (item, index)=>
                           <tr key={item.transactionNumber}>
                                <td style={{width:'3%'}}  nowrap='true'><div className='table_head_txt'>{t('number')}:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                                <td style={{width:'7%'}}  nowrap='true' ><div className='table_head_txt'>{t('transaction_number')}։ </div>{item.transactionNumber}</td>
                                <td style={{width:'7%'}}  nowrap='true' ><div className='table_head_txt'>{t('transaction_type')}։ </div>{item.transactionType}</td>
                                <td style={{width:'7%'}}  nowrap='true' ><div className='table_head_txt'>{t('transaction_date')}։ </div>{item.transactionDate}</td>
                                {(userRole ==="fuelSupervisor" || userRole ==="fuelSupervisorViewer" || userRole==="operator1" ||   userRole==="operator1Viewer" || userRole==="admin1") && <td style={{width:'7%'}}  nowrap='true' ><div className='table_head_txt'>{t('car_plate')}։ </div>{item.carPlate}</td>}
                                <td style={{width:'15%'}}  nowrap='true' ><div className='table_head_txt'>{t('sender')}։</div>{item.senderName}</td>
                               {userRole !== "fuelSupervisor" && userRole !== "fuelSupervisorViewer" && userRole !== "operator1"  && userRole!=="operator1Viewer"  && userRole!=="operator2Viewer" && userRole !== "cardHolder" && userRole!=="operator2" && userRole !== "admin2" && userRole !== 'admin2Viewer' &&
                               <td style={{width: '7%'}} nowrap='true'>
                                   <div className='table_head_txt'>{t('debet_account')}։</div>
                                   {item.senderAccount}</td>
                               }
                                <td style={{width:'15%'}}  nowrap='true' ><div className='table_head_txt'>{t('receiver')}։</div>{item.receiverName}</td>
                               {userRole !== "fuelSupervisor" && userRole !== "fuelSupervisorViewer" && userRole !== "operator1" && userRole!=="operator1Viewer"  && userRole!=="operator2Viewer" && userRole !== "cardHolder" && userRole!=="operator2" && userRole !== "admin2" && userRole !== 'admin2Viewer' &&
                               <td style={{width: '7%'}} nowrap='true'>
                                   <div className='table_head_txt'>{t('credit_account')}։</div>
                                   {item.receiverAccount}</td>
                               }
                                <td style={{width:'7%'}}  nowrap='true' ><div className='table_head_txt'>{t('fuel_type')}</div>{ typeof item.fuelType==="string"? item.fuelType :''}</td>
                                <td style={{width:'7%'}}  nowrap='true' ><div className='table_head_txt'>{t('fuel_unit')}</div>{item.fuelAmount} {item.fuelUnit}</td>
                                <td style={{width:'7%'}}  nowrap='true' ><div className='table_head_txt'>{t('transaction_amount')}</div>{item.amount} AMD</td>
                            </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>
                {this.state.loader && <div className="loader"><img src={Loader} alt=""/></div>}
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data, Count} = state.getTable;
    const {data1} = state.get_admin_transactions_header;
    const {data2} = state.get_transaction_all;
    const {data3} = state.get_report;
    const {data4} = state.get_report_list;
    const {fuelTypes} = state.get_fuelTypes_by_corpId;
    const data5 = state.get_status_company_list.data1;
    return {
        data,
        data1,
        data2,
        data3,
        data4,
        data5,
        fuelTypes,
        Count
    };
}

const connectedAdmin1Transactions = connect(mapStateToProps)(withTranslation()(Admin1Transactions));
export { connectedAdmin1Transactions as Admin1Transactions };