import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import { withTranslation } from 'react-i18next';
import Select from "react-select";
class AddRefuelingDetals extends Component{
    constructor(props){
        super(props)
        const content =this.props.modals.data;
        this.state = {
            user:{
                // nameEnglish:'' ,
                name:'',
                inn:'',
                address:'',
                phone:'',
                bankAccount:'',
                stationChainId: content,
                lat:'',
                long:'',
                fuelType:[],
                external_id: ''
            },
            sellerFuelType: [],
            selectedOptions: []

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_azs_network_list({}, "get_azs_network_list"))
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    componentWillReceiveProps(props){
        if(props.data1){
            const { user } = this.state;
            this.setState({
                user: {
                    ...user,
                    stationChainId: props.data1[0].id
                }
            });
        }
        if(props.data2 && props.data2 !== this.props.data2){
            const sellerFuelType = props.data2.map(item=>{
                return{ value: item.sellerId, label: item.sellerName, fuelTypes: item.fuelTypes}

            })
            this.setState({
                sellerFuelType,
            });
        }
    }
    handleChangeMultiSelect = (selectedOption, option) => {
        const { user } = this.state;
        let selectedOptions = this.state.selectedOptions;
        let fuelType1;
        let fuelType = user.fuelType;
        if(selectedOption && selectedOption.length > selectedOptions.length){
            // fuelType.concat(option.fuelTypes)
            fuelType1= [...fuelType, ...option.option.fuelTypes]
        }
        else if(selectedOption && selectedOption.length < selectedOptions.length){
            fuelType1 = fuelType.reduce((acc, item) => {
                if (!option.removedValue.fuelTypes.find(e => e.id === item.id)) acc.push(item);
                return acc;} , []);
        } else{
            fuelType1= []
        }

        this.setState({
            user: {
                ...user,
                fuelType: fuelType1
            },
            selectedOptions: selectedOption ? selectedOption : [],
        })
    }
    handleChangeMulty =(e, nameInp) => {
        const target = e.target;
        const name = target.name;
        const { user } = this.state;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let fuelType = user.fuelType.map((item)=>{
            if(item.id == name){
                return  {...item, [nameInp]: value}
            }
            else return item
        })
        this.setState({
            user: {
                ...user,
                fuelType
            }

        });

    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.AddTable(user, "add_refueling"))

    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('add_new_gas_station')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    {/*<label className={message&& message.nameEnglish &&'error_lab'}>*/}
                        {/*<span className="label_txt">Անուն(English)</span>*/}
                        {/*<input type="text" className="form-control" name="nameEnglish" value={user.nameEnglish} onChange={this.handleChangeInput}/>*/}
                        {/*{message&& message.nameEnglish && <span className="error_mes">{message.nameEnglish[0]}</span> }*/}
                    {/*</label>*/}
                    <label className={message&& message.name &&'error_lab'}>
                        <span className="label_txt">{t('name')}</span>
                        <input type="text" className="form-control" name="name" value={user.name} onChange={this.handleChangeInput}/>
                        {message&& message.name && <span className="error_mes">{message.name[0]}</span> }
                    </label>
                    <label className={message&& message.inn &&'error_lab'}>
                        <span className="label_txt">{t('tax_code')}</span>
                        <input type="text" className="form-control" name="inn" value={user.inn} onChange={this.handleChangeInput}/>
                        {message&& message.inn && <span className="error_mes">{message.inn[0]}</span> }
                    </label>
                    <label className={message&& message.address &&'error_lab'}>
                        <span className="label_txt">{t('address')}</span>
                        <input type="text" className="form-control" name="address" value={user.address} onChange={this.handleChangeInput}/>
                        {message&& message.address && <span className="error_mes">{message.address[0]}</span> }
                    </label>

                    <label className={message&& message.phone &&'error_lab'}>
                        <span className="label_txt">{t('phone')}</span>
                        <input type="text" className="form-control" name="phone" value={user.phone} onChange={this.handleChangeInput}/>
                        {message&& message.phone && <span className="error_mes">{message.phone[0]}</span> }
                    </label>
                    <label className={message&& message.bankAccount &&'error_lab'}>
                        <span className="label_txt">{t('bank_account')}</span>
                        <input type="text" className="form-control" name="bankAccount" value={user.bankAccount} onChange={this.handleChangeInput}/>
                        {message&& message.bankAccount && <span className="error_mes">{message.bankAccount[0]}</span> }
                    </label>
                    <label className={message&& message.lat &&'error_lab'}>
                        <span className="label_txt">Lat</span>
                        <input type="text" className="form-control" name="lat" value={user.lat} onChange={this.handleChangeInput}/>
                        {message&& message.lat && <span className="error_mes">{message.lat[0]}</span> }
                    </label>
                    <label className={message&& message.long &&'error_lab'}>
                        <span className="label_txt">Long</span>
                        <input type="text" className="form-control" name="long" value={user.long} onChange={this.handleChangeInput}/>
                        {message&& message.long && <span className="error_mes">{message.long[0]}</span> }
                    </label>
                    <label className={message&& message.external_id &&'error_lab'}>
                        <span className="label_txt">{t('external_system_id')}</span>
                        <input type="text" className="form-control" name="external_id" value={user.external_id} onChange={this.handleChangeInput}/>
                        {message&& message.external_id && <span className="error_mes">{message.external_id[0]}</span> }
                    </label>
                    <label>
                        <span className="label_txt">{t('choose_supplier')}</span>
                        <Select
                            isMulti
                            className="select-box"
                            classNamePrefix="react-select"
                            // styles={customStyles}
                            // isDisabled={!user.branchOffice}
                            // defaultValue={data1 && data1.data.filter(item=>item.value === this.state.user.azs)}
                            onChange={this.handleChangeMultiSelect}
                            options={this.state.sellerFuelType}
                            placeholder={t('choose_supplier')}
                            isClearable={false}
                        />
                    </label>
                    <div className="multy_check">
                        <span className="label_txt">{t('choose_fuel_type')}</span>
                        {(user.fuelType && user.fuelType.length) ? user.fuelType.map(
                            (item, index)=>
                            <div className="flex-inp-price">
                                <label className='checkbox_lab' key={item.id}>
                                    <input type="checkbox" className="form-control" name={item.id}  checked={item.checked} onChange={e=>this.handleChangeMulty(e, "checked")}/>
                                    <span className="label_txt">{item.name}</span>
                                </label>
                                <label className="txt_lab">
                                    <span className="label_txt">{t('price')}</span>
                                    <input type="number" min={1} pattern="[0-9]{10}" className="form-control" disabled={!item.checked } name={item.id}  value={item.price} onChange={e=>this.handleChangeMulty(e,"price")}/>
                                </label>
                            </div>

                        ):''}

                    </div>
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const data2 = state.GetAzsNetworkList.data2
    return {
        modals,
        alert,
        data2
    };
}

export default connect(mapStateToProps)(withTranslation()(AddRefuelingDetals));
