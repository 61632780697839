import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';
import { sha256} from 'js-sha256';
import {withTranslation} from "react-i18next";

class ChangePassword extends Component{
    constructor(props){
        super(props)
        const content = this.props.modals.data;
        this.state = {
            oldPassword:'',
            newPassword:''
        }


        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        const data={
            oldPassword:sha256(this.state.oldPassword).toUpperCase(),
            newPassword:sha256(this.state.newPassword).toUpperCase()
        }
        dispatch(userActions.changePassword( data,'change_password'))
    }
    render() {
        const { message } = this.props.alert;
        const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('change_password')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    <label className={message&& message.oldPassword &&'error_lab'}>
                        <span className="label_txt">{t('old_password')}</span>
                        <input type="password" className="form-control" name="oldPassword" value={this.state.oldPassword} onChange={this.handleChangeInput}/>
                        {message&& message.oldPassword && <span className="error_mes">{message.oldPassword[0]}</span> }
                    </label>
                    <label className={message&& message.newPassword &&'error_lab'}>
                        <span className="label_txt">{t('new_password')}</span>
                        <input type="password" className="form-control" name="newPassword" value={this.state.newPassword} onChange={this.handleChangeInput}/>
                        {message&& message.newPassword && <span className="error_mes">{message.newPassword[0]}</span> }
                    </label>

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert,
    };
}
export default connect(mapStateToProps)(withTranslation()(ChangePassword));
