
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions, getInfo } from '../../../actions/index';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import {get_buyer_corporate_fuel_types} from "../../../reducers/getInfo.reducer";

const customStyles = {
    container: (base) => ({
        ...base,
        display:'inline-block',
        width: '100%'
    }),
    control: provided => ({
        ...provided,
        minHeight: "30px",
        height: "30px"
    }),
    valueContainer: (base) => ({
        ...base,
        height: "30px",
        padding: "0 8px"
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: "30px"
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
};
class ReplenishBalance extends Component{
    constructor(props){
        super(props)
        const content = this.props.modals.data;
        this.state = {
            user:{
                sellerCorp:'',
                buyerCorp:'',
                fuelTypeDetails:'',
            },
            replenish: content.replenish
        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_admin_transactions_balance({}, "get_admin_transactions_balance"))
    }
    componentWillReceiveProps(props){
        const { dispatch } = this.props;

        if(props.data1 && props.data1 !== this.props.data1){
            const { user } = this.state;
            this.setState({
                user: {
                    ...user,
                    sellerCorp: props.data1.sellerCorp[0].value
                }
            });
            // dispatch(getInfo.get_buyer_corporate_fuel_types({buyerCorpId: props.data1.buyerCorp[0].value}, "get_buyer_corporate_fuel_types"))
        }
        if(props.data4 && props.data4 !== this.props.data4){
            const { user } = this.state;
            this.setState({
                user: {
                    ...user,
                    fuelTypeDetails: props.data4.fuelTypes.map((item)=>{
                        return { fuelType: item.value, checked: false, name:item.label, unit: item.unit, price: 0, fuelAmount: 0}
                    }),
                }
            });
        }
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    handleChange = (selectedOption, name) => {
        const { dispatch } = this.props;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: selectedOption.value
            }
        })
        if(name==='buyerCorp'){
            // this.setState({
            //     unit: selectedOption.unit
            // })
            dispatch(getInfo.get_buyer_corporate_fuel_types({sellerCorpId: user.sellerCorp, buyerCorpId: selectedOption.value}, "get_buyer_corporate_fuel_types"))
        }
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user, replenish } = this.state;
        const { dispatch, t } = this.props;
        const sendData = {
            ...user,
            fuelTypeDetails: user.fuelTypeDetails.filter(item=>item.checked)
        }
        dispatch(modalActions.open_modal("ApproveReplenish", {size:'large', replenish,  title: replenish ? t('approve_transfer') : t('approve_transfer_return'), sendData}))
    }
    handleChangeMulty =(e, nameInp) => {
        const target = e.target;
        const name = target.name;
        const { user } = this.state;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let fuelTypeDetails = user.fuelTypeDetails.map((item)=>{
            if(item.fuelType == name){
                return  {...item, [nameInp]: value}
            }
            else return item
        })
        this.setState({
            user: {
                ...user,
                fuelTypeDetails
            }

        });

    }
    render() {
        const { data1 } = this.props;
        const { user, replenish } = this.state;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{ replenish ? t('replenish_balance') : t('refund') }</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    {/*<label>*/}
                    {/*    <span className="label_txt">{t('select_supplier')}</span>*/}
                    {/*    <Select*/}
                    {/*        className="select-box"*/}
                    {/*        classNamePrefix="react-select"*/}
                    {/*        styles={customStyles}*/}
                    {/*        onChange={e=>this.handleChange(e, 'sellerCorp')}*/}
                    {/*        options={data1 && data1.sellerCorp}*/}
                    {/*        placeholder={t('select_supplier')}*/}
                    {/*        required={true}*/}
                    {/*    />*/}
                    {/*</label>*/}
                    <label>
                        <span className="label_txt">{t('select_corporate_client')}</span>
                        <Select
                            className="select-box"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            onChange={e=>this.handleChange(e, 'buyerCorp')}
                            options={data1 && data1.buyerCorp}
                            placeholder={t('select_corporate_client')}
                        />
                    </label>
                    {user && user.fuelTypeDetails ? user.fuelTypeDetails.map(
                        (item, index) =>
                            <div className="flex-inp-price mod1" key={item.id}>
                                <label className='checkbox_lab fix_width_label' key={item.id}>
                                    <input type="checkbox" className="form-control" name={item.fuelType}
                                           checked={item.checked}
                                           onChange={e => this.handleChangeMulty(e, "checked")}/>
                                    <span className="label_txt">{item.name}</span>
                                </label>
                                <label className="txt_lab" >
                                    <span className="label_txt" style={{width:166}}>{t('volume')}({item.unit})</span>
                                    <input type="number" min={1} pattern="[0-9]{10}" className="form-control" disabled={!item.checked } name={item.fuelType}  value={item.fuelAmount ? item.fuelAmount : ''} onChange={e=>this.handleChangeMulty(e,"fuelAmount")}/>
                                </label>
                                {
                                    replenish && <label className="txt_lab">
                                        <span className="label_txt" style={{width:166}}>{t('unit_price_amd')}</span>
                                        <input type="number" min={1} pattern="[0-9]{10}" className="form-control" disabled={!item.checked } name={item.fuelType}  value={item.price ? item.price : ''} onChange={e=>this.handleChangeMulty(e,"price")}/>
                                    </label>
                                }

                            </div>

                    ):''}
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b" disabled={ (this.props.loadingBar && this.props.loadingBar.default) || !user.buyerCorp || !user.sellerCorp}>{replenish ? t('replenish') : t('refund')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert, loadingBar} = state
    const data1 = state.get_admin_transactions_balance.data1
    const data4 = state.get_buyer_corporate_fuel_types.data4
    return {
        modals,
        alert,
        data1,
        data4,
        loadingBar
    };
}

export default connect(mapStateToProps)(withTranslation()(ReplenishBalance));
