import { authHeader  } from '../helpers';
export const userService = {
    login,
    PostInfo,
    PostFileInfo,
    downloadExcel
};
const config= process.env.REACT_APP_API_URL;
// const config= 'http://online.autocard.am:5001/api/autocard';
// const config= 'https://online.autocard.am:5001/api/autocard';
// const config= 'https://online.autocard.am:5001/api/autocard';
// const config= 'http://109.75.38.80:5002/api/autocard';
// const config= 'http://109.75.38.80:5000/api/autocard';

function login(user) {
    let data ={
        version: "1.0",
        id: "123456",
        method: "login",
        params:user
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)

    };
    return fetch(`${config}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.result ) {
                localStorage.setItem('user_token', JSON.stringify(user.result.token));
                localStorage.setItem('LoginStatus', true);
                localStorage.setItem('userRole', JSON.stringify(user.result.user_role));
            }
            return user;
        });
}
function PostInfo(data1, method) {
    let data ={
        version: "1.0",
        id: "123456",
        method: method,
        params:data1
    }
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    return fetch(`${config}`, requestOptions).then(handleResponse);
}
function downloadExcel(method, params) {
    let data ={
        version: "1.0",
        id: "123456",
        method,
        params
    }
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    return fetch(`${config}`, requestOptions);
}
function PostFileInfo(data1, method) {
    const data = new FormData();
    data.append("version", "1.0");
    data.append("id", "123456");
    data.append("method", method);
    data.append("params", data1);
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader()},
        body: data
    };
    return fetch(`${config}`, requestOptions).then(handleResponse);
}


function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 406) {
                localStorage.removeItem('user_token');
                localStorage.setItem('LoginStatus', false);
                window.location.reload();
            }
            else  {
                const error = data;
                return Promise.reject(error);
            }
        }

        return data;
    });
}
