import React, { Component } from 'react';
import { connect } from 'react-redux';
import  Cards1  from './cards1';
import  Cards2  from './cards2';
import { withTranslation } from 'react-i18next';

class Cards extends Component {

    constructor(props) {
        super(props);
        this.state={
           activeTab:'tab1'

        }

    }
    changeTab = (e, tab)=>{
       this.setState({
           activeTab:tab
       })
    }



    render() {
		const { t } = this.props;
        return (
            <div className="main_container">
                <div className="tab_top">
                    <div className={this.state.activeTab==='tab1'?"card-tab active":"card-tab"} onClick={ e => this.changeTab(e,'tab1')}>{t('assigned_cards')}</div>
                    <div className={this.state.activeTab==='tab2'?"card-tab active":"card-tab"} onClick={ e => this.changeTab(e,'tab2')}>{t('unassigned_cards')}</div>
                </div>
                {this.state.activeTab==='tab1'&& <Cards1 />}
                {this.state.activeTab==='tab2'&& <Cards2 />}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
    };
}

const connectedCards = connect(mapStateToProps)(withTranslation()(Cards));
export { connectedCards as Cards };