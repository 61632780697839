/**
 * Created by user on 27.07.2018.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions } from '../../../actions/index';
import { withTranslation } from 'react-i18next';
class EditRefuelingNetwork extends Component{
    constructor(props){
        super(props)
        const EditContent =this.props.modals.data;
        this.state = {
            user:{
                id:EditContent.id,
                nameEnglish:EditContent.azsNetworkNameEnglish ,
                name:EditContent.azsNetworkName,
                inn:EditContent.inn,
                address:EditContent.address,
                phone:EditContent.phone,
                bankAccount:EditContent.bankAccount,
            }

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.EditTable(user,'edit_refueling_network'));
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('edit_gas_station_chain')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    <label className={message&& message.nameEnglish &&'error_lab'}>
                        <span className="label_txt">{t('name_english')}</span>
                        <input type="text" className="form-control" name="nameEnglish" value={user.nameEnglish} onChange={this.handleChangeInput}/>
                        {message&& message.nameEnglish && <span className="error_mes">{message.nameEnglish[0]}</span> }
                    </label>
                    <label className={message&& message.name &&'error_lab'}>
                        <span className="label_txt">{t('name')}</span>
                        <input type="text" className="form-control" name="name" value={user.name} onChange={this.handleChangeInput}/>
                        {message&& message.name && <span className="error_mes">{message.name[0]}</span> }
                    </label>
                    <label className={message&& message.inn &&'error_lab'}>
                        <span className="label_txt">{t('tax_code')}</span>
                        <input type="text" className="form-control" name="inn" value={user.inn} onChange={this.handleChangeInput}/>
                        {message&& message.inn && <span className="error_mes">{message.inn[0]}</span> }
                    </label>
                    <label className={message&& message.address &&'error_lab'}>
                        <span className="label_txt">{t('address')}</span>
                        <input type="text" className="form-control" name="address" value={user.address} onChange={this.handleChangeInput}/>
                        {message&& message.address && <span className="error_mes">{message.address[0]}</span> }
                    </label>

                    <label className={message&& message.phone &&'error_lab'}>
                        <span className="label_txt">{t('phone')}</span>
                        <input type="text" className="form-control" name="phone" value={user.phone} onChange={this.handleChangeInput}/>
                        {message&& message.phone && <span className="error_mes">{message.phone[0]}</span> }
                    </label>
                    <label className={message&& message.bankAccount &&'error_lab'}>
                        <span className="label_txt">{t('bank_account')}</span>
                        <input type="text" className="form-control" name="bankAccount" value={user.bankAccount} onChange={this.handleChangeInput}/>
                        {message&& message.bankAccount && <span className="error_mes">{message.bankAccount[0]}</span> }
                    </label>


                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert
    };
}

export default connect(mapStateToProps)(withTranslation()(EditRefuelingNetwork));
