import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import React from "react";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	am: {
		translation: {
			"edit_all_gas_price_station" : "Փոփոխել ցանցի բոլոր վառելիքների գները",
			"selected_count" : "Ընտրված քանակ:",
			"edit_gas_price_station" : "Փոփոխել ԱԶՍ-ի վառելիքի գները",
			"show_my_location" : "Ցույց տալ իմ հասցեն",
			"choose_supplier" : "Ընտրել մատակարարներին",
			"show_balance" : "Ցույց տալ մնացորդը",
			"attach_price_file" : "Կցել վճարման անդորրագիր",
			"old_password" : "Գործող գաղտնաբառ",
			"new_password" : "Նոր գաղտնաբառ",
			"approve_transfer" : "Փոխանցման հաստատում",
			"approve_transfer_return" : "Միջոցների վերադարձի հաստատում",
			"attach_file_if_not_empty" : "( կցել առկայության դեպքում )",
			"application_price" : "Հայտի պայմ. գին",
			"month_limit_error" : "Ամսական լիմիտը կարող  է լինի 0 կամ գերազանցել օրվա լիմիտը",
			"month_limit" : "Ամսական լիմիտ",
			"total_price" : "Ընդհանուր գումար",
			"send_application" : "Ուղարկել հայտ",
			"amount" : "Գումար",
			"approve_confirmation" : "Հայտի հաստատում",
			"decline" : "Մերժել",
			"fuel_application" : "Վառելիքի հայտ",
			"cancellation_confirmation" : "Չեղարկման հաստատում",
			"select_application_status" : "Ընտրել հայտի կարգավիճակը",
			"applications" : "Հայտեր",
			"new_application" : "Նոր հայտ",
			"application_number" : "Հայտի համար",
			"application_date" : "Հայտի ամսաթիվ",
			"supplier" : "Մատակարար",

			"tax_code" : "ՀՎՀՀ",
			"address" : "Հասցե",
			"email" : "Էլ․ փոստ",
			"phone" : "Հեռ.",
			"fuel_type" : "Վառ․ տեսակ",
			"volume" : "Ծավալ",
			"price" : "Գին",
			"summary" : "Գումար",
			"role" : "Կարգավիճակ",

			"return_card_balance_message" : "Հաստատելուց հետո Ձեր բոլոր քարտերի առկա մնացորդները ետ կվերադարձվեն կորպորատիվ հաշվին․",
			"return_card_balance_button" : "Մնացորդների վերադարձ",
			"approve" : "Հաստատել",
			"change_fuel_discount" : "Փոփոխել Զեղչերը",
			"discount" : "Զեղչ",
			"discounts" : "Զեղչեր",
			"company_discount_managements" : "Ընկերության",
			"discount_managements" : "Զեղչի կառավարում",
			"sale_price" : "Զեղչված գին",
			"add_new_operator" : "Ավելացնել նոր օպերատոր",
			"change_fuel_price" : "Փոփոխել վառելիքի տվյալները",
			"choose_list" : "Ընտրել ցանկից",
			"head_balance" : "Գլխամասի մնացորդ",
			"branch_balance" : "Մասնաճուղի մնացորդ",
			"card_summary_report" : "Քարտերի ամփոփ հաշվետվություն",
			"filling_station_report" : "Լցակայանների հաշվետվություն",
			"date" : "Ամսաթիվ",
			"operator" : "Օպերատոր",
			"viewer" : "Դիտորդ",
			"select_user_type" : "Ընտրել օգտագործողի տեսակը",
			"account" : "Հաշիվ",

			"users" : "Օգտագործողներ",
			"cards" : "Վառելիքի Քարտեր",
			"gas_stations" : "Լցակայաններ",
			"transactions" : "Գործարքներ",
			"corporate_clients" : "Կորպորատիվ հաճախորդներ",
			"corp_client" : "Կորպ. հաճախորդ",
			"terminals" : "Տերմինալներ",
			"branches" : "Մասնաճյուղեր",
			"branch" : "Մասնաճյուղ",
			"card_number" : "Քարտի համար",
			"no_card" : "Չկա քարտ",
			"end_date" : "Վերջնաժամկետ",
			"fuel_prices" : "Վառելիքի գներ",
			"change_password" : "Փոխել գաղտնաբառը",
			"sign_out" : "Դուրս գալ",
			"assigned_cards" : "Կցված Քարտեր",
			"unassigned_cards" : "Չկցված Քարտեր",
			"invalid_cards" : "Անվավեր քարտեր",
			"search" : "Որոնում",
			"search_now" : "Որոնել",
			"export" : "Արտահանել",
			"add_new_cards" : "Ավելացնել նոր քարտեր",
			"fuel_cards" : " Վառելիքի քարտեր",
			"attach_cards" : "Կցել քարտերը",
			"sort_by" : "Սորտավորել ըստ",
			"by_card" : "Քարտի",
			"by_branch" : "Մասնաճյուղ-ի",
			"cor_client" : "Կորպ. հաճախորդ-ի",
			"by_driver" : "Վարորդի",
			"by_car" : "Մեքենայի",
			"card" : "Քարտ",
			"driver" : "Վարորդ",
			"car" : "Մեքենա",
			"fuel" : "Վառելիք",
			"action" : "Գործողություն",
			"number" : "Համար",
			"balance" : "Մնացորդ",

			"by_fuel_type" : "Վառ․ տեսակ-ի",
			"daily_limit" : "Օրվա լիմիտ",
			"one_time_limit" : "Մեկանգամյա լիմիտ",
			"company_limit" : "Հիմնարկի լիմիտ",
			"remove_card" : "Հանել քարտը",
			"first" : "Սկիզբ",
			"last" : "Ավարտ",
			"in_use" : "Օգտագործվում է",
			"by_end_date" : "Վերջնաժամկետի",
			"by_cardId" : "Card Id",
			"by_cardPan" : "Card pan",
			"by_oldCorpId" : "Old Corp Id",
			"by_disableDate" : "Disable Date",
			"cardId" : "Card Id",
			"cardPan" : "Card pan",
			"oldCorpId" : "Old Corp Id",
			"disableDate" : "Disable Date",
			"average_fuel_price" : "Վառելիքի միջին գինը",
			"petrol" : "Բենզին",

			"litr" : "Լիտր",
			"gas" : "Գազ",
			"kg" : "ԿԳ",
			"by_station_id" : "Station ID",
			"by_station_name" : "Station Name",
			"station_id" : "Station ID",
			"station_name" : "Station Name",
			"add_new_corporate_client" : "Ավելացնել նոր կորպորատիվ հաճախորդ",
			"by_name" : "Անվանումի",
			"by_corp_id" : "Corp id-ի",
			"by_name_english" : "Անվանում(English)-ի",
			"by_taxcode" : "ՀՎՀՀ-ի",
			"by_email" : "Էլ․ փոստի",
			"by_address" : "Հասցեի",
			"by_phone" : "Հեռախոսահամարի",
			"name" : "Անվանում",
			"corp_id" : "Corp id",
			"name_english" : "Անվանում(English)",


			"head_office" : "Գլխամաս",
			"add_new_gas_station" : "Ավելացնել նոր լցակայան",
			"add_new_gas_station_chain" : "Ավելացնել նոր լցակայանների ցանց",
			"bank_account" : "Բանկային հաշիվ",
			"by_bank_account" : "Բանկային հաշիվի",
			"by_gas_station_chain_name" : "Լցակայանի ցանցի անունի",
			"by_gas_station_chain_name_english" : "Լցակայանի ցանցի անունի(English)",
			"gas_station_chain_name" : "Լցակայանի ցանցի անուն",
			"gas_station_chain_name_english" : "Լցակայանի ցանցի անուն(English)",
			"go_back" : "Հետ գնալ",
			"by_gas_station_name" : "Լցակայանի անունի",
			"gas_station_name" : "Լցակայանի անուն",
			"by_gas_station_name_english" : "Լցակայանի անուն(English)",
			"unit" : "Չափ․ միավոր",
			"add_new_terminal" : "Ավելացնել նոր տերմինալ",
			"add_new_user" : "Ավելացնել նոր օգտագործող",
			"by_full_name" : "Անուն/Ազգանուն - ի",
			"by_role" : "Կարգավիճակի",
			"by_soc_card" : "ՀԾՀ - ի",
			"by_position" : "Պաշտոնի",
			"by_company_name" : "Կազմակերպությունի",
			"full_name" : "Անուն/Ազգանուն",

			"soc_card" : "ՀԾՀ",
			"position" : "Պաշտոն",
			"company_name" : "Կազմակերպություն",
			"choose_transaction_type" : "Ընտրել գործարքի տեսակը",
			"start" : "Սկիզբ",
			"end" : "Ավարտ",
			"report" : "Հաշվետվություն",
			"fuel_transaction_report" : "Վառելիքի շարժի հաշվետվություն",
			"choose_gas_station" : "Ընտրեք լցակայանը",
			"choose_gas_stations" : "Ընտրեք լցակայանները",
			"choose_branch" : "Ընտրեք մասնաճյուղը",
			"report_sumary" : "Լցակայանների ամփոփ հաշվետվություն",
			"report_sumary_fuelSupervisor" : " Ամփոփ հաշվետվություն",
			"replenish_balance" : "Լիցքավորել հաշիվը",
			"refund" : "Վերադարձնել միջոցները",
			"sender" : "Տրամադրող",
			"receiver" : "Ստացող",
			"by_transaction_number" : "Գործարքի համարի",
			"transaction_number" : "Գործարքի համար",
			"by_transaction_type" : "Գործարքի տեսակի",
			"transaction_type" : "Գործարքի տեսակ",
			"by_transaction_date" : "Գործարքի ամսաթվի",
			"transaction_date" : "Գործարքի ամսաթիվ",
			"debet_account" : "Դեբետ հաշիվ",
			"credit_account" : "Կրեդիտ հաշիվ",
			"fuel_unit" : "Վառելիքի ծավալ",
			"transaction_amount" : "Գործարքի գումար",
			"firstname" : "Անուն",
			"lastname" : "Ազգանուն",
			"choose_company_name" : "Ընտրել կազմակերպությունը",
			"gas_station" : "Լցակայան",
			"gas_station_chain" : "Լցակայանի ցանց",
			"save" : "Պահպանել",
			"cancel" : "Չեղարկել",
			"external_system_id" : "Արտաքին համակարգի Id",
			"choose_fuel_type" : "Ընտրել վառելիքի տեսակը",
			"choose_head_office" : "Ընտրել Գլխամասը",
			"close" : "Փակել",
			"choose_gas_station_chain" : "Ընտրել լցակայանի ցանցը",
			"assign" : "Կցել",
			"choose_corporate_client" : "Ընտրել կորպորատիվ  հաճախորդին",
			"attach_card" : "Կցել քարտը",
			"choose_cards" : "Ընտրել քարտերը",
			"yes" : "Այո",
			"no" : "Ոչ",
			"remove" : "Ջնջել",
			"the_user" : "օգտագործողին",
			"the_station" : "լցակայանը",
			"the_chain" : "ցանցը",
			"the_terminal" : "տերմինալը",
			"edit_user" : "Փոփոխել օգտագործողի տվյալները",
			"edit" : "Փոփոխել",
			"the_fuel_price" : "վառելիքի գները",
			"fuel_price" : "Վառելիքի գին",
			"edit_corporate_client" : "Փոփոխել կորպորատիվ հաճախորդի տվյալները",
			"edit_gas_station" : "Փոփոխել  ԱԶՍ-ի տվյալները",
			"edit_gas_station_chain" : "Փոփոխել  ԱԶՍ ցանցի տվյալները",
			"edit_terminal" : "Փոփոխել տերմինալի տվյալները",
			"detach" : "Հանել",
			"the_card" : "քարտը",
			"replenish" : "Լիցքավորել",
			"select_supplier" : "Ընտրել Մատակարարին",
			"select_corporate_client" : "Ընտրել կորպորատիվ հաճախորդին",
			"select_fuel_type" : "Ընտրել վառելիքի տեսակը",
			"enter_fuel_amount" : "Մուտքագրել ծավալը",
			"enter_unit_price_amd" : "Մուտքագրել միավորի գինը AMD",
			"unit_price_amd" : "Միավորի գինը (AMD)",
			"unit_price_all" : "Միավորի գինը",
			"import_autocard" : "Ներմուծել autocard",
			"card_balance" : "Քարտերի մնացորդ",
			"company" : "Կազմակերպություն",
			"company_balance" : "Կազմ. մնացորդ",
			"fuel_receipt_summary" : "Վառելիքի ստացման ամփոփ",
			"account_balances" : "Հաշիվների մնացորդներ",
			"name_tax_code" : "Անվանում",
			"unit_price" : "Հաշվարկային գին",

			"select_branch" : "Ընտրել մասնաճյուղ",
			"add_new_driver" : "Ավելացնել նոր վարորդ",
			"available_fuel" : "Հասանելի վառելիք",
			"none" : "Չկա",
			"add" : "Ավելացնել",
			"middlename" : "Հայրանուն",
			"edit_driver" : "Խմբագրել վարորդի տվյալները",
			"edit_fuel_type" : "Խմբագրել վառելիքի գները",
			"fill_card" : "Լիցքավորել քարտը",
			"return_fuel" : "Վերադարձնել վառելիքը",
			"edit_card" : "Փոփոխել քարտը",
			"select_driver" : "Ընտրել վարորդին",
			"all" : "Բոլորը",
			"total_balance" : "Ընդհանուր մնացորդ",
			"driver_balance" : "Վարորդի մնացորդ",
			"volume_fuel_return" : "Վերադարձվող վառելիքի ծավալը",
			"fuel_amount" : "Լիցքավորման ծավալը",
			"fill_list" : "Լիցքավորել ցուցակով",
			"car_plate" : "Մեքենայի համարանիշ",
		}
	},
	en: {
		translation: {
			"edit_all_gas_price_station" : "Еdit all gas price",
			"edit_gas_price_station" : "Edit gas prices",
			"selected_count" : "Selected count:",
			"show_my_location" : "Show my location",
			"choose_supplier" : "Choose supplier",
			"show_balance" : "Show balance",
			"old_password" : "Current password",
			"new_password" : "New password",
			"approve_transfer" : "Approve transfer",
			"approve_transfer_return" : "Approve transfer replenish",
			"unit_price_amd" : "Unit price (AMD)",
			"unit_price_all" : "Unit price",
			"attach_file_if_not_empty" : "( attach if available )",
			"attach_price_file" : "Attach price file",
			"application_price" : "Application price",
			"month_limit_error" : "The monthly limit can be 0 or exceed the daily limit",
			"month_limit" : "Month limit",
			"total_price" : "Total price",
			"send_application" : "Send bid",
			"amount" : "Amount",
			"decline" : "Decline",
			"approve_confirmation" : "Approve confirmation",
			"fuel_application" : "Fuel application",
			"cancellation_confirmation" : "Cancellation confirmation",
			"applications" : "Applications",
			"select_application_status" : "Select application status",
			"new_application" : "New application",
			"application_number" : "Application number",
			"application_date" : "Application date",
			"supplier" : "Supplier",

			"return_card_balance_message" : "Upon approval, the available balance on all your cards will be returned to the corporate account.",
			"return_card_balance_button" : "Return of balances",
			"approve" : "Approve",
			"change_fuel_discount" : "Change fuel discount",
			"discount" : "Discount",
			"discounts" : "Discounts",
			"company_discount_managements" : "Company",
			"discount_managements" : "Управление скидками",
			"sale_price" : "Sale price",
			"add_new_operator" : "Add new operator",
			"edit_fuel_type" : "Edit fuel types",
			"change_fuel_price" : "Change fuel price",
			"fuel_price" : "Fuel price",
			"choose_list" : "Choose list",
			"head_balance" : "Head balance",
			"branch_balance" : "Branch balance",
			"card_summary_report" : "Card summary report",
			"filling_station_report" : "Filling station report",
			"date" : "Date",
			"operator" : "Operator",
			"viewer" : "Viewer",
			"select_user_type" : "Select user type",
			"summary" : "Summary",
			"account" : "Account",
			"users" : "Users",
			"cards" : "Fuel Cards",
			"gas_stations" : "Filling Stations",
			"transactions" : "Transactions",
			"corporate_clients" : "Corporate clients",
			"corp_client" : "Corp. client",
			"terminals" : "Terminals",
			"branches" : "Branches",
			"branch" : "Branch",
			"card_number" : "Card Number",
			"no_card" : "No Card",
			"fuel_prices" : "Fuel prices",
			"change_password" : "Change Password",
			"sign_out" : "Sign out",
			"assigned_cards" : "Assigned Cards",
			"unassigned_cards" : "Unassigned Cards",
			"invalid_cards" : "Invalid Cards",
			"search" : "Search",
			"search_now" : "Search",
			"export" : "Export",
			"add_new_cards" : "Add New Cards",
			"fuel_cards" : " Fuel Cards",
			"assign_cards" : "Assign Cards",
			"sort_by" : "Sort By",
			"by_card" : "Card",
			"by_branch" : "Branch",
			"by_driver" : "Driver",
			"by_car" : "Car",
			"card" : "Card",
			"driver" : "Driver",
			"car" : "Car",
			"fuel" : "Fuel",
			"action" : "Action",
			"number" : "Number",
			"balance" : "Balance",
			"fuel_type" : "Fuel type",
			"by_fuel_type" : "Fuel type",
			"daily_limit" : "Daily Limit",
			"one_time_limit" : "One Time Limit",
			"company_limit" : "Company Limit",
			"remove_card" : "Remove Card",
			"first" : "First",
			"last" : "Last",
			"in_use" : "In Use",
			"by_end_date" : "End Date",
			"end_date" : "End Date",
			"by_cardId" : "Card Id",
			"by_cardPan" : "Card pan",
			"by_oldCorpId" : "Old Corp Id",
			"by_disableDate" : "Disable Date",
			"cardId" : "Card Id",
			"cardPan" : "Card pan",
			"oldCorpId" : "Old Corp Id",
			"disableDate" : "Disable Date",
			"average_fuel_price" : "Average fuel price",
			"petrol" : "Petrol",
			"price" : "Price",
			"litr" : "Litr",
			"gas" : "Gas",
			"kg" : "KG",
			"by_station_id" : "Station ID",
			"by_station_name" : "Station Name",
			"station_id" : "Station ID",
			"station_name" : "Station Name",
			"add_new_corporate_client" : "Add new corporate client",
			"by_name" : "Name",
			"by_corp_id" : "Corp id-ի",
			"by_name_english" : "Name(English)",
			"by_taxcode" : "TIN",
			"by_email" : "Email",
			"by_address" : "Address",
			"by_phone" : "Phone",
			"corp_id" : "Corp id-ի",
			"tax_code" : "TIN",
			"email" : "Email",
			"address" : "Address",
			"phone" : "Phone",
			"head_office" : "Head Office",
			"add_new_gas_station" : "Add New Filling Station",
			"add_new_gas_station_chain" : "Add New Filling Station Chain",
			"bank_account" : "Bank Account",
			"by_bank_acount" : "Bank Account",
			"by_gas_station_chain_name" : "Filling Station Chain Name",
			"by_gas_station_chain_name_english" : "Filling Station Chain Name(English)",
			"gas_station_chain_name" : "Filling Station Chain Name",
			"gas_station_chain_name_english" : "Filling Station Chain Name(English)",
			"go_back" : "Go Back",
			"by_gas_station_name" : "Filling Station Name",
			"gas_station_name" : "Filling Station Name",
			"by_gas_station_name_english" : "Filling Station Name(English)",
			"unit" : "Unit",
			"add_new_terminal" : "Add new termianl",
			"add_new_user" : "Add new user",
			"by_full_name" : "First name/ Last name",
			"by_role" : "Role",
			"by_soc_card" : "SSN",
			"by_position" : "Position",
			"by_company_name" : "Company name",
			"full_name" : "First name/ Last name",
			"role" : "Role",
			"soc_card" : "SSN",
			"position" : "Position",
			"company_name" : "Company / Station",
			"choose_transaction_type" : "Choose Transaction Type",
			"start" : "Start",
			"end" : "End",
			"report" : "Report",
			"fuel_transaction_report" : "Fuel Transaction Report",
			"choose_gas_station" : "Choose filling Station",
			"choose_gas_stations" : "Choose filling Stations",
			"choose_branch" : "Choose branch",
			"report_sumary" : "Stations summary report",
			"report_sumary_fuelSupervisor" : "Summary report",
			"replenish_balance" : "Replenish balance",
			"refund" : "Refund",
			"sender" : "Sender",
			"receiver" : "Receiver",
			"by_transaction_number" : "Transaction Number",
			"transaction_number" : "Transaction Number",
			"by_transaction_type" : "Transaction type",
			"transaction_type" : "Transaction type",
			"by_transaction_date" : "Transaction date",
			"transaction_date" : "Transaction date",
			"debet_account" : "Debet account",
			"credit_account" : "Credit account",
			"fuel_unit" : "Fuel unit",
			"transaction_amount" : "Transaction amount",
			"firstname" : "First name",
			"lastname" : "Last name",
			"choose_company_name" : "Choose company",
			"gas_station" : "Station",
			"gas_station_chain" : "Station chain",
			"save" : "Save",
			"cancel" : "Cancel",
			"name" : "Name",
			"name_english" : "Name(English)",
			"external_system_id" : "External System Id",
			"choose_fuel_type" : "Choose Fuel Type",
			"choose_head_office" : "Choose Head Office",
			"close" : "Close",
			"choose_gas_station_chain" : "Choose Filling Station Chain",
			"assign" : "Assign",
			"choose_corporate_client" : "Choose corporate client",
			"attach_cards" : "Assign cards",
			"attach_card" : "Assign card",
			"choose_cards" : "Choose cards",
			"yes" : "Yes",
			"no" : "No",
			"remove" : "Remove",
			"the_user" : "user",
			"the_station" : "station",
			"the_chain" : "chain",
			"the_terminal" : "terminal",
			"edit_user" : "Edit user's details",
			"edit" : "Edit",
			"the_fuel_price" : "fuel_price",
			"edit_corporate_client" : "Edit corporate user's details",
			"edit_gas_station" : "Edit station's details",
			"edit_gas_station_chain" : "Edit station chain's details",
			"edit_terminal" : "Edit terminal",
			"detach" : "Detach",
			"the_card" : "card",
			"replenish" : "Replenish",
			"select_supplier" : "Select Supplier",
			"select_corporate_client" : "Select corporate client",
			"select_fuel_type" : "Select fuel type",
			"enter_fuel_amount" : "Select fuel amount",
			"enter_unit_price_amd" : "Select unit price AMD",
			"import_autocard" : "Import to autocard",
			"card_balance" : "Card balance",
			"company" : "Company",
			"company_balance" : "Company balance",
			"fuel_receipt_summary" : "Fuel receipt summary",
			"account_balances" : "Account Balances",
			"name_tax_code" : "Name",
			"unit_price" : "Unit price",
			"volume" : "Volume",
			"select_branch" : "Select branch",
			"add_new_driver" : "Add new driver",
			"available_fuel" : "Available fuel",
			"none" : "None",
			"add" : "Add",
			"middlename" : "Father's name",
			"edit_driver" : "Edit driver's details",
			"fill_card" : "Fill the card",
			"return_fuel" : "Return fuel",
			"edit_card" : "Edit card",
			"select_driver" : "Select driver",
			"all" : "All",
			"total_balance" : "Total balance",
			"driver_balance" : "Driver Balance",
			"volume_fuel_return" : "Volume of fuel return",
			"fuel_amount" : "Fuel Amount",
			"fill_list" : "Fill by list",
			"car_plate" : "Vehicle Plate number",
		}
	},
	ru: {
		translation: {
			"edit_all_gas_price_station" : "Изменить все цены на топливо",
			"edit_gas_price_station" : "Изменить цены на топливо",
			"selected_count" : "Выбранное количество:",
			"show_my_location" : "Показать мое местоположение",
			"choose_supplier" : "Выбрать поставщика",
			"show_balance" : "Показать остаток",
			"old_password" : "Текущий пароль",
			"new_password" : "Новый пароль",
			"approve_transfer" : "Одобрить пополнение баланса ",
			"approve_transfer_return" : "Одобрить возврат",
			"attach_file_if_not_empty" : "( прикрепить если он есть )",
			"attach_price_file" : "Закрепить файл заявки",
			"application_price" : "Цена заявки",
			"month_limit_error" : "Месячный лимит может быть равен 0 или превышать дневной лимит",
			"month_limit" : "Месячный лимит",
			"total_price" : "Общая сумма",
			"send_application" : "Отправить заявку",
			"amount" : "Цена",
			"decline" : "Отклонить",
			"approve_confirmation" : "Подтверждение заявки",
			"fuel_application" : "Заявка на топливо",
			"cancellation_confirmation" : "Отмена заявки",
			"applications" : "Заявки",
			"select_application_status" : "Выбрать статус поставщика",
			"new_application" : "Новая заявка",
			"application_number" : "Номер заявки",
			"application_date" : "Дата заявки",
			"supplier" : "Supplier",
			"return_card_balance_message" : "После одобрения доступный остаток на всех ваших картах будет возвращен на корпоративный счет.",
			"return_card_balance_button" : "Возврат остатков",
			"approve" : "Одобрить",
			"change_fuel_discount" : "Изминение скидок",
			"discount" : "Скидка",
			"discounts" : "Скидки",
			"company_discount_managements" : "Компания",
			"discount_managements" : "Управление скидками",
			"sale_price" : "Цена со скидкой",
			"add_new_operator" : "Добавить нового оператора",
			"edit_fuel_type" : "Редактировать цены",
			"change_fuel_price" : "Изменить цены на топливо",
			"fuel_price" : "Цены на топливо",
			"choose_list" : "Выбрать из списка",
			"head_balance" : "Баланс гл. офиса",
			"branch_balance" : "Баланс филиала",
			"card_summary_report" : "Отчет по картам",
			"filling_station_report" : "Отчет заправок",
			"date" : "Дата",
			"operator" : "Оператор",
			"viewer" : "Наблюдатель",
			"select_user_type" : "Выбрать тип пользователя",
			"summary" : "Стоимость",
			"account" : "Счет",
			"users" : "Пользователи",
			"cards" : "Топливные карты",
			"gas_stations" : "Заправки",
			"transactions" : "Транзакции",
			"corporate_clients" : "Корпоративные клиенты",
			"corp_client" : "Корпоративные клиент",
			"terminals" : "Терминалы",
			"branches" : "Филиалы",
			"branch" : "Филиал",
			"card_number" : "Номер карты",
			"no_card" : "Нет карты",
			"fuel_prices" : "Цены на топливо",
			"change_password" : "Изменение пароля",
			"sign_out" : "Выход",
			"assigned_cards" : "Прикрепленные карты",
			"unassigned_cards" : "Не прикрепленные карты",
			"invalid_cards" : "Недействительные карточки",
			"search" : "Поиск",
			"search_now" : "Поиск",
			"export" : "Экспорт",
			"add_new_cards" : "Добавить новые карточки",
			"fuel_cards" : " Топливные карты",
			"assign_cards" : "Прикрепленные карты",
			"sort_by" : "Сортировать по",
			"by_card" : "Карта",
			"by_branch" : "Филиал",
			"by_driver" : "Водитель",
			"by_car" : "Машина",
			"card" : "Карта",
			"driver" : "Водитель",
			"car" : "Машина",
			"fuel" : "Топливо",
			"action" : "Действие",
			"number" : "Номер",
			"balance" : "Баланс",
			"fuel_type" : "Тип топлива",
			"by_fuel_type" : "По типу топлива",
			"daily_limit" : "Дневной лимит",
			"one_time_limit" : "Заправочный лимит",
			"company_limit" : "Лимит Компании",
			"remove_card" : "Удалить Карту",
			"first" : "Первый",
			"last" : "Последний",
			"in_use" : "В Использовании",
			"by_end_date" : "Дата Окончания",
			"end_date" : "Дата Окончания",
			"by_cardId" : "Card Id",
			"by_cardPan" : "Card pan",
			"by_oldCorpId" : "Old Corp Id",
			"by_disableDate" : "Disable Date",
			"cardId" : "Card Id",
			"cardPan" : "Card pan",
			"oldCorpId" : "Old Corp Id",
			"disableDate" : "Disable Date",
			"average_fuel_price" : "Средняя цена на топливо",
			"petrol" : "Бензин",
			"price" : "Цена",
			"litr" : "Литр",
			"gas" : "Газ",
			"kg" : "КГ",
			"by_station_id" : "Station ID",
			"by_station_name" : "Название станции",
			"station_id" : "Station ID",
			"station_name" : "Название станции",
			"add_new_corporate_client" : "Добавить нового корпоративного клиента",
			"by_name" : "Имя",
			"by_corp_id" : "Corp id-ի",
			"by_name_english" : "Имя(English)",
			"by_taxcode" : "Налоговый код",
			"by_email" : "Email",
			"by_address" : "Адрес",
			"by_phone" : "Телефон",
			"corp_id" : "Corp id-ի",
			"tax_code" : "Налоговый код",
			"email" : "Email",
			"address" : "Адрес",
			"phone" : "Телефон",
			"head_office" : "Головной офис",
			"add_new_gas_station" : "Добавить новую заправочную станцию",
			"add_new_gas_station_chain" : "Добавить новую сеть Азс",
			"bank_account" : "Банковский счет",
			"by_bank_acount" : "Банковский счет",
			"by_gas_station_chain_name" : "Название сети АЗС",
			"by_gas_station_chain_name_english" : "Название сети АЗС(English)",
			"gas_station_chain_name" : "Название сети АЗС",
			"gas_station_chain_name_english" : "Название Сети АЗС(English)",
			"go_back" : "Назад",
			"by_gas_station_name" : "Название заправочной станции",
			"gas_station_name" : "Название заправочной станции",
			"by_gas_station_name_english" : "Название заправочной станции(English)",
			"unit" : "Единица измерения",
			"add_new_terminal" : "Добавить новый терминал",
			"add_new_user" : "Добавить нового пользователя",
			"by_full_name" : "Имя/Фамилия",
			"by_role" : "Роль",
			"by_soc_card" : "НСУ",
			"by_position" : "Позиция",
			"by_company_name" : "Название компании",
			"full_name" : "Имя/Фамилия",
			"role" : "Роль",
			"soc_card" : "НСУ",
			"position" : "Позиция",
			"company_name" : "Компания / Станция",
			"choose_transaction_type" : "Выберите тип транзакции",
			"start" : "Начало",
			"end" : "Конец",
			"report" : "Отчет",
			"fuel_transaction_report" : "Отчет о сделке с топливом",
			"choose_gas_station" : "Выберите заправочную станцию",
			"choose_gas_stations" : "Выберите заправочные станции",
			"choose_branch" : "Выберите филиал",
			"report_sumary" : "Отчет по заправочным станциям",
			"report_sumary_fuelSupervisor" : "Отчет",
			"replenish_balance" : "Пополнить баланс",
			"refund" : "Возврат",
			"sender" : "Отправитель",
			"receiver" : "Получатель",
			"by_transaction_number" : "Номер транзакции",
			"transaction_number" : "Номер транзакции",
			"by_transaction_type" : "Тип транзакции",
			"transaction_type" : "Тип транзакции",
			"by_transaction_date" : "Дата транзакции",
			"transaction_date" : "Дата транзакции",
			"debet_account" : "Дебетовый счет",
			"credit_account" : "Кредитный счет",
			"fuel_unit" : "Единица топлива",
			"transaction_amount" : "Сумма транзакции",
			"firstname" : "Имя",
			"lastname" : "Фамилия",
			"choose_company_name" : "Выберите компанию",
			"gas_station" : "Станция",
			"gas_station_chain" : "Сеть станций",
			"save" : "Сохранить",
			"cancel" : "Отмена",
			"name" : "Имя",
			"name_english" : "Имя(English)",
			"external_system_id" : "External System Id",
			"choose_fuel_type" : "Выберите тип топлива",
			"choose_head_office" : "Выберите головной офис",
			"close" : "Закрыть",
			"choose_gas_station_chain" : "Выберите сеть Азс",
			"assign" : "Прикрепить",
			"choose_corporate_client" : "Выберите корпоративного клиента",
			"attach_cards" : "Прикрепить карты",
			"attach_card" : "Прикрепить карту",
			"choose_cards" : "Выберите карты",
			"yes" : "Да",
			"no" : "Нет",
			"remove" : "Удалить",
			"the_user" : "Пользователь",
			"the_station" : "станция",
			"the_chain" : "Сеть",
			"the_terminal" : "Терминал",
			"edit_user" : "Редактирование пользователя",
			"edit" : "Редактирование",
			"the_fuel_price" : "топливная цена",
			"edit_corporate_client" : "Редактирование корпоративного клиента",
			"edit_gas_station" : "Редактирование станции",
			"edit_gas_station_chain" : "Редатирование сеть станции",
			"edit_terminal" : "Редактировать терминал",
			"detach" : "Отсоединить",
			"the_card" : "Карта",
			"replenish" : "Пополнить",
			"select_supplier" : "Выберите Поставщика",
			"select_corporate_client" : "Выберите корпоративного клиента",
			"select_fuel_type" : "Выберите тип топлива",
			"enter_fuel_amount" : "Выберите количество топлива",
			"enter_unit_price_amd" : "Выберите цену за единицу AMD",
			"import_autocard" : "Импорт в autocad",
			"card_balance" : "Баланс карты",
			"company" : "Компания",
			"company_balance" : "Баланс компании",
			"fuel_receipt_summary" : "Fuel receipt summary",
			"account_balances" : "Наименование",
			"name_tax_code" : "Наименование",
			"unit_price" : "Цена за единицу",
			"unit_price_all" : "Цена за единицу",
			"unit_price_amd" : "Цена за единицу (AMD)",
			"volume" : "Объем",
			"select_branch" : "Выберите филиал",
			"add_new_driver" : "Добавить нового водителя",
			"available_fuel" : "Доступное топливо",
			"none" : "Никто",
			"add" : "Добавить",
			"middlename" : "Отчество",
			"edit_driver" : "Редактирование сведений о водителе",
			"fill_card" : "Пополнить карту",
			"return_fuel" : "Возврат топлива",
			"edit_card" : "Редактировать карту",
			"select_driver" : "Выбрать водителья",
			"all" : "Все",
			"total_balance" : "Общий баланс",
			"driver_balance" : "Баланс Водителья",
			"volume_fuel_return" : "Объем возврата топлива",
			"fuel_amount" : "Количество топлива",
			"fill_list" : "Заполнить по списку",
			"car_plate" : "Номерной знак",
		}
	}
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "en",

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;