import React, { Component } from 'react';
import { connect } from 'react-redux';
import {modalActions, userActions} from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import FileBase64 from 'react-file-base64';
import { Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import * as moment from "moment";
import DatePicker from "react-datepicker";

class Cards2 extends Component {

    constructor(props) {
        super(props);
        this.state={
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                pageSize :10,
            },
            removeSearch: false,
            pageRangeDisplayed: 5,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(event) {
        $('table tr').removeClass('edited')
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        }, function () {
            // const _this=this
            // setTimeout(function () {
            //     dispatch(getInfo.GetGroupTable(_this.state.tableData))
            // }, 500)
        });
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_unattached_cards"))
        });

    }
    SortClass(event){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_unattached_cards"))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_admin_unattached_cards"))
    }
    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(getInfo.clear_table())
    }
    onBtnClick(event, data){
        const { dispatch } = this.props;
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_unattached_cards"))
        });
    }
    handleSubmit =(event)=>{
        event.preventDefault();
        const {dispatch}= this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_admin_unattached_cards"))
        const { tableData } = this.state;
        if(tableData.searchValue != ''){
            this.setState({
                removeSearch: true
            })
        }
    }
    removeSearch =()=>{
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_admin_unattached_cards"))
        });
    }
    sendFile =(file)=> {
        const {dispatch}= this.props;
        dispatch(userActions.sendFile(file, "add_cards_admin")).then(()=>{
            this.setState({
                tableData: {
                    searchValue: '',
                    sortValue:'',
                    sortPosition: '',
                    activePage: 1,
                    pageSize :10
                },
                removeSearch: false,
                pageRangeDisplayed: 5

            }, function () {
                dispatch(getInfo.getTable(this.state.tableData, "get_admin_unattached_cards"))
            })

        })
        $('.inp_file input').val(null);
    }

    render() {
        const userRole=JSON.parse( localStorage.getItem('userRole'));
        const {data, Count} =this.props;
        const {tableData} =this.state;
		const { t } = this.props;
        return (
            <div>
                <div className="top_container_search">
                    <form onSubmit={this.handleSubmit} className="form_search m_form">
                        <label className="search">
                            <span className="label_txt">{t('search')}</span>
                            <input type="text" value={tableData.searchValue} onChange={this.handleChange} placeholder="" className="form-control"/>
                            {this.state.removeSearch && <span className="remove_search" onClick={this.removeSearch}>X</span> }
                        </label>
                        <button  className="search1">{t('search_now')}</button>
                    </form>
                    {(userRole==='admin1' || userRole==='superAdmin' || userRole ==='operator2') && <div className="but_cont_search">
                        {userRole !=='operator2'  &&
                        <label className="add_but inp_file">
                            <FileBase64
                                multiple={ false }
                                onDone={ this.sendFile}
                            />
                            <span>{t('add_new_cards')}</span>
                        </label>
                        }
                        {userRole !=='operator2Viewer'  && <div className="add_but but2"  onClick={(e) => { this.onBtnClick(e, 'card2' ) }}  data_modal="AttachCardAdmin1">{t('attach_cards')}</div>}
                    </div>}
                </div>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="card">{t('by_card')}</option>
                    <option value="endDate">{t('by_end_date')}</option>
                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="card">{t('card')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="endDate">{t('end_date')}</th>
                        {/*<th >Գործողություն</th>*/}
                    </tr>
                    </thead>
                        <tbody>{data ? data.map((item, index)=>
                            <tr key={index}>
                                <td style={{width:'5%'}}  nowrap='true'><div className='table_head_txt'>{t('number')}:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                                <td style={{width:'45%'}}  nowrap='true'><div className='table_head_txt'>{t('card')}:</div> {item.card} </td>
                                <td style={{width:'45%'}}  nowrap='true'><div className='table_head_txt'>{t('end_date')}:</div> {item.endDate} </td>


                                {/*<td style={{width:'10%'}} nowrap='true' className="actions">*/}
                                    {/*<div className='table_head_txt'>Գործողություն:</div>*/}
                                    {/*<span className="but_table"  onClick={(e) => { this.onBtnClick(e, '' ) }}  data_modal="AttachCard">Կցել քարտը</span>*/}
                                {/*</td>*/}
                            </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>
                {/*{(userRole==='admin1' || userRole==='superAdmin') && <div className="but_container_bot but_cont_search">*/}
                    {/*<div className="add_but" onClick={(e) => { this.onBtnClick(e, '' ) }}  data_modal="ReplenishBalance">Լցնել քարտերը</div>*/}
                {/*</div>*/}
                {/*}*/}

            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data, Count} = state.getTable
    return {
        data,
        Count
    };
}

export default connect(mapStateToProps)(withTranslation()(Cards2));