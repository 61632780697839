import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../helpers';
import { PrivateRoute, PrivateRoute2 } from '../PrivateRoute';
import { alertActions } from '../../actions';
import {Login}  from '../Login';
import DashboardFix  from '../fixed.dash.jsx';

//operator1
import {Dashboard}  from '../operator1/Dashboard';
import {Users}  from '../operator1/Users';
import {Cards}  from '../operator1/Cards';
import {RefuelingOperator1}  from '../operator1/Refueling';
import {RefuelingOperator1Details}  from '../operator1/RefuelingDetails';
import {Applications}  from '../operator1/Applications';
//admin1 admin2
import {CorporateUsers}  from '../admin1/CorporateUsers';
import {RefuelingAdmin1}  from '../admin1/RefuelingAdmin1';
import {RefuelingDetails}  from '../admin1/RefuelingDetails';
import {Admin1Users}  from '../admin1/Users';
import {DiscountManagements}  from '../admin1/DiscountManagements';
// import {AveragePrice}  from '../admin1/AveragePrice';
import {Admin1Transactions}  from '../admin1/Transactions/admin1transactions';
import {Admin1Cards}  from '../admin1/Cards';
import {Terminals}  from '../admin1/Terminals';
import {FuelPrices}  from '../admin1/FuelPrices';

// Card Holder
import {AveragePriceCardHolder}  from '../CardHolder/AveragePrice';
import {RefuelingCardHolder}  from '../CardHolder/Refueling';
// import {TransactionsCardHolder}  from '../CardHolder/Transactions';

// Operator2
import { Branches }  from '../operator2/Branches';
import { Operator2Users }  from '../operator2/Users';
import {OperatorsAveragePrice} from "../operator1/OperatorsAveragePrice";
// userRole==='operator1' || userRole === 'operator1Viewer'
const role1=[
    {
        component:Dashboard,
        link: '/dashboard'
    },
    {
        component:Users,
        link: '/users'
    },
    {
        component:Cards,
        link: '/cards'
    },
    {
        component:RefuelingOperator1,
        link: '/refueling'
    },
    {
        component:RefuelingOperator1Details,
        link: '/refuelingDetails/:id'
    },
    {
        component:Admin1Transactions,
        link: '/transactions'
    },
    {
        component:OperatorsAveragePrice,
        link: '/average-price'
    },
    {
        component:Applications,
        link: '/applications'
    },

]
// userRole==='admin1'||  userRole==='superAdmin'
const role2=[
    {
        component: CorporateUsers,
        link: '/dashboard'
    },
    {
        component:Admin1Users,
        link: '/users'
    },
    {
        component:Admin1Cards,
        link: '/cards'
    },
    {
        component:RefuelingAdmin1,
        link: '/refueling'
    },
    {
        component:RefuelingDetails,
        link: '/refuelingDetails/:id'
    },
    // {
    //     component:AveragePrice,
    //     link: '/average-price'
    // },
    {
        component:Admin1Transactions,
        link: '/transactions'
    },
    {
        component:Terminals,
        link: '/terminals'
    },
    {
        component:FuelPrices,
        link: '/fuel-prices'
    },

]
// userRole==='cardHolder'
const role3=[
    {
        component: AveragePriceCardHolder,
        link: '/dashboard'
    },
    {
        component: RefuelingCardHolder,
        link: '/refueling'
    },
    {
        component: Admin1Transactions,
        link: '/transactions'
    },
    {
        component:RefuelingAdmin1,
        link: '/refuelingnetwork'
    },
    {
        component:RefuelingDetails,
        link: '/refuelingDetails/:id'
    },

]
// userRole==='fuelSupervisor' || userRole==='fuelSupervisorViewer'
const role4=[
    {
        component: Admin1Transactions,
        link: '/dashboard'
    },
    {
        component:RefuelingAdmin1,
        link: '/refuelingnetwork'
    },
    {
        component:RefuelingDetails,
        link: '/refuelingDetails/:id'
    },

]
// userRole==='operator2' || userRole === 'operator2Viewer'
const role5=[
    {
        component:Dashboard,
        link: '/dashboard'
    },
    {
        component:Operator2Users,
        link: '/users'
    },
    {
        component: Branches,
        link: '/branches'
    },
    {
        component:Admin1Cards,
        link: '/cards'
    },
    {
        component:RefuelingOperator1,
        link: '/refueling'
    },
    {
        component:RefuelingOperator1Details,
        link: '/refuelingDetails/:id'
    },
    {
        component:Admin1Transactions,
        link: '/transactions'
    },
    {
        component:OperatorsAveragePrice,
        link: '/average-price'
    },
    {
        component:Applications,
        link: '/applications'
    },

]
// userRole==='admin2' || userRole === 'admin2Viewer'
const role6=[
    {
        component: CorporateUsers,
        link: '/dashboard'
    },
    {
        component:RefuelingAdmin1,
        link: '/refueling'
    },
    {
        component:RefuelingDetails,
        link: '/refuelingDetails/:id'
    },
    {
        component:Admin1Transactions,
        link: '/transactions'
    },
    {
        component:FuelPrices,
        link: '/fuel-prices'
    },
    {
        component:DiscountManagements,
        link: '/discount-managements'
    },
    {
        component:Applications,
        link: '/applications'
    },
]
class RouteComponent extends React.Component {
    constructor(props) {
        super(props);
        let LoginStatus = JSON.parse(localStorage.getItem('LoginStatus') );
        let user_token = JSON.parse(localStorage.getItem('user_token') )
        if((!LoginStatus && user_token) || (LoginStatus && !user_token)){
            localStorage.removeItem('user_token');
            localStorage.setItem('LoginStatus', false);
            history.push('/');
        }
        history.listen((location, action) => {
            if((!LoginStatus && user_token) || (LoginStatus && !user_token)){
                localStorage.removeItem('user_token');
                localStorage.setItem('LoginStatus', false);
                history.push('/');
            }
            window.scrollTo(0, 0)
            const { dispatch } = this.props;
            dispatch(alertActions.clear());

        });

    }


    render() {

        let LoginStatus = JSON.parse( localStorage.getItem('LoginStatus'))
        const userRole=JSON.parse( localStorage.getItem('userRole'))
        let role;
        if(userRole==='operator1' || userRole === 'operator1Viewer'){
            role=role1;
        }
        else if(userRole==='admin1'||  userRole==='superAdmin'){
            role=role2;
        }
        else if(userRole==='cardHolder'){
            role=role3;
        }
        else if(userRole==='fuelSupervisor' || userRole==='fuelSupervisorViewer'){
            role=role4;
        }
        else if(userRole==='operator2' || userRole === 'operator2Viewer'){
            role=role5;
        }
        else if( userRole==='admin2' || userRole === 'admin2Viewer' ){
            role=role6;
        }
        return (
            <div>

                <Router history={history} >
                    <div>
                        {LoginStatus ? <DashboardFix/>: "" }

                        <Switch>
                                <PrivateRoute2 exact path="/" component={Login}/>
                            {/*<PrivateRoute exact path="/dashboard" component={Home} />*/}
                            {/*<Route component={Nomatch}/>*/}
                            {
                                role && role.map( (item,index) =>{
                                   return <PrivateRoute path={item.link} exact component={item.component} key={index}/>
                                })
                            }


                        </Switch>

                    </div>
                </Router>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert
    };
}

const connectedRouteComponent = connect(mapStateToProps)(RouteComponent);
export { connectedRouteComponent as RouteComponent };