import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions,getInfo } from '../../actions/index';
import { withTranslation } from 'react-i18next';


class AddCash extends Component{
    constructor(props){
        super(props)
        const content = this.props.modals.data;
        this.state = {
            cash:{
                card: content,
                fuelType:'',
                fuelAmount:''
            },
            unit:''
        }


        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_operator_update_balance({id: this.state.cash.card}, "get_operator_update_balance"))
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { cash } = this.state;
        this.setState({
            cash: {
                ...cash,
                [name]: value
            }
        });
        if(name==='fuelType'){

            const list  = this.props.data1.fuelTypesList.filter(item => item.value == value)

            if(list.length){
                this.setState({
                    unit: list[0].unit
                });
            }
            else{
                this.setState({
                    unit: ''
                });
            }

        }
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { cash } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.EditTable( cash, "post_operator_update_balance"))
    }
    render() {
        const { cash } = this.state;
        const { message } = this.props.alert;
		const { t } = this.props;
        // const { data } = this.props.data1;
        return (
            <div>
                <div className="modal_head">{t('fill_card')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                    {this.props.data1 && this.props.data1.data &&
                        <>
                            <div className="driver_data">
                                <div className="col1"><span>{t('driver')}։</span> <div>{this.props.data1.data[0].driver}</div></div>
                                <div className="col1"><span>{t('card')}։</span>  <div>{this.props.data1.data[0].card}</div></div>
                                <div className="col1"><span>{t('car')}։</span>  <div>{this.props.data1.data[0].auto}</div></div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>{t('fuel')}</th>
                                        <th>{t('total_balance')}</th>
                                        <th>{t('driver_balance')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.data1 && this.props.data1.data && this.props.data1.data[0].balance.map((item, index)=>
                                        <tr key={index}>
                                            <td>{item.fuelType}</td>
                                            <td>{item.CorpBalance} {item.fuelUnit}</td>
                                            <td>{item.driverBalance} {item.fuelUnit}</td>
                                        </tr>
                                    )}

                                    </tbody>
                                </table>
                            </div>
                        </>
                    }


                    <label>
                        <span className="label_txt">{t('select_fuel_type')}</span>
                        <select value={cash.fuelType} name="fuelType"  onChange={this.handleChangeInput}>
                            <option value="">{t('select_fuel_type')}</option>
                            {this.props.data1 && this.props.data1.fuelTypesList.map(
                                (item, index)=>
                                    <option key={index} data-id={item.unit} value={item.value} >{item.label}</option>
                            )}
                        </select>
                        {message&& message.fuelType && <span className="error_mes">{message.fuelType[0]}</span> }
                    </label>

                    <label className={message&& message.fuelAmount &&'error_lab'}>
                        <span className="label_txt">{t('fuel_amount')} {this.state.unit && <span>({this.state.unit})</span>}</span>
                        <input type="number" className="form-control" name="fuelAmount" value={cash.fuelAmount} onChange={this.handleChangeInput}/>
                        {message&& message.fuelAmount && <span className="error_mes">{message.fuelAmount[0]}</span> }
                    </label>

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b"  disabled={ this.props.loadingBar && this.props.loadingBar.default}>{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { data1 } =  state.get_operator_update_balance
    const {modals, alert, loadingBar} = state;
    return {
        modals,
        alert,
        loadingBar,
        data1
    };
}

export default connect(mapStateToProps)(withTranslation()(AddCash));
