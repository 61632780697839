
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';

class ResetPassword extends Component{
    constructor(props){
        super(props)
        this.state = {
            username:''
        }


        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        });
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { dispatch } = this.props;
        dispatch(userActions.resetPassword( this.state, 'forgot_password'))
    }
    render() {
        const { message } = this.props.alert;
        return (
            <div>
                <div className="modal_head">Վերականգնել գաղտնաբառը</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    <label className={message&& message.username &&'error_lab'}>
                        <span className="label_txt">Մուտքագրեք մուտքանունը (login)</span>
                        <input type="text" className="form-control" name="username" value={this.state.username} onChange={this.handleChangeInput} required/>
                        {message&& message.username && <span className="error_mes">{message.username[0]}</span> }
                    </label>

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">Ուղարկել</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>Փակել</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert,
    };
}

export default connect(mapStateToProps)(ResetPassword);
