
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions, getInfo } from '../../actions/index';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
import ShowMoreText from "react-show-more-text";
import { AiOutlineArrowDown } from "react-icons/ai";

const customStyles = {
    container: (base) => ({
        ...base,
        display:'inline-block',
        width: '100%'
    }),
    control: provided => ({
        ...provided,
        minHeight: "30px",
        height: "30px"
    }),
    valueContainer: (base) => ({
        ...base,
        height: "30px",
        padding: "0 8px"
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: "30px"
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
};
class AttachCard extends Component{
    constructor(props){
        super(props)
        const EditContent = this.props.modals.data;
        this.state = {
            card:EditContent.item.card,
            attachCard:{
                id:EditContent.item.id,
                driver:'',
                selectAll: false,
                azsList:[],
                fuelType:[],
            },
            sellerFuelType: [],
            selectedOptions: [],

        }


        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_cards_attach_lists({id: this.state.attachCard.id}, "get_cards_attach_lists"))
    }
    componentWillReceiveProps(props){
        if(props.data1){
            const { attachCard } = this.state;
            const sellerFuelType = props.data1.fuelTypesGroupSellers.map(item=>{
                return{ value: item.sellerId, label: item.sellerName, fuelTypes: item.fuelTypes}

            })
            this.setState({
                attachCard: {
                    ...attachCard,
                    // azsList:props.data1.azsList,
                    // fuelType:props.data1.fuelType,
                },
                sellerFuelType
            });
        }
    }
    // handleChange(e) {
    //     const { attachCard } = this.state;
    //     const target = e.target;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     const name = target.name;
    //     this.setState({
    //         attachCard: {
    //             ...attachCard,
    //             [name]: value
    //         }
    //     });
    // }
    handleChangeMulty =(e, action) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { attachCard } = this.state;
        let fuelType = attachCard.fuelType.map((item)=>{
            if(item.id == name){
                if((action === "monthLimit" && Number(item['dayLimit']) >= Number(value) && Number(value) !== 0) || (action === "dayLimit"  && Number(item['monthLimit']) <= Number(value) && Number(item['monthLimit']) !==0)){
                    return  {...item, [action]: value, error: this.props.t("month_limit_error")}
                }
                else return  {...item, [action]: value, error: ''}
            }
            else return item
        })
        if(target.type === 'checkbox'){
            let azsList1 = this.props.data1.azsList.filter((item)=> {
                // const found = fuelType.forEach((field) => item.fuelType.some( field2 =>{if(field2.checked){ return  field.id == field2.id}}  ));
                const found = fuelType.some((field) => {if(field.checked){return item.fuelType.some( field2 =>  field.id == field2.id)  } } );

                return found

            })
            let group = azsList1.reduce((r, a) => { r[a.azsNetworkName] = [...r[a.azsNetworkName] || [], a]; return r;}, {});
            const newArray = [];
            Object.values(group).forEach(item=> newArray.push(...item))
            this.setState({
                attachCard: {
                    ...attachCard,
                    fuelType,
                    azsList: newArray
                }

            });
        }

        else{
            this.setState({
                attachCard: {
                    ...attachCard,
                    fuelType
                }

            });
        }


    }
    handleChangeMulty2 =(e, action) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { attachCard } = this.state;
        let azsList = attachCard.azsList.map((item)=>{
            if(item.stationId == name){
                return  {...item, checked: value}
            }
            else return item
        })
        this.setState({
            attachCard: {
                ...attachCard,
                azsList
            }

        });

    }
    handleChangeMulty3 =(e, action) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { attachCard } = this.state;
        let azsList = attachCard.azsList.map((item)=>{
            return  {...item, checked: value}

        })
        this.setState({
            attachCard: {
                ...attachCard,
                azsList
            }

        });

    }
    handleChangeAll =(e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { attachCard } = this.state;
        this.setState({
            attachCard: {
                ...attachCard,
                selectAll: value
            }

        });

    }
    handleChange = (selectedOption, e) => {
        const { attachCard } = this.state;
        this.setState({
            attachCard: {
                ...attachCard,
                driver: selectedOption.value
            }
        })
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { attachCard } = this.state;
        const { dispatch } = this.props;
        const azsList = attachCard.azsList.filter(item=>item.checked)
        const fuelType = attachCard.fuelType.filter(item=>item.checked)
        dispatch(userActions.DeleteTable( {fuelType,azsList, id:attachCard.id, driver:attachCard.driver, selectAll:attachCard.selectAll},'attach_operator_card'));
    }
    toggleTable = (e) =>{
        document.querySelector('.toggle_table').classList.toggle('display_none')
        document.querySelector('.toggle_button').classList.toggle('reverse')
    }
    handleChangeMultiSelect = (selectedOption, option) => {
        const { attachCard } = this.state;
        let selectedOptions = this.state.selectedOptions;
        let fuelType1;
        let fuelType = attachCard.fuelType;
        if(selectedOption && selectedOption.length > selectedOptions.length){
            // fuelType.concat(option.fuelTypes)
            fuelType1= [...fuelType, ...option.option.fuelTypes]
        }
        else if(selectedOption && selectedOption.length < selectedOptions.length){
            fuelType1 = fuelType.reduce((acc, item) => {
                if (!option.removedValue.fuelTypes.find(e => e.id === item.id)) acc.push(item);
                return acc;} , []);
        } else{
            fuelType1= []
        }

        this.setState({
            attachCard: {
                ...attachCard,
                fuelType: fuelType1
            },
            selectedOptions: selectedOption ? selectedOption : [],
        })
    }
    render() {
        const { attachCard } = this.state;
        const { message } = this.props.alert;
        const { data1 } = this.props;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('attach_card')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                    <span className="label_txt center_mod"> {t('card')} ։ {this.state.card}</span>
                    <label className={message&& message.driver &&'error_lab'}>
                        <span className="label_txt">{t('select_driver')}</span>
                        <Select
                            // value={selectedOption}
                            className="select-box"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            onChange={this.handleChange}
                            options={data1 && data1.driverList}
                            placeholder={t('select_driver')}
                        />
                    </label>
                    {message&& message.driver && <span className="error_mes">{message.driver[0]}</span> }
                    <label>
                        <span className="label_txt">{t('choose_supplier')}</span>
                        <Select
                            isMulti
                            className="select-box"
                            classNamePrefix="react-select"
                            // styles={customStyles}
                            // isDisabled={!user.branchOffice}
                            // defaultValue={data1 && data1.data.filter(item=>item.value === this.state.user.azs)}
                            onChange={this.handleChangeMultiSelect}
                            options={this.state.sellerFuelType}
                            placeholder={t('choose_supplier')}
                            isClearable={false}
                        />
                    </label>
                    <div className={message&& message.driver &&'error_lab'}>
                        <span className="label_txt center_mod">{t('select_fuel_type')}</span>
                        {attachCard && attachCard.fuelType.length ?
                            attachCard.fuelType.map(item=>
                                <div className="multy_fuelType mod1" key={item.id}>
                                    <div>
                                        <span className="label_txt">{t('fuel_type')}<span className="req-star">*</span></span>
                                        <label className='checkbox_lab mod_check'>
                                            <input type="checkbox" className="form-control" name={item.id}  checked={item.checked} onChange={e=>this.handleChangeMulty(e,"checked")}/>
                                            <span className="label_txt">{item.name}</span>
                                        </label>

                                    </div>
                                    <label className="txt_lab">
                                        <span className="label_txt">{t('daily_limit')}({item.fuelUnit === "KG" ? 'AMD' :'L'})<span className="req-star">*</span></span>
                                        <input type="number" min={0}  pattern="[0-9]{10}" className="form-control" disabled={!item.checked } name={item.id}  value={item.dayLimit ? item.dayLimit : ''} onChange={e=>this.handleChangeMulty(e,"dayLimit")}/>
                                    </label>
                                    <label className="txt_lab">
                                        <span className="label_txt">{t('one_time_limit')}({item.fuelUnit === "KG" ? 'AMD' :'L'})<span className="req-star">*</span></span>
                                        <input type="number" min={0} pattern="[0-9]{10}" className="form-control" disabled={!item.checked } name={item.id}  value={item.oneLimit ? item.oneLimit : ''} onChange={e=>this.handleChangeMulty(e,"oneLimit")}/>
                                    </label>
                                    <label className="txt_lab">
                                        <span className="label_txt">{t('month_limit')}({item.fuelUnit === "KG" ? 'AMD' :'L'})</span>
                                        <input type="number" min={0} pattern="[0-9]{10}" className="form-control" disabled={!item.checked } name={item.id}  value={item.monthLimit ? item.monthLimit : ''} onChange={e=>this.handleChangeMulty(e,"monthLimit")}/>
                                    </label>
                                    <label className='checkbox_lab'>
                                        <span className="label_txt">{t('company_limit')}</span>
                                        <input type="checkbox" min={0} pattern="[0-9]{10}" className="form-control" disabled={!item.checked} name={item.id}  checked={item.corpLimit} onChange={e=>this.handleChangeMulty(e,"corpLimit")}/>
                                    </label>
                                    {item.error && <div className="error_message">{item.error}</div>  }
                                </div>
                            )

                            :''}

                    </div>

                    <button type="button" className="btn btn-primary block  m-b toggle_button"
                            onClick={this.toggleTable}>

                        {t('choose_list')}
                        <AiOutlineArrowDown size={15}/>
                    </button>
                    <div className="all-station-select">
                        <label>
                            <input type="checkbox" onChange={e=>this.handleChangeAll(e)} checked={attachCard.selectAll}/>
                            <span>{t('all')}</span>
                        </label>

                        <span className="label_txt center_mod">{t('choose_gas_stations')}</span>
                    </div>
                    <div className="table-responsive fix-header toggle_table display_none">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th><input type="checkbox" onChange={e=>this.handleChangeMulty3(e)} disabled={attachCard.selectAll} /> {t('choose_gas_station')}</th>
                                <th>Station ID</th>
                                <th>{t('gas_station_chain')}</th>
                                <th>{t('gas_station')}</th>
                                <th>{t('address')}</th>
                                <th>{t('fuel_type')}</th>
                            </tr>
                            </thead>
                            <tbody>
                             {/*0: {stationId: 17, fuelType: Array(1), address: "Խորենացի 21", azsName: "Ֆլեշ1", azsNetworkName: "«Ֆլեշ» ՍՊԸ"}*/}
                            {attachCard && attachCard.azsList.length?
                                attachCard.azsList.map( item =>
                                <tr key={item.stationId}>
                                    <td>
                                        <input type="checkbox" name={item.stationId}  checked={item.checked} onChange={e=>this.handleChangeMulty2(e)} disabled={attachCard.selectAll}/>
                                    </td>
                                    <td>{item.stationId}</td>
                                    <td>{item.azsNetworkName}</td>
                                    <td>{item.azsName}</td>
                                    <td>{item.address}</td>
                                    <td>
                                        <ShowMoreText
                                            /* Default options */
                                            lines={1}
                                            more="..."
                                            less="Show less"
                                            className="content-css"
                                            anchorClass="my-anchor-css-class"
                                            expanded={false}
                                            width={120}
                                            truncatedEndingComponent={""}
                                        >
                                            {item.fuelType && item.fuelType.length ?
                                                item.fuelType.map((item1, index) => {
                                                        if (index !== (item.fuelType.length - 1)) {
                                                            return `${item1.name}, `
                                                        }
                                                        else return item1.name
                                                    }
                                                ) : ''
                                            }
                                        </ShowMoreText>
                                    </td>
                                </tr>
                            ):false}

                            </tbody>
                        </table>
                    </div>


                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('assign')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                    {message && <div className="global_error">{message.error_description}</div>}
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const data1 = state.get_cards_attach_lists.data1
    return {
        modals,
        alert,
        data1

    };
}

export default connect(mapStateToProps)(withTranslation()(AttachCard));
