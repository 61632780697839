import { userService } from '../services';
import { alertActions } from './';
import { modalActions, getInfo } from './';
import { history } from '../helpers';
export const userActions = {
    login,
    logout,
    AddUser,
    EditUser,
    DeleteUser,
    UnpinCard,
    changePassword,
    resetPassword,
    editAllFuelPrices,
    AddTable,
    EditTable,
    DeleteTable,
    AddRefueling,
    attach_operator_card,
    sendFile,
    attach_admin_card,
    post_operator_update_balance,
    add_transaction,
    transaction_exel,
    transaction_report,
    get_report_list,
    post_corporate_sell_price,
    return_all_card_balance
};
function login(user) {
    return dispatch => {
        dispatch(request());
        userService.login(user)
            .then(
                user => {
                    if(user.error){
                        dispatch(failure(user.error));
                        dispatch(alertActions.alert_error(user.error.error_description));
                    }
                    else{
                        dispatch(success(user.result));
                        history.push('/dashboard');
                    }

                },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                    dispatch(modalActions.open_modal('Fail_popup',"Bad request"));
                }
            );
    };

    function request() { return { type: 'LOGIN_REQUEST' } }
    function success(user) { return { type: 'LOGIN-SUCCESS', user } }
    function failure(error) { return { type: 'LOGIN-FAILURE', error } }
}
function changePassword(user, method) {
    return dispatch => {
        dispatch(request(user));
        userService.PostInfo(user, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        if(data.error.error_code === "10001"){
                            dispatch(alertActions.alert_error(data.error.error_description));
                        }
                        else{
                            dispatch(modalActions.open_modal('Fail_popup',data.error));
                        }
                    }
                    else{
                        dispatch(success1());
                        dispatch(alertActions.success('Add successful'));
                        dispatch(modalActions.open_modal('Success','Գաղտնաբառը փոխվեց'));
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }

    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function resetPassword(user, method) {
    return dispatch => {
        dispatch(request(user));
        userService.PostInfo(user, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        if(data.error.error_code === "10001"){
                            dispatch(alertActions.alert_error(data.error.error_description));
                        }
                        else{
                            dispatch(modalActions.open_modal('Fail_popup', data.error.error_description));
                        }
                    }
                    else{
                        dispatch(success1());
                        dispatch(alertActions.success('Add successful'));
                        dispatch(modalActions.open_modal('Success', data.result.Message));
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }

    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
// function login(username, password) {
//     return dispatch => {
//         dispatch(request());
//         // userService.login(username, password)
//         //     .then(
//         //         user => {
//                     dispatch(success());
//                     history.push('/dashboard');
//                     localStorage.setItem('user_token', JSON.stringify("asdadssad"));
//                     localStorage.setItem('LoginStatus', true);
//                     localStorage.setItem('userRole', JSON.stringify('operator1'));
//             //     },
//             //     error => {
//             //         dispatch(failure(error));
//             //         dispatch(alertActions.error(error));
//             //     }
//             // );
//     };
//
//     function request() { return { type: 'LOGIN_REQUEST' } }
//     function success() { return { type: 'LOGIN-SUCCESS' } }
//     function failure(error) { return { type: 'LOGIN-FAILURE', error } }
// }
function logout() {
    return dispatch => {
        localStorage.removeItem('user_token');
        localStorage.setItem('LoginStatus', false);
        history.push('/');
        dispatch(alertActions.clear())
        window.location.reload();
        return { type: 'LOGOUT' };

    }

}
function EditUser(user) {
    return dispatch => {
        dispatch(request(user));
        userService.PostInfo(user, 'edit_driver')
            .then(
                user => {
                    if(user.error){
                        dispatch(failure(user.error));
                        if(user.error.error_code === "10001"){
                            dispatch(alertActions.alert_error(user.error.error_description));
                        }
                        else{
                            dispatch(modalActions.open_modal('Fail_popup',user.error));
                        }
                    }
                    else{
                        dispatch(success(user));
                        dispatch(success1());
                        dispatch(alertActions.success('Edit successful'));
                        dispatch(modalActions.close_modal());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(user) { return { type: 'SUCCESS_EDIT_USER', user } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function AddUser(user) {
    return dispatch => {
        dispatch(request(user));
        userService.PostInfo(user, 'add_driver')
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        if(data.error.error_code === "10001"){
                            dispatch(alertActions.alert_error(data.error.error_description));
                        }
                        else{
                            dispatch(modalActions.open_modal('Fail_popup',data.error));
                        }
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                        dispatch(alertActions.success('Add successful'));
                        dispatch(modalActions.close_modal());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'SUCCESS_ADD_USER', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function DeleteUser(user) {
    return dispatch => {
        dispatch(request(user));
        userService.PostInfo(user, 'delete_driver')
            .then(
                user => {
                    if(user.error){
                        dispatch(failure(user.error));
                        dispatch(alertActions.alert_error(user.error));
                        dispatch(modalActions.open_modal('Fail_popup',user.error));
                    }
                    else{
                        dispatch(success(user));
                        dispatch(success1());
                        dispatch(alertActions.success('Delete successful'));
                        dispatch(modalActions.close_modal());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(user) { return { type: 'SUCCESS_DELETE_USER', user } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function UnpinCard(id) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(id)
            .then(
                group => {
                    // dispatch(success(group));
                    dispatch(success1());
                    dispatch(alertActions.success('Delete successful'));
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(failure());
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                    dispatch(alertActions.alert_error(error));
                    dispatch(alertActions.clear())
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    // function success(group) { return { type: 'SUCCESS_DELETE_GROUP', group } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function AddTable(user, method) {
    return dispatch => {
        dispatch(request(user));
        userService.PostInfo(user, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        if(data.error.error_code === "10001"){
                            dispatch(alertActions.alert_error(data.error.error_description));
                        }
                       else{
                            dispatch(modalActions.open_modal('Fail_popup',data.error));
                        }
                    }
                    else{

                        dispatch(success(data));
                        dispatch(success1());
                        dispatch(alertActions.success('Add successful'));
                        dispatch(modalActions.close_modal());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'ADD_TABLE', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function AddRefueling(user, method) {
    return dispatch => {
        dispatch(request(user));
        userService.PostInfo(user, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        if(data.error.error_code === "10001"){
                            dispatch(alertActions.alert_error(data.error.error_description));
                        }
                        else{
                            dispatch(modalActions.open_modal('Fail_popup',data.error));
                        }
                    }
                    else{
                        dispatch(success1());
                        dispatch(alertActions.success('Add successful'));
                        dispatch(modalActions.open_modal('Success','Լցակայանը ավելացվել է'));
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }

    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function EditTable(user, method) {
    return dispatch => {
        dispatch(request(user));
        userService.PostInfo(user, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        if(data.error.error_code === "10001"){
                            dispatch(alertActions.alert_error(data.error.error_description));
                        }
                        else{
                            dispatch(modalActions.open_modal('Fail_popup',data.error));
                        }
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                        dispatch(alertActions.success('Edit successful'));
                        dispatch(modalActions.close_modal());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'EDIT_TABLE', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function DeleteTable(user, method) {
    return dispatch => {
        dispatch(request(user));
        userService.PostInfo(user, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        // dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                        dispatch(alertActions.success('Edit successful'));
                        dispatch(modalActions.close_modal());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'DELETE_TABLE', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function attach_operator_card(user, method) {
    return dispatch => {
        dispatch(request(user));
        userService.PostInfo(user, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                        dispatch(alertActions.success('Edit successful'));
                        dispatch(modalActions.close_modal());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'DELETE_TABLE', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function sendFile(file, method) {
    return dispatch => {
        dispatch(request());
        return userService.PostInfo(file, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success());
                        dispatch(modalActions.open_modal('Success',data.result.message));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function attach_admin_card(data1, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data1.info, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        // dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                        dispatch(modalActions.close_modal());
                        if(data1.cartType === "card1"){
                            dispatch(getInfo.getTable({
                                searchValue: '',
                                sortValue:'',
                                sortPosition: '',
                                activePage: 1,
                                pageSize :10
                            }, "get_admin_attached_cards"))
                        }
                        else{
                                dispatch(getInfo.getTable({
                                    searchValue: '',
                                    sortValue:'',
                                    sortPosition: '',
                                    activePage: 1,
                                    pageSize :10
                                }, "get_admin_unattached_cards"))

                        }
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    // dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(data) { return { type: 'DELETE_TABLE', data } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function post_operator_update_balance(data) {
    return dispatch => {
        dispatch(request(data));
        userService.PostInfo(data, 'edit_driver')
            .then(
                user => {
                    if(user.error){
                        dispatch(failure(user.error));
                        dispatch(alertActions.alert_error(user.error));
                        dispatch(modalActions.open_modal('Fail_popup',user.error));
                    }
                    else{
                        dispatch(success());
                        dispatch(modalActions.open_modal('Success',"Քարտը հաջող լիցքավորվեց"));
                        dispatch(alertActions.success('Edit successful'));
                        dispatch(modalActions.close_modal());
                        dispatch(getInfo.getTable({
                            searchValue: '',
                            sortValue: '',
                            sortPosition: '',
                            activePage: 1,
                            pageSize: 10
                        }, "get_operator1_attached_cards"))
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function add_transaction(user, method) {
    return dispatch => {
        dispatch(request());
        return new Promise((resolve, reject) => {
            userService.PostInfo(user, method)
                .then(
                    data => {
                        if (data.error) {
                            dispatch(failure(data.error));

                            if (data.error.error_code === "10001") {
                                dispatch(alertActions.alert_error(data.error.error_description));
                            } else {
                                dispatch(modalActions.open_modal('Fail_popup', data.error));
                            }

                            reject(data.error);
                        } else {
                            dispatch(success1());

                            dispatch(alertActions.success('Add successful'));
                            dispatch(modalActions.open_modal('Success', data.result.Message));

                            resolve(data.result);
                        }
                    },
                    error => {
                        dispatch(failure(error));
                        dispatch(alertActions.alert_error(error));

                        reject(error);
                    }
                );
        });
    };

    function request() { return { type: 'REQUEST' }; }
    function success1() { return { type: 'SUCCESS' }; }
    function failure(error) { return { type: 'FAILURE', error }; }
}

export default add_transaction;

function transaction_exel(user, method) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(user));
            userService.PostInfo(user, method)
                .then(
                    data => {
                        if (data.error) {
                            dispatch(failure(data.error));
                            if (data.error.error_code === "10001") {
                                dispatch(alertActions.alert_error(data.error.error_description));
                            }
                            else {
                                dispatch(modalActions.open_modal('Fail_popup', data.error));
                            }
                        }
                        else {
                            dispatch(success1());
                            resolve(dispatch(success(data)));
                            dispatch(alertActions.success('successful'));
                        }

                    },
                    error => {
                        dispatch(failure(error));
                        dispatch(alertActions.alert_error(error));
                    }
                );
        })
    };

    function request() { return { type: 'REQUEST' } }
    function success1() { return { type: 'SUCCESS' } }
    function success(data2) { return { type: 'GET_TRANSACTION_ALL', data2 } }
    function failure() { return { type: 'FAILURE' } }
}
function transaction_report(user, method) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(request(user));
            userService.PostInfo(user, method)
                .then(
                    data => {
                        if (data.error) {
                            dispatch(failure(data.error));
                            if (data.error.error_code === "10001") {
                                dispatch(alertActions.alert_error(data.error.error_description));
                            }
                            else {
                                dispatch(modalActions.open_modal('Fail_popup', data.error));
                            }
                        }
                        else {
                            dispatch(success1());
                            resolve(dispatch(success(data)));
                            dispatch(alertActions.success('successful'));
                        }

                    },
                    error => {
                        dispatch(failure(error));
                        dispatch(alertActions.alert_error(error));
                    }
                );
        })
    };

    function request() { return { type: 'REQUEST' } }
    function success1() { return { type: 'SUCCESS' } }
    function success(data3) { return { type: 'GET_REPORT', data3 } }
    function failure() { return { type: 'FAILURE' } }
}
function  get_report_list(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else{
                        dispatch(success(data));
                        dispatch(success1());
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success(data4) { return { type: 'GET_REPORT_LIST', data4 } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function  post_corporate_sell_price(data, method) {
    return dispatch => {
        dispatch(request());
        userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                    }
                    else {
                        dispatch(getInfo.get_corporate_sell_price({}, "get_corporate_sell_price"))
                        dispatch(modalActions.close_modal());
                        dispatch(success(data));
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };
    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function  return_all_card_balance(data, method) {
    return dispatch => {
        return new Promise((resolve, reject) => {
        dispatch(request());
        return userService.PostInfo(data, method)
            .then(
                data => {
                    if(data.error){
                        dispatch(failure(data.error));
                        dispatch(alertActions.alert_error(data.error));
                        dispatch(modalActions.open_modal('Fail_popup',data.error));
                    }
                    else {
                        dispatch(modalActions.close_modal());
                        resolve(dispatch(success(data)));
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
        })
    };

    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function editAllFuelPrices(data, method) {
    return dispatch => {
        dispatch(request());
        return new Promise((resolve, reject) => {
            userService.PostInfo(data, method)
                .then(
                    data => {
                        if (data.error) {
                            dispatch(failure(data.error));

                            if (data.error.error_code === "10001") {
                                dispatch(alertActions.alert_error(data.error.error_description));
                            } else {
                                dispatch(modalActions.open_modal('Fail_popup', data.error));
                            }

                            reject(data.error);
                        } else {
                            dispatch(success1());
                            dispatch(modalActions.close_modal());
                            resolve(data.result);
                        }
                    },
                    error => {
                        dispatch(failure(error));
                        dispatch(alertActions.alert_error(error));
                        reject(error);
                    }
                );
        });
    };

    function request() { return { type: 'REQUEST' }; }
    function success1() { return { type: 'SUCCESS' }; }
    function failure(error) { return { type: 'FAILURE', error }; }
}
