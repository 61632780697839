import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, alertActions, modalActions} from '../../actions';
import { sha256} from 'js-sha256';
import Logo from '../../img/logo1.jpg'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user:{
                username: '',
                password: ''
            }

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(alertActions.clear())
    }
    handleChange(e) {
        const {name, value} = e.target;
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });

    }
    handleSubmit(event) {
        event.preventDefault();
        const {user} = this.state;
        const { dispatch } = this.props;
        let password=sha256(user.password).toUpperCase()
        let data={
            username:user.username,
            password: password
        }
        dispatch(userActions.login(data));

    }
    onBtnClick(event, data) {
        const {dispatch} = this.props;
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }

    render() {
        const { user } = this.state;
        return (
            <div className="gray-bg">
                <div className="middle-box text-center loginscreen animated fadeInDown">
                    <div>
                        <div>
                            <h1 className="logo-name"><img src={Logo} width={250} alt=""/></h1>
                        </div>

                        <form className="form_login" onSubmit={this.handleSubmit}>
                            {this.props.alert && !this.props.alert.error_description &&   <div className="error-mess">{this.props.alert}</div>}

                            <div className="form-group">
                                <input type="username" name="username"  className="form-control" placeholder="Username" required="" value={user.username} onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <input type="password" name="password" className="form-control" placeholder="Password" required="" value={user.password} onChange={this.handleChange}/>
                            </div>

                            <button type="submit" className="btn btn-primary block full-width m-b">Login</button>
                            <div className="forgot_pass"
                                 onClick={(e) => {
                                     this.onBtnClick(e)
                                 }} data_modal="ResetPassword">Forgot Password?</div>

                        </form>
                    </div>
                </div>


            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        alert: state.alert.message
    };
}

const connectedLogin = connect(mapStateToProps)(Login);
export { connectedLogin as Login };