import React, { Component } from 'react';
import { connect } from 'react-redux';
import {modalActions, userActions} from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import { withTranslation } from 'react-i18next';

class EditRefuelingNetworkPrices extends Component{
    constructor(props){
        super(props)
        const EditContent = this.props.modals.data;
        const userRole=JSON.parse( localStorage.getItem('userRole'))
        this.state = {
            fuelType: [
                    ],
            id: EditContent.id,
            selectedOptions: [],
            defaultCheck: [],

        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {

        const { dispatch } = this.props;
        const EditContent = this.props.modals.data;
        dispatch(getInfo.get_refuelings_fuelTypes(EditContent, "get_refuelings_fuelTypes"))
    }
    UNSAFE_componentWillReceiveProps(props){
        console.log(234234324, props.refuelingList)
        if(props.refuelingList && props.refuelingList !== this.props.refuelingList){

            this.setState({
                fuelType: props.refuelingList
            });
        }
    }
    handleChangeMulty =(e, nameInp) => {
        const target = e.target;
        const name = target.name;
        const { fuelType } = this.state;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let newFuelType = fuelType.map((item)=>{
            if(item.id == name){
                return  {...item, [nameInp]: value}
            }
            else return item
        })
        this.setState({
            fuelType: newFuelType
        });

    }

    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    async handleSubmit(event) {
        event.preventDefault();
        const { fuelType } = this.state;
        const { dispatch } = this.props;
        const editContent = this.props.modals.data;

        const newFuelType = fuelType.filter(item=> item.price)
        try{
            await dispatch(userActions.editAllFuelPrices({fuelType: newFuelType, id: this.state.id},'edit_stations_refuelings_prices'));
            editContent.handlePageChange(1)
        } catch (err){
            console.log(err)
        }
    }
    render() {
        const { fuelType } = this.state;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('edit_all_gas_price_station')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    <div className="multy_check">
                        {(fuelType && fuelType.length) ? fuelType.map(
                            (item)=>
                                            <div className="flex-inp-price mod1" key={item.id}>
                                                <label className='checkbox_lab fix-width-300' >
                                                    <span className="label_txt">{item.name}</span>
                                                </label>
                                                <label className="txt_lab">
                                                    <span className="label_txt">{t('price')}</span>
                                                    <input type="number" min={1} pattern="[0-9]{10}" className="form-control" name={item.id}  value={item.price} onChange={e=>this.handleChangeMulty(e,"price")}/>
                                                </label>
                                            </div>


                        ):''}

                    </div>
                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('save')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const refuelingList = state.getRefuelingFuelTypes.data1
    return {
        modals,
        alert,
        refuelingList
    };
}

export default connect(mapStateToProps)(withTranslation()(EditRefuelingNetworkPrices));
