import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { withTranslation } from 'react-i18next';

class OperatorsAveragePrice extends Component {

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_corporate_discounts({},  "get_corporate_discounts"))
    }

    onBtnClick =(event, data)=>{
        const { dispatch } = this.props;
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }

    render() {
        const {prices} = this.props;
		const { t } = this.props;
        return (
            <div className="main_container">
                <div className="balance">
                    <div className="d-flex">
                        <span>{t('fuel_prices')}</span>
                    </div>
                </div>
                <div className="row">
                        <div className="cont2 mod_dashb">
                            <div className="widget color2">
                                <div className="table-responsive">
                                      <table className="table table-striped">
                                          <thead>
                                            <tr>
                                                <th>{t('fuel_type')}</th>
                                                <th>{t('unit')}</th>
                                                <th>{t('fuel_price')}</th>
                                                <th>{t('sale_price')}</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          {prices && prices.map((item, index)=>
                                              <tr key={index}>
                                                  <td><div className='table_head_txt'>{t('fuel')}:</div>{item.name}</td>
                                                  <td><div className='table_head_txt'>{t('volume')}:</div>{item.unit}</td>
                                                  <td><div className='table_head_txt'>{t('unit')}:</div>{item.price}</td>
                                                  <td><div className='table_head_txt'>{t('unit_price')}:</div>{item.discount}</td>
                                              </tr>
                                          )}

                                          </tbody>
                                      </table>
                                </div>
                            </div>
                        </div>

                    </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {prices} = state.get_corporate_discounts
    return {
        prices
    };
}

const connectedOperatorsAveragePrice = connect(mapStateToProps)(withTranslation()(OperatorsAveragePrice));
export { connectedOperatorsAveragePrice as OperatorsAveragePrice };