import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link} from "react-router-dom";
import logo from '../img/logo.png';
import petrol from '../img/gas-station.svg';
import terminal from '../img/terminal.svg';
import transactions from '../img/transaction.svg';
import profile from '../img/profile_blue.svg';
import $ from "jquery";
import { userActions} from '../actions';
import {modalActions} from '../actions/index';
import { history } from '../helpers';
import { withTranslation, useTranslation } from 'react-i18next';
import {getInfo} from '../actions/index';
import { MdPriceCheck } from "react-icons/md";
import { FaPercent } from "react-icons/fa";
import { BiMailSend, BiCoinStack } from "react-icons/bi";

function Lang() {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(localStorage.getItem("lang") ? localStorage.getItem("lang") : 'am');
    useEffect(() => {
        localStorage.setItem("lang", lang)
        i18n.changeLanguage(lang);

    }, [lang]);
    const toggle = lng => setLang(lng)
        return (
            <div className="language_container">
                <button className={lang === "am" ? "active" : ""} onClick={() => toggle('am')}>am</button>
                <button className={lang === "en" ? "active" : ""} onClick={() => toggle('en')}>en</button>
                <button className={lang === "ru" ? "active" : ""} onClick={() => toggle('ru')}>ru</button>
            </div>
        );
}


class DashboardFix extends Component {

    constructor(props) {
        super(props);
        this.NavLinkActive = this.NavLinkActive.bind(this);
        history.listen((location, action) => {
            this.NavLinkActive()
        });
        this.LogOut = this.LogOut.bind(this)
        this.onBtnClick = this.onBtnClick.bind(this)
    }
    componentDidMount() {

        this.NavLinkActive()
        $('.nav_button').click(function(){
            if(!$(this).hasClass('act_but')){
                $(this).addClass('act_but');
                $('body').addClass('active_menu');
            }
            else{
                $(this).removeClass('act_but');
                $('body').removeClass('active_menu');
            }

        })

        if($( window ).width() <= 768){

            $('.navlink').click(function(){
                $('.nav_button').removeClass('act_but');
                $('body').removeClass('active_menu');

            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.get_user_info({}, 'get_user_data'))

    }
    NavLinkActive (){
        $('.navlink').removeClass('active');
        $('body').find('.navlink').each(function () {
            if($(this).attr('href') === history.location.pathname){
                $(this).addClass('active')
            }
        })
    }
    LogOut(){
        const { dispatch } = this.props;
        return dispatch(userActions.logout());
    }

    onBtnClick(event, data) {
        const {dispatch} = this.props;
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    render() {
        const userRole=JSON.parse( localStorage.getItem('userRole'))
        const {data1}=this.props;
        const { t } = this.props;

        return (
            <header id="header">

                <div className="container1">

                    <div className="left">


                        <div className="nav_cont">
                            <img src={logo} className="logo" alt="logo"/>
                            <nav>

                                {
                                    (userRole === 'operator1' || userRole === 'operator1Viewer')  &&
                                    <ul>

                                        <li>
                                            <Link to="/dashboard" className="navlink" > <i className="fa fa-th-large"></i>{t('account')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/users" className="navlink"><i className="fa fa-users"></i>{t('users')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/cards" className="navlink"><i className="fa fa-credit-card"></i>{t('cards')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/refueling" className="navlink"><img src={petrol} width={18} alt=""/>{t('gas_stations')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/transactions" className="navlink"><img src={transactions} width={18}
                                                                                              alt=""/>{t('transactions')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/average-price" className="navlink" > <BiCoinStack size={18}/> {t('fuel_prices')}</Link>
                                        </li>
                                        {data1 && !data1.has_branch_operator_or_viewer?
                                            <li>
                                                <Link to="/applications" className="navlink" > <BiMailSend size={18}/> {t('applications')}</Link>
                                            </li>

                                            :''}

                                    </ul>
                                }
                                {(userRole === 'admin1' || userRole === 'admin2' || userRole === 'admin2Viewer' || userRole === 'superAdmin') &&
                                <ul>

                                    <li>
                                        <Link to="/dashboard" className="navlink"> <i className="fa fa-th-large"></i>{t('corporate_clients')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/refueling" className="navlink"><img src={petrol} width={18} alt=""/>{t('gas_stations')}</Link>
                                    </li>
                                    {userRole !== 'admin2' && userRole !== 'admin2Viewer' &&
                                        <>
                                            <li>
                                                <Link to="/terminals" className="navlink"><img src={terminal} width={18}
                                                                                               alt=""/>{t('terminals')}</Link>
                                            </li>
                                            <li>
                                            <Link to="/users" className="navlink"><i className="fa fa-users"></i>{t('users')}</Link>
                                            </li>

                                            <li>
                                            <Link to="/cards" className="navlink"><i className="fa fa-credit-card"></i>{t('cards')}</Link>
                                            </li>
                                        </>
                                    }
                                    {/*<li>*/}
                                        {/*<Link to="/average-price" className="navlink"><img src={coin} width={18}*/}
                                                                                           {/*alt=""/>վառելիքի*/}
                                            {/*գներ</Link>*/}
                                    {/*</li>*/}
                                    <li>
                                        <Link to="/transactions" className="navlink"><img src={transactions} width={18}
                                                                                          alt=""/> {t('transactions')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/fuel-prices" className="navlink"><MdPriceCheck size={18}/> {t('fuel_prices')}</Link>
                                    </li>
                                    {(userRole === 'admin2' || userRole === 'admin2Viewer') &&
                                        <>
                                            <li>
                                                <Link to="/discount-managements" className="navlink"><FaPercent size={18}/>{t('discount_managements')}</Link>
                                            </li>
                                            <li>
                                                <Link to="/applications" className="navlink" > <BiMailSend size={18}/> {t('applications')}</Link>
                                            </li>
                                        </>
                                    }
                                </ul>
                                }
                                {(userRole === 'operator2'  || userRole === 'operator2Viewer') &&
                                <ul>

                                    <li>
                                        <Link to="/dashboard" className="navlink"> <i className="fa fa-th-large"></i>{t('account')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/branches" className="navlink"> <i className="fa fa-th-large"></i>{t('branches')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/users" className="navlink"><i className="fa fa-users"></i>{t('users')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/cards" className="navlink"><i className="fa fa-credit-card"></i>{t('cards')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/refueling" className="navlink"><img src={petrol} width={18} alt=""/>{t('gas_stations')}</Link>
                                    </li>

                                    <li>
                                        <Link to="/transactions" className="navlink"><img src={transactions} width={18}
                                                                                          alt=""/> {t('transactions')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/average-price" className="navlink" > <BiCoinStack size={18}/> {t('fuel_prices')}</Link>
                                    </li>
                                    <li>
                                        <Link to="/applications" className="navlink" > <BiMailSend size={18}/> {t('applications')}</Link>
                                    </li>
                                </ul>
                                }
                                {(userRole === 'cardHolder' ) &&
                                    <div>
                                        <ul>

                                            <li>
                                                <Link to="/dashboard" className="navlink"> <i className="fa fa-th-large"></i>{t('account')}</Link>
                                            </li>
                                            {/*<li>*/}
                                                {/*<Link to="/refueling" className="navlink"><img src={petrol} width={18} alt=""/>{t('gas_stations')}</Link>*/}
                                            {/*</li>*/}
                                            <li>
                                                <Link to="/refuelingnetwork" className="navlink"><img src={petrol} width={18} alt=""/>{t('gas_stations')}</Link>
                                            </li>
                                            <li>
                                                <Link to="/transactions" className="navlink"><img src={transactions} width={18}
                                                                                                  alt=""/> {t('transactions')}</Link>
                                            </li>
                                        </ul>
                                        <div className="info_card">
                                            <i className="fa fa-credit-card"></i>
                                            <div>{t('card_number')}։ {data1 && data1.cardNumber ?data1 && data1.cardNumber:"{t('no_card')}"}</div>
                                            <div>{t('end_date')}։ {data1 && data1.endDate}</div>
                                        </div>

                                    </div>

                                }
                                {(userRole === 'fuelSupervisor' || userRole === 'fuelSupervisorViewer' ) &&
                                <div>
                                    <ul>
                                        <li>
                                            <Link to="/dashboard" className="navlink"><img src={transactions} width={18}
                                                                                              alt=""/> {t('transactions')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/refuelingnetwork" className="navlink"><img src={petrol} width={18} alt=""/>{t('fuel_prices')}</Link>
                                        </li>
                                    </ul>

                                </div>

                                }
                            </nav>
                        </div>
                        <div className="nav_button">
                            <div className="icon-bar bar1"></div>
                            <div className="icon-bar bar2"></div>
                            <div className="icon-bar bar3"></div>
                        </div>
                    </div>
                    <div></div>
                    <div className="right">
                        <Lang />
                        <Link to={'/'} className="user_name">
                            <img src={profile} width={24} alt=""/>
                            <span>{data1 && data1.first_name?data1.first_name:''} {data1 && data1.last_name?data1.last_name:''}</span>
                        </Link>
                        <div className="user_name"  onClick={(e) => {
                            this.onBtnClick(e)
                        }} data_modal="ChangePassword">{t('change_password')}</div>

                        <div className="logout" onClick={this.LogOut}><i className="fa fa-sign-out"></i>{t('sign_out')}</div>
                    </div>
                </div>

            </header>
        )
    }
}
function mapStateToProps(state) {
    const {data1} = state.GetUserInfo
    return {
        data1
    };
}


export default connect(mapStateToProps)( withTranslation()(DashboardFix));
