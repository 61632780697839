/**
 * Created by user on 21.11.2018.
 */
export function UsersTable(state = {}, action) {
    switch (action.type) {
        case 'GET_USER_TABLE':
            return {
                data: action.data.result.data,
                Count: action.data.result.count
            };
        case 'SUCCESS_ADD_USER':
            return{
                ...state,
                data: [action.data.result.user ,...state.data ]

            }
        case 'SUCCESS_EDIT_USER':
            return{
                ...state,
                data: state.data.map(user=>
                user.id === action.user.result.user.id
                ? action.user.result.user
                : user
                )

            }
        case 'SUCCESS_DELETE_USER':
            console.log(action)
            return{
                ...state,
                data: state.data.filter((user)=> user.id !== action.user.result.id)

            }

        default:
            return state
    }
}

// export function Operator1Card1(state = {}, action) {
//     switch (action.type) {
//         case 'GET_OPERATOR1CARD1_TABLE':
//             return {
//                 data: action.data.result.users,
//                 Count: action.data.result.count
//             };
//         case 'SUCCESS_ADD_USER':
//             return{
//                 ...state,
//                 data: [action.user.result.user ,...state.data ]
//
//             }
//         case 'SUCCESS_EDIT_USER':
//             return{
//                 ...state,
//                 data: state.UsersTable.map(user=>
//                     user.id === action.user.result.user.id
//                         ? action.user.result.user
//                         : user
//                 )
//
//             }
//         case 'SUCCESS_DELETE_USER':
//             console.log(action)
//             return{
//                 ...state,
//                 UsersTable: state.UsersTable.filter((user)=> user.id !== action.user.result.id)
//
//             }
//         default:
//             return state
//     }
// }

export function getTable(state = {}, action) {
    switch (action.type) {
        case 'GET_TABLE':
            return {
                data: action.data.result.data,
                Count: action.data.result.count
            };
        case 'ADD_TABLE':
            return{
                ...state,
                data: [Array.isArray(action.data.result.data) ? action.data.result.data[0] : action.data.result.data ,...state.data ]
                // data: [action.data.result.data ,...state.data ]

            }
        case 'EDIT_TABLE':
            return{
                ...state,
                data: state.data.map(user=>
                    user.id === action.data.result.data.id
                        ? action.data.result.data
                        : user
                )

            }
        case 'DELETE_TABLE':
            return{
                ...state,
                data: state.data.filter((user)=> user.id != action.data.result.id)

            }
        case 'CLEAR_TABLE':
            return{
                data: [],
                Count: ""

            }
        case 'GET_CORPORATE_USERS_FUELTYPES_BALANCE':
            return {
                ...state,
                data: state.data.map(user=>
                    user.id === action.corpFuelTypeBalance.corporateUserId
                        ? {...user, balance: action.corpFuelTypeBalance.data.result.data}
                        : user
                )
                // corpFuelTypeBalance: action.corpFuelTypeBalance.result.data,
            }
        default:
            return state
    }
}
export function GraphData(state = {GraphData:{}}, action) {
    switch (action.type) {
        case 'VIEW_GRAPH':
            Object.keys(action.getData).map(function(key, index) {
                return state.GraphData[key]=action.getData[key]

            })
            return{
                // GraphData: Object.keys(action.getData).map(function(key, index) {
                //     state.GraphData[key]=action.getData[key]
                //
                // })
                // GraphData:action.getData,
                GraphData:state.GraphData
            }
        default:
            return state
    }
}

export function AveragePrices(state = {}, action) {
    switch (action.type) {
        case 'GET_AVERAGE_PRICES':
            return {
                data: action.data.result,
            }
        default:
            return state
    }
}
export function GetBalance(state = {}, action) {
    switch (action.type) {
        case 'GET_BALANCE':
            return {
                data1: action.data1.result,
            }
        default:
            return state
    }
}
export function GetUserInfo(state = {}, action) {
    switch (action.type) {
        case 'GET_USER_INFO':
            return {
                data1: action.data1.result,
            }
        default:
            return state
    }
}
export function GetAzsNetworkList(state = {}, action) {
    switch (action.type) {
        case 'GET_AZS_NETWORK_LIST':
            return {
                data1: action.data1.result.data,
                data2: action.data1.result.fuelTypesGroupSellers,
            }
        default:
            return state
    }
}
export function getRefuelingFuelTypes(state = {}, action) {
    switch (action.type) {
        case 'GET_REFUELINGS_FUELTYPES':
            return {
                data1: action.data1.result.fuelTypes,
            }
        default:
            return state
    }
}
export function get_status_company_list(state = {}, action) {
    switch (action.type) {
        case 'GET_STATUS_COMPANY_LIST':
            return {
                data1: action.data1.result,
            }
        default:
            return state
    }
}
export function get_cards_attach_lists(state = {}, action) {
    switch (action.type) {
        case 'GET_CARDS_ATTACH_LISTS':
            return {
                data1: action.data1.result,
            }
        default:
            return state
    }
}
export function get_admin_transactions_balance(state = {}, action) {
    switch (action.type) {
        case 'GET_ADMIN_TRANSACTIONS_BALANCE':
            return {
                data1: action.data1.result,
            }
        default:
            return state
    }
}
export function get_buyer_corporate_fuel_types(state = {}, action) {
    switch (action.type) {
        case 'GET_BUYER_CORPORATE_FUEL_TYPES':
            return {
                data4: action.data4.result,
            }
        default:
            return state
    }
}
export function get_admin_transactions_header(state = {}, action) {
    switch (action.type) {
        case 'GET_ADMIN_TRANSACTIONS_HEADER':
            return {
                data1: action.data1.result.transactionType,
            }
        default:
            return state
    }
}
export function get_admin_attach_card(state = {}, action) {
    switch (action.type) {
        case 'GET_ADMIN_ATTACH_CARD':
            return {
                data1: action.data1.result,
            }
        default:
            return state
    }
}
export function get_operator_update_balance(state = {}, action) {
    switch (action.type) {
        case 'GET_OPERATOR_UPDATE_BALANCE':
            return {
                data1: action.data1.result,
            }
        default:
            return state
    }
}
export function get_transaction_all(state = {}, action) {
    switch (action.type) {
        case 'GET_TRANSACTION_ALL':
            return {
                data2: action.data2.result.data,
            }
        default:
            return state
    }
}
export function get_report(state = {}, action) {
    switch (action.type) {
        case 'GET_REPORT':
            return {
                data3: action.data3.result,
            }
        default:
            return state
    }
}
export function get_report_list(state = {}, action) {
    switch (action.type) {
        case 'GET_REPORT_LIST':
            return {
                data4: action.data4.result.data,
            }
        default:
            return state
    }
}export function get_status_company_list_edit(state = {}, action) {
    switch (action.type) {
        case 'GET_STATUS_COMPANY_LIST_EDIT':
            return {
                data5: action.data5.result.azsList,
            }
        default:
            return state
    }
}
export function get_fuelTypes_list(state = {}, action) {
    switch (action.type) {
        case 'GET_FUEL_TYPES_LIST':
            return {
                data5: action.data5.result,
            }
        default:
            return state
    }
}
export function get_corporate_sell_price(state = {}, action) {
    switch (action.type) {
        case 'GET_CORPORATE_SELL_PRICE':
            return {
                corpUserPrices: action.corpUserPrices.result,
            }
        default:
            return state
    }
}

export function get_corporate_discounts(state = {}, action) {
    switch (action.type) {
        case 'GET_OPERATORS_AVERAGE_BALANCE':
            return {
                prices: action.data1.result,
            }
        default:
            return state
    }
}
export function get_corporate_discount_managements(state = {}, action) {
    switch (action.type) {
        case 'GET_CORPORATE_DISCOUNT_MANAGEMENTS':
            return {
                prices: action.data1.result,
            }
        default:
            return state
    }
}
export function get_corporate_suppliers(state = {}, action) {
    switch (action.type) {
        case 'GET_CORPORATE_SUPPLIERS':
            return {
                suppliers: action.suppliers.result,
            }
        default:
            return state
    }
}
export function get_fuelTypes_by_corpId(state = {}, action) {
    switch (action.type) {
        case 'GET_FUELTYPES_BY_CORPID':
            return {
                fuelTypes: action.fuelTypes.result.data.fuelTypes,
            }
        default:
            return state
    }
}
