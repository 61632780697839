import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  modalActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { Table } from 'reactstrap';
import { Link} from "react-router-dom";
import {userService} from "../../../services";
import { withTranslation } from 'react-i18next';

class RefuelingOperator1Details extends Component {

    constructor(props) {
        super(props);
        this.state={
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                pageSize :10,
                id:this.props.match.params.id
            },
            removeSearch: false,
            pageRangeDisplayed: 5

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(event) {
        $('table tr').removeClass('edited')
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        });
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_operator_refueling"))

        });

    }
    SortClass(event){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_operator_refueling"))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_operator_refueling"))
    }

    onBtnClick(event, data){
        const { dispatch } = this.props;
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    onBtnClick1(event, data){
        const { dispatch } = this.props;
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(getInfo.clear_table())
    }
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_operator_refueling"))
        });
    }
    handleSubmit =(event)=>{
        event.preventDefault();
        const {dispatch}= this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_operator_refueling"))
        const { tableData } = this.state;
        if(tableData.searchValue != ''){
            this.setState({
                removeSearch: true
            })
        }
    }
    removeSearch =()=>{
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_operator_refueling"))
        });
    }
    downloadExcel= (method, name, data) =>{
        userService.downloadExcel(method, data).then(response => {
            if(response.ok){
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${name}.xlsx`;
                    a.click();
                })
            }
            else{
                this.props.dispatch(modalActions.open_modal('Fail_popup','Bad request'));
            }

        });
    }
    render() {
        const userRole=JSON.parse( localStorage.getItem('userRole'));
        const {data, Count} =this.props;
        const {tableData} =this.state;
		const { t } = this.props;
        return (
            <div className="main_container">
                <div className="top_container_search">
                    <form onSubmit={this.handleSubmit} className="form_search m_form">
                        <label className="search">
                            <span className="label_txt">{t('search')}</span>
                            <input type="text" value={tableData.searchValue} onChange={this.handleChange} placeholder="" className="form-control"/>
                            {this.state.removeSearch && <span className="remove_search" onClick={this.removeSearch}>X</span> }
                        </label>
                        <button  className="search1">{t('search_now')}</button>
                        <div className="add_but exel_but_trans" onClick={()=>this.downloadExcel('get_stations_excel', t('gas_stations'), this.state.tableData)}>{t('export')}</div>
                    </form>
                    <div className="but_cont_search">
                        <Link to={'/refueling'} className="add_but" >{"< "}{t('go_back')}</Link>
                    </div>
                </div>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="id">Id</option>
                    <option value="azsName">{t('by_gas_station_name')}</option>
                    <option value="azsNameEnglish">{t('by_gas_station_name_english')}</option>
                    <option value="address">{t('by_address')}</option>
                    <option value="inn">{t('by_taxcode')}</option>
                    <option value="phone">{t('by_phone')}</option>
                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="id">Id</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="azsName">{t('gas_station_name')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="address">{t('address')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="inn">{t('tax_code')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="phone">{t('phone')}</th>
                        <th nowrap='true'>{t('fuel')}</th>
                    </tr>
                    </thead>
                    <tbody>{data ? data.map((item, index)=>
                        <tr key={index}>
                            <td style={{width:'3%'}}  nowrap='true'><div className='table_head_txt'>{t('number')}:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                            <td style={{width:'3%'}} nowrap='true'><div className='table_head_txt'>Id: </div>{item.id}</td>
                            <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('gas_station_name')}: </div>{item.azsName}</td>
                            <td style={{width:'10%'}} nowrap='true' className="address_table">
                                <div className='table_head_txt'>{t('address')}: </div>
                                <span onClick={(e) => { this.onBtnClick1(e, {lat:item.lat, long:item.long} ) }}  data_modal="Map">{item.address}</span>
                            </td>
                            {/*<td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>Վառելիքի տեսակ: </div>*/}
                                {/*{item.fuelType && item.fuelType.length ?*/}
                                    {/*item.fuelType.map((item1, index) => {*/}
                                            {/*if (index !== (item.fuelType.length - 1)) {*/}
                                                {/*return `${item1}, `*/}
                                            {/*}*/} 
                                            {/*else return item1*/}
                                        {/*}*/}
                                    {/*) : ''*/}
                                {/*}*/}
                                {/*</td>*/}
                            <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('tax_code')}։</div>{item.inn}</td>
                            <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('phone')}</div>{item.phone}</td>
                            <td style={{width: '20%'}} nowrap='true'>
                                <div className='table_head_txt'>{t('fuel')}։</div>
                                <Table responsive striped>
                                    <thead>
                                    <tr>
                                        <th nowrap='true'>{t('fuel_type')}</th>
                                        <th nowrap='true'>{t('unit')}</th>
                                        <th nowrap='true'>{t('price')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {item.fuelType && item.fuelType ?
                                        item.fuelType.map(item1 =>
                                            item1.checked?
                                                <tr key={item1.name}>
                                                    <td>
                                                        <div className='table_head_txt'>{t('fuel_type')}։</div>
                                                        {item1.name}</td>
                                                    <td>
                                                        <div className='table_head_txt'>{t('unit')}։</div>
                                                        1 {item1.unit}</td>
                                                    <td>
                                                        <div className='table_head_txt'>{t('price')}։</div>
                                                        {item1.price} AMD</td>

                                                </tr>
                                                :''
                                        ) : false}
                                    </tbody>
                                </Table>
                            </td>
                        </tr>
                    )
                        :false
                    }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data, Count} = state.getTable
    return {
        data,
        Count
    };
}

const connectedRefuelingOperator1Details = connect(mapStateToProps)(withTranslation()(RefuelingOperator1Details));
export { connectedRefuelingOperator1Details as RefuelingOperator1Details };