import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { userActions } from '../../actions/index';
import { withTranslation } from 'react-i18next';
class AddUser extends Component{
    constructor(props){
        super(props)
        this.state = {
            user:{
                firstName:'' ,
                lastName:'',
                patronymic:'',
                soc_card:'',
                position:'',
                auto:'',
                phone:'',
                email:'',
                viewer: ''
            }

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    handleChangeInput(e) {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.AddTable({...user, viewer: !!user.viewer },'add_driver'));
    }
    render() {
        const { user } = this.state;
        const { message } = this.props.alert;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('add_new_user')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">

                    <label className={message&& message.firstName &&'error_lab'}>
                        <span className="label_txt">{t('firstname')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="firstName" value={user.firstName} onChange={this.handleChangeInput}/>
                        {message&& message.firstName && <span className="error_mes">{message.firstName[0]}</span> }
                    </label>
                    <label className={message&& message.lastName &&'error_lab'}>
                        <span className="label_txt">{t('lastname')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="lastName" value={user.lastName} onChange={this.handleChangeInput}/>
                        {message&& message.lastName && <span className="error_mes">{message.lastName[0]}</span> }
                    </label>
                    <label className={message&& message.patronymic &&'error_lab'}>
                        <span className="label_txt">{t('middlename')}</span>
                        <input type="text" className="form-control" name="patronymic" value={user.patronymic} onChange={this.handleChangeInput}/>
                        {message&& message.patronymic && <span className="error_mes">{message.patronymic[0]}</span> }
                    </label>
                    <label className={message&& message.soc_card &&'error_lab'}>
                        <span className="label_txt">{t('soc_card')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="soc_card" value={user.soc_card} onChange={this.handleChangeInput}/>
                        {message&& message.soc_card && <span className="error_mes">{message.soc_card[0]}</span> }
                    </label>
                    <label className={message&& message.position &&'error_lab'}>
                        <span className="label_txt">{t('position')}</span>
                        <input type="text" className="form-control" name="position" value={user.position} onChange={this.handleChangeInput}/>
                        {message&& message.position && <span className="error_mes">{message.position[0]}</span> }
                    </label>
                    <label className={message&& message.auto &&'error_lab'}>
                        <span className="label_txt">{t('car')}</span>
                        <input type="text" className="form-control" name="auto" value={user.auto} onChange={this.handleChangeInput}/>
                        {message&& message.auto && <span className="error_mes">{message.auto[0]}</span> }
                    </label>
                    <label className={message&& message.phone &&'error_lab'}>
                        <span className="label_txt">{t('phone')}</span>
                        <input type="text" className="form-control" name="phone" value={user.phone} onChange={this.handleChangeInput}/>
                        {message&& message.phone && <span className="error_mes">{message.phone[0]}</span> }
                    </label>
                    <label className={message && message.email &&'error_lab'}>
                        <span className="label_txt">{t('email')}<span className="req-star">*</span></span>
                        <input type="text" className="form-control" name="email" value={user.email} onChange={this.handleChangeInput}/>
                        {message&& message.email && <span className="error_mes">{message.email[0]}</span> }
                    </label>
                    {/*<label className={message && message.viewer ? 'checkbox_lab mod1 error_lab' : 'checkbox_lab mod1'}>*/}
                    {/*    <span className="label_txt">{t('viewer')}*</span>*/}
                    {/*    <input type="checkbox" className="form-control" name="viewer" value={user.viewer} onChange={this.handleChangeInput}/>*/}
                    {/*    {message&& message.viewer && <span className="error_mes">{message.viewer[0]}</span> }*/}
                    {/*</label>*/}
                    <label  className={message&& message.viewer &&'error_lab'}>
                        <span className="label_txt">{t('select_user_type')}</span>
                        <select name="viewer" value={user.viewer}  onChange={this.handleChangeInput}>
                            <option value={false}>{t('driver')}</option>
                            <option value={true}>{t('viewer')}</option>
                        </select>
                        {message&& message.viewer && <span className="error_mes">{message.viewer[0]}</span> }
                    </label>

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b" disabled={ this.props.loadingBar && this.props.loadingBar.default}>{t('add')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert, loadingBar} = state;
    return {
        modals,
        loadingBar,
        alert
    };
}

export default connect(mapStateToProps)(withTranslation()(AddUser));
