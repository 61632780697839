
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../../actions/index';
import { alertActions } from '../../../actions/index';
import { userActions, getInfo } from '../../../actions/index';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

const customStyles = {
    container: (base) => ({
        ...base,
        display:'inline-block',
        width: '100%'
    }),
    control: provided => ({
        ...provided,
        minHeight: "30px",
        height: "30px"
    }),
    valueContainer: (base) => ({
        ...base,
        height: "30px",
        padding: "0 8px"
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: "30px"
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
};

class ReturnFunds extends Component{
    constructor(props){
        super(props)
        this.state = {
            user:{
                sellerCorp:'',
                buyerCorp:'',
                fuelType:'',
                fuelAmount:''
            },
            unit:''

        }
        this.handleChangeInput = this.handleChangeInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_admin_transactions_balance({}, "get_admin_transactions_balance"))
    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }
    handleChange = (selectedOption, name) => {
        const { user } = this.state;
        this.setState({
            user: {
                ...user,
                [name]: selectedOption.value
            }
        })
        if(name==='fuelType'){
            this.setState({
                unit: selectedOption.unit
            })
        }
    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { user } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.add_transaction(user, "return_admin_transactions_balance"))
    }
    render() {
        const { data1 } = this.props;
        const { user } = this.state;
        const { message } = this.props.alert;
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('refund')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">
                    <label>
                        <span className="label_txt">{t('select_corporate_client')}</span>
                        <Select
                            className="select-box"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            onChange={e=>this.handleChange(e, 'buyerCorp')}
                            options={data1 && data1.buyerCorp}
                            placeholder={t('select_corporate_client')}
                        />
                    </label>
                    <label>
                        <span className="label_txt">{t('select_supplier')}</span>
                        <Select
                            className="select-box"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            onChange={e=>this.handleChange(e, 'sellerCorp')}
                            options={data1 && data1.sellerCorp}
                            placeholder={t('select_supplier')}
                        />
                    </label>
                    <label>
                        <span className="label_txt">{t('select_fuel_type')}</span>
                        <Select
                            className="select-box"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            onChange={e=>this.handleChange(e, 'fuelType')}
                            options={data1 && data1.fuelTypes}
                            placeholder={t('select_fuel_type')}
                        />
                    </label>
                    <label className={message&& message.fuelAmount &&'error_lab'}>
                        <span className="label_txt">{t('enter_fuel_amount')} {this.state.unit && <span>({this.state.unit})</span>}</span>
                        <input type="text" className="form-control" name="fuelAmount" value={user.fuelAmount} onChange={this.handleChangeInput}/>
                        {message&& message.fuelAmount && <span className="error_mes">{message.fuelAmount[0]}</span> }
                    </label>

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b" disabled={ this.props.loadingBar && this.props.loadingBar.default}>{t('refund')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('cancel')}</button>
                    </div>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert, loadingBar} = state;
    const data1 = state.get_admin_transactions_balance.data1
    return {
        modals,
        alert,
        loadingBar,
        data1
    };
}

export default connect(mapStateToProps)(withTranslation()(ReturnFunds));
