import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  modalActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { Table } from 'reactstrap';
import { Link} from "react-router-dom";
import { withTranslation } from 'react-i18next';

class RefuelingAdmin1 extends Component {

    constructor(props) {
        super(props);
        this.state={
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                pageSize :10,
            },
            removeSearch: false,
            pageRangeDisplayed: 5

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.onBtnClick1 = this.onBtnClick1.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(event) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        });
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_refueling_networks"))
        });

    }
    SortClass(event){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_refueling_networks"))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_refueling_networks"))
    }
    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(getInfo.clear_table())
    }
    onBtnClick(event, data){
        const { dispatch } = this.props;
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_refueling_networks"))
        });
    }
    onBtnClick1(event, data){
        const { dispatch } = this.props;
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    handleSubmit =(event)=>{
        event.preventDefault();
        const {dispatch}= this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_refueling_networks"))
        const { tableData } = this.state;
        if(tableData.searchValue != ''){
            this.setState({
                removeSearch: true
            })
        }
    }
    removeSearch =()=>{
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_refueling_networks"))
        });
    }
    render() {
        const userRole=JSON.parse( localStorage.getItem('userRole'));
        const {data, Count} =this.props;
        const {tableData} =this.state;
		const { t } = this.props;
        return (
            <div className={`main_container ${(userRole !=='cardHolder' && userRole!=='fuelSupervisor' && userRole!=='fuelSupervisorViewer') && 'admin1'}`}>
                <div className="top_container_search">
                    <form onSubmit={this.handleSubmit} className="form_search m_form">
                        <label className="search">
                            <span className="label_txt">{t('search')}</span>
                            <input type="text" value={tableData.searchValue} onChange={this.handleChange} placeholder="" className="form-control"/>
                            {this.state.removeSearch && <span className="remove_search" onClick={this.removeSearch}>X</span> }
                        </label>
                        <button  className="search1">{t('search_now')}</button>
                    </form>
                    {(userRole==='admin1' || userRole==='superAdmin') &&<div className="but_cont_search">
                        <div className="add_but mod_height"  onClick={(e) => { this.onBtnClick(e, '' ) }}  data_modal="AddRefuelingNetwork">{t('add_new_gas_station_chain')}</div>
                        <div className="add_but but2 mod_height"  onClick={(e) => { this.onBtnClick(e, '' ) }}  data_modal="AddRefueling">{t('add_new_gas_station')}</div>
                    </div>}
                </div>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="azsNetworkName">{t('by_gas_station_chain_name')}</option>
                    <option value="azsNetworkNameEnglish">{t('by_gas_station_chain_name_english')}</option>
                    <option value="bankAccount">{t('by_bank_account')}</option>
                    {userRole!=='cardHolder' && <option value="inn">{t('by_taxcode')}</option> }
                    {userRole!=='cardHolder' && <option value="address">{t('by_address')}</option>}
                    <option value="phone">{t('by_phone')}</option>
                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Id</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="azsNetworkName">{t('gas_station_chain_name')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="azsNetworkNameEnglish">{t('gas_station_chain_name_english')}</th>
                        {userRole!=='cardHolder' && <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="bankAccount">{t('bank_account')}</th> }
                        {userRole!=='cardHolder' && <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="inn">{t('tax_code')}</th> }
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="address">{t('address')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="phone">{t('phone')}</th>

                        {(userRole==='admin1' || userRole==='superAdmin') &&<th >{t('action')}</th>}
                    </tr>
                    </thead>
                        <tbody>{data ? data.map((item, index)=>
                            <tr key={index}>
                                <td style={{width:'3%'}}  nowrap='true'><div className='table_head_txt'>{t('number')}:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>Id: </div>{item.id}</td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('gas_station_chain_name')}: </div>{item.azsNetworkName}</td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('gas_station_chain_name_english')}: </div><Link to={`/refuelingDetails/${item.id}`}>{item.azsNetworkNameEnglish}</Link></td>
                                {userRole!=='cardHolder' &&  <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('bank_account')}: </div>{item.bankAccount}</td> }
                                {userRole!=='cardHolder' &&  <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('tax_code')}: </div>{item.inn}</td> }
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('address')}: </div><span className="point_click" onClick={(e) => { this.onBtnClick1(e, item.coordinates)}}  data_modal="MultyMap">{item.address}</span></td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>{t('phone')}</div>{item.phone}</td>
                                {(userRole==='admin1' || userRole==='superAdmin') &&<td style={{width:'10%'}} nowrap='true' className="actions">
                                    <div className='table_head_txt'>{t('action')}:</div>
                                    <span className="edit fa"  onClick={(e) => { this.onBtnClick(e, item ) }}  data_modal="EditRefuelingNetwork"></span>
                                    {!item.deleted_at?<span className="delete fa"  onClick={(e) => { this.onBtnClick(e, item ) }}  data_modal="DeleteRefuelingNetwork" ></span>:''}
                                </td>}
                            </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data, Count} = state.getTable
    return {
        data,
        Count
    };
}

const connectedRefuelingAdmin1 = connect(mapStateToProps)(withTranslation()(RefuelingAdmin1));
export { connectedRefuelingAdmin1 as RefuelingAdmin1 };