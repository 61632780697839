import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  modalActions } from '../../../actions/index';
import {getInfo, userActions} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { Table } from 'reactstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../../../img/loader.gif'
import {userService} from "../../../services";
import setHours from 'date-fns/setHours'
import setMinutes from 'date-fns/setMinutes'
import { withTranslation } from 'react-i18next';

const applicationStatuses = [
    {
        id: 1,
        name: "Ընթացիկ"
    },
    {
        id: 2,
        name: "Հաստատված"
    },
    {
        id: 3,
        name: "Չեղարկված"
    },
    {
        id: 4,
        name: "Մերժված"
    }
]

class Applications extends Component {
    constructor(props) {
        super(props);
        const days =  30;
        const newDate = new Date(Date.now() - days*24*60*60*1000);
        this.state = {
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                fuelBidStatusId:'',
                from: newDate,
                to:null,
                pageSize :10
            },
            removeSearch: false,
            pageRangeDisplayed: 5,
            loader: false,
            validationStation: true

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(e) {
        const { name, value } = e.target;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                [name]: value,
            }
        });
    }

    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_bids"))
        });

    }
    SortClass(){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');
    };
    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_bids"))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        // dispatch(getInfo.GetUsersTable(this.state.tableData))
        dispatch(getInfo.getTable(this.state.tableData, "get_bids"))
    }
    onBtnClick(event, data){
        const { dispatch } = this.props;
        // $(this).parent().parent()
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_bids"))
        });
    }
    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(getInfo.clear_table())
    }
    onChangeDate1 = (date) => {
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                from: date
            },

        })
    }
    onChangeDate2 = (date) => {
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                to: date
            },

        })
    }
    handleSubmit =(event)=>{
        event.preventDefault();
        const {dispatch}= this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_bids"))
        const { tableData } = this.state;
        if(tableData.searchValue !== ''){
            this.setState({
                removeSearch: true
            })
        }
    }
    removeSearch =()=>{
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_bids"))
        });
    }
    downloadExcelTransactions = (method, name, data) =>{
        this.setState({loader: true})
        userService.downloadExcel(method, data).then(response => {
            if(response.ok){
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = `${name}.xlsx`;
                    a.click();
                    this.setState({loader: false})
                })
            }
            else{
                this.setState({loader: false})
                this.props.dispatch(modalActions.open_modal('Fail_popup','Bad request'));
            }

            //window.location.href = response.url;
        });

    }
    componentWillUnmount() {
        const {dispatch} = this.props;
        dispatch(getInfo.clear_table())
    }
    render() {
        const {data, Count} =this.props;
        const {tableData} =this.state;
		const { t } = this.props;
        const userRole = JSON.parse( localStorage.getItem('userRole'));
        return (
            <div className={userRole!=='operator1'
            && userRole!=='cardHolder'
            && userRole!=='fuelSupervisor'
            && userRole!=='operator2'
            && userRole!=='operator1Viewer'
            && userRole!=='operator2Viewer'
                ?"main_container admin1":"main_container"
            }>
                <div className="top_container_search">
                    <form onSubmit={this.handleSubmit} className="form_search m_form mod_transactions">
                        <label className="search">
                            <span className="label_txt">{t('search')}</span>
                            <input type="text" name="searchValue" value={tableData.searchValue} onChange={this.handleChange} placeholder="" className="form-control"/>
                            {this.state.removeSearch && <span className="remove_search" onClick={this.removeSearch}>X</span> }
                        </label>
                        <label className="mrg_l">
                            <span className="label_txt">{t('select_application_status')}</span>
                            <select name="fuelBidStatusId" value={tableData.fuelBidStatusId }  onChange={this.handleChange} >
                                <option value="" >{t('select_application_status')}</option>
                                {applicationStatuses ?
                                    applicationStatuses.map(item=>
                                        <option value={item.id} key={item.id}>{item.name}</option>
                                    )
                                :''}

                            </select>
                        </label>
                        <div className="calendar_cont mrg_l" >
                            <span className="label_txt">{t('start')}</span>
                            <DatePicker
                                selected={tableData.from}
                                onChange={date => this.onChangeDate1(date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy HH:mm aa"
                                injectTimes={[
                                    setHours(setMinutes(new Date(), 59), 23)
                                ]}
                                // showTimeInput
                                // customTimeInput={<ExampleCustomTimeInput />}
                            />
                        </div>
                        <div className="calendar_cont mrg_l" >
                            <span className="label_txt">{t('end')}</span>

                            <DatePicker
                                selected={tableData.to}
                                onChange={date => this.onChangeDate2(date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy HH:mm"
                                injectTimes={[
                                    setHours(setMinutes(new Date(), 59), 23)
                                ]}
                                // showTimeInput
                                // customTimeInput={<ExampleCustomTimeInput />}
                            />

                        </div>

                        <button  className="search1">{t('search')}</button>
                        <div className="add_but exel_but_trans"  onClick={()=>this.downloadExcelTransactions("get_fuel_bid_report", t('report'), this.state.tableData)}>{t('report')}</div>
                        {(userRole !== 'admin2' && userRole !== 'admin2Viewer' && userRole !== 'operator1Viewer') &&
                            <div className="add_but exel_but_trans"
                                 onClick={(e) => {
                                     this.onBtnClick(e, {size: "large"})
                                 }} data_modal="AddNewApplication">{t('new_application')}</div>
                        }
                    </form>
                </div>


                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="id">{t('application_number')}</option>
                    <option value="create_date">{t('application_date')}</option>
                    <option value="name">{t('supplier')}</option>
                    <option value="tax_id">{t('tax_code')}</option>
                    {(userRole === 'admin2' || userRole === 'admin2Viewer') && <>
                        <option value="address">{t('address')}</option>
                        <option value="email">{t('email')}</option>
                        <option value="phone">{t('phone')}</option>
                    </>

                    }

                </select>

                <Table responsive striped>
                    <thead>
                    <tr>
                        <th  className="clickable" onClick={this.handleSort} data-val="id">{t('application_number')}</th>
                        <th  className="clickable" onClick={this.handleSort} data-val="create_date">{t('application_date')}</th>
                        <th  className="clickable" onClick={this.handleSort} data-val="name">{t('supplier')}</th>
                        <th  className="clickable" onClick={this.handleSort} data-val="tax_id">{t('tax_code')}</th>
                        {(userRole === 'admin2' || userRole === 'admin2Viewer') && <>
                            <th  className="clickable" onClick={this.handleSort} data-val="address">{t('address')}</th>
                            <th  className="clickable" onClick={this.handleSort} data-val="email">{t('email')}</th>
                            <th  className="clickable" onClick={this.handleSort} data-val="phone">{t('phone')}</th>
                        </>}

                        <th>{t('fuel_type')}</th>
                        <th >{t('role')}</th>
                        <th >{t('action')}</th>
                    </tr>
                    </thead>
                        <tbody>{data && data.length ?  data.map( (item, index)=>
                           <tr>
                                <td style={{width:'3%'}}  nowrap='true'><div className='table_head_txt'>{t('number')}:</div>
                                    {item.id}</td>
                                <td style={{width:'7%'}}><div className='table_head_txt'>{t('application_date')}</div> {item.create_date}</td>
                                <td style={{width:'7%'}}><div className='table_head_txt'>{t('supplier')}</div> {item.name}</td>
                                <td style={{width:'7%'}}><div className='table_head_txt'>{t('tax_code')}</div> {item.tax_id}</td>
                               {(userRole === 'admin2' || userRole === 'admin2Viewer') && <>
                                   <td style={{width:'7%'}}><div className='table_head_txt'>{t('address')}</div>
                                       {item.address}</td>
                                   <td style={{width:'7%'}}><div className='table_head_txt'>{t('email')}</div> {item.email}</td>
                                   <td style={{width:'7%'}}><div className='table_head_txt'>{t('phone')}</div> {item.phone}</td>
                               </>}

                               <td style={{width: '25%'}} nowrap='true'>
                                   <div className='table_head_txt'>{t('fuel_type')}։</div>
                                   <Table responsive striped>
                                       <thead>
                                       <tr>
                                           <th nowrap='true'>{t('fuel_type')}</th>
                                           <th nowrap='true'>{t('volume')}</th>
                                           <th nowrap='true'>{t('price')}</th>
                                           <th nowrap='true'>{t('amount')}</th>
                                       </tr>
                                       </thead>
                                       <tbody>
                                       {item && item.fuelbiddetails && item.fuelbiddetails.length ?
                                           item.fuelbiddetails.map(item1 =>
                                               <tr key={item1.fuel_type_id}>
                                                   <td>
                                                       <div className='table_head_txt'>{t('fuel_type')}։</div>
                                                       {item1.name}</td>
                                                   <td>
                                                       <div className='table_head_txt'>{t('fuel_type')}։</div>
                                                       {item1.fuel_volume} {item1.unit}</td>
                                                   <td>
                                                       <div className='table_head_txt'>{t('daily_limit')}։</div>
                                                       <span style={{whiteSpace:'nowrap'}}>{item1.discount_price} AMD</span></td>
                                                   <td>
                                                       <div className='table_head_txt'>{t('one_time_limit')}։</div>
                                                       <span style={{whiteSpace:'nowrap'}}>{item1.total_price} AMD</span>

                                                   </td>
                                               </tr>
                                           ) : false}
                                       </tbody>
                                   </Table>
                                   <div>{t('total_price')}: {item.total_amount}</div>
                               </td>
                               <td style={{width:'7%'}}><div className='table_head_txt'>{t('role')}</div>
                                   {item.fuel_bid_status}</td>
                               <td style={{width: '20%'}} nowrap='true' className="actions mod1">
                                   <div className='table_head_txt'>{t('action')}:</div>
                                   {(userRole==='admin2') && item.fuel_bid_status === "Ընթացիկ" &&
                                       <div className="but_table" onClick={(e) => {
                                           this.onBtnClick(e, item)
                                       }} data_modal="ApproveConfirmation">{t('approve')}
                                       </div>
                                   }
                                   {item.fuel_bid_status === "Ընթացիկ" && userRole !== 'operator1Viewer' && userRole !== 'admin2Viewer' &&
                                       <div className="but_table but1" onClick={(e) => {
                                           this.onBtnClick(e, item)
                                       }} data_modal="CancellationConfirmation">{(userRole==='admin2' || userRole === 'admin2Viewer') ?  t('decline') : t('cancel')}
                                       </div>
                                   }

                               </td>
                           </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>
                {this.state.loader && <div className="loader"><img src={Loader} alt=""/></div>}
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data, Count} = state.getTable;
    return {
        data,
        Count
    };
}

const connectedApplications = connect(mapStateToProps)(withTranslation()(Applications));
export { connectedApplications as Applications };