import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  modalActions } from '../../../actions/index';
import {getInfo} from '../../../actions/index';
import $ from "jquery";
import Pagination from "react-js-pagination";
import Avatar from '../../../img/avatar.svg';
import { Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import * as moment from "moment";

class Cards2 extends Component {

    constructor(props) {
        super(props);
        this.state={
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1,
                pageSize :10,
            },
            removeSearch: false,
            pageRangeDisplayed: 5,

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)

    }
    handleChange(event) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        }, function () {
            // const _this=this
            // setTimeout(function () {
            //     dispatch(getInfo.GetGroupTable(_this.state.tableData))
            // }, 500)
        });
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_operator1_not_attached_cards"))
        });

    }
    SortClass(event){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_operator1_not_attached_cards"))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_operator1_not_attached_cards"))
    }

    onBtnClick(event, data){
        const { dispatch } = this.props;
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_operator1_not_attached_cards"))
        });
    }
    handleSubmit =(event)=>{
        event.preventDefault();
        const {dispatch}= this.props;
        dispatch(getInfo.getTable(this.state.tableData, "get_operator1_not_attached_cards"))
        const { tableData } = this.state;
        if(tableData.searchValue != ''){
            this.setState({
                removeSearch: true
            })
        }
    }
    removeSearch =()=>{
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: ''
            },
            removeSearch: false
        }, function () {
            dispatch(getInfo.getTable(this.state.tableData, "get_operator1_not_attached_cards"))
        });
    }
    componentWillUnmount(){
        const { dispatch } = this.props;
        dispatch(getInfo.clear_table())
    }
    render() {
        const {data, Count} =this.props;
        const {tableData} =this.state;
		const { t } = this.props;
        const userRole = JSON.parse( localStorage.getItem('userRole'));
        return (
            <div>
                <form onSubmit={this.handleSubmit} className="form_search m_form">
                    <label className="search">
                        <span className="label_txt">{t('search')}</span>
                        <input type="text" value={tableData.searchValue} onChange={this.handleChange} placeholder="" className="form-control"/>
                        {this.state.removeSearch && <span className="remove_search" onClick={this.removeSearch}>X</span> }
                    </label>
                    <button  className="search1">{t('search_now')}</button>
                </form>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">{t('sort_by')}</option>
                    <option value="card">{t('by_card')}</option>
                    <option value="endDate">{t('by_end_date')}</option>
                </select>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="card">{t('card')}</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="endDate">{t('end_date')}</th>
                        { userRole !== 'operator1Viewer' && <th >{t('action')}</th> }
                    </tr>
                    </thead>
                        <tbody>
                        {data ? data.map((item, index)=>
                            <tr key={index}>
                                <td style={{width:'5%'}}  nowrap='true'><div className='table_head_txt'>{t('number')}:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                                <td style={{width:'45%'}}  nowrap='true'><div className='table_head_txt'>{t('card')}:</div> {item.card} </td>
                                <td style={{width:'45%'}}  nowrap='true'><div className='table_head_txt'>{t('end_date')}:</div>{item.endDate}</td>

                                {userRole !== 'operator1Viewer' &&
                                    <td style={{width: '10%'}} nowrap='true' className="actions">
                                        <div className='table_head_txt'>{t('action')}:</div>
                                        <span className="but_table" onClick={(e) => {
                                            this.onBtnClick(e, {item: item, size: "large"})
                                        }} data_modal="AttachCard">{t('attach_card')}</span>
                                    </td>
                                }
                            </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>{t('first')}</i>}
                            lastPageText={<i className='b_link1'>{t('last')}</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''}
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
    const {data, Count} = state.getTable
    return {
        data,
        Count
    };
}

export default connect(mapStateToProps)(withTranslation()(Cards2));