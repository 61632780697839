import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions } from '../../actions/index';
import { alertActions } from '../../actions/index';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import {withTranslation} from "react-i18next";
import {BiMailSend} from "react-icons/bi";
import { GiPositionMarker } from "react-icons/gi";

const customIcon = {
    url: BiMailSend,
};
const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        center={!props.center ? { lat: 40.177200, lng: 44.503490 } : props.center}
        defaultZoom={13}
        defaultCenter={ !props.center ? { lat: 40.177200, lng: 44.503490 } : props.center}
    >
        {props.isMarkerShown && props.markers && props.markers.length ? props.markers.map(item=><Marker onClick={()=>props.onMarkerClick({lat:item.lat, lng: item.lng })} key={item.lat} position={{ lat: item.lat, lng: item.lng }} />):'' }
        {props.center && props.markers && props.markers.length ? props.markers.map(item=><Marker icon={'http://maps.google.com/mapfiles/ms/micons/green-dot.png'}   position={props.center} />):'' }
    </GoogleMap>
))

class MultyMap extends Component {
    constructor(props) {
        super(props)
        const Content = this.props.modals.data;
        this.state = {
            coordinates: Content,
            userLocation: null, // Track user's current location
        }

        this.close_modal = this.close_modal.bind(this)
        this.showUserLocation = this.showUserLocation.bind(this)
    }

    componentDidMount() {
        // Get the user's current location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                this.setState({ userLocation });
            });
        }
    }

    showUserLocation() {
        // Center the map on the user's current location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                this.setState({ userLocation });
            });
        }
    }

    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal());
        dispatch(alertActions.clear());
    }
    handleMarkerClick = (marker) => {
        // Open navigation to the clicked marker
        window.open(`https://www.google.com/maps/dir/?api=1&origin=${this.state.userLocation.lat},${this.state.userLocation.lng}&destination=${marker.lat},${marker.lng}`);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="modal_head">Լցակայաներ</div>
                <form className="m_form d-flex flex-column">
                    <MyMapComponent
                        isMarkerShown
                        markers={this.state.coordinates}
                        center={this.state.userLocation} // Center the map on user's current location
                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAsVcgGr7EfTA_V-qclc8Q5m6euDrSoZt0"
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        onMarkerClick={this.handleMarkerClick}
                    />
                    <button type="button" className="btn btn-primary block m-b" style={{ marginTop: "10px" }} onClick={this.showUserLocation}>{t('show_my_location')}<GiPositionMarker style={{marginLeft:'6px'}} size={'20'}/></button>
                    <button type="button" className="btn btn-grey block m-b" style={{ marginTop: "25px" }} onClick={this.close_modal}>{t('close')}</button>
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    return {
        modals,
        alert
    };
}
export default connect(mapStateToProps)(withTranslation()(MultyMap));