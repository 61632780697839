import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalActions, getInfo, alertActions, userActions } from '../../../actions/index';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';
const customStyles = {
    container: (base) => ({
        ...base,
        display:'inline-block',
        width: '100%'
    }),
    control: provided => ({
        ...provided,
        minHeight: "30px",
        height: "30px"
    }),
    valueContainer: (base) => ({
        ...base,
        height: "30px",
        padding: "0 8px"
    }),
    indicatorsContainer: provided => ({
        ...provided,
        height: "30px"
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: "5px"
    }),
    input: base => ({
        ...base,
        margin: 0,
        padding: 0
    })
};
class AttachCardAdmin1 extends Component{
    constructor(props){
        super(props)
        const EditContent = this.props.modals.data;
        this.state = {
            cartType:EditContent,
            attachCard:{
                 cards:[],
                 client:''
            }

        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.close_modal = this.close_modal.bind(this)
    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getInfo.get_admin_attach_card("", "get_admin_attach_card"))
    }
    componentWillReceiveProps(props){
        if(props.data1){
            const { attachCard } = this.state;
            this.setState({
                attachCard: {
                    ...attachCard,
                    cards: props.data1.cards,
                }
            });
        }
    }
    handleChange = (selectedOption) => {
        const { attachCard } = this.state;
        this.setState({
            attachCard: {
                ...attachCard,
                client: selectedOption.value
            }
        })
    }
    handleChangeMulty =(e) => {
        const target = e.target;
        const name = target.name;
        const { attachCard } = this.state;
        let cards = attachCard.cards.map((item)=>{
            if(item.name == name){
                return  {...item, checked: !item.checked}
            }
            else return item
        })
        this.setState({
            attachCard: {
                ...attachCard,
                cards: cards
            }

        });

    }
    close_modal() {
        const { dispatch } = this.props;
        dispatch(modalActions.close_modal())
        dispatch(alertActions.clear())
    }
    handleSubmit(event) {
        event.preventDefault();
        const { attachCard } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.attach_admin_card({info:attachCard, cartType: this.state.cartType}, 'attach_admin_card'));
    }
    render() {
        const { attachCard } = this.state;
        const { message } = this.props.alert;
        const userRole = JSON.parse( localStorage.getItem('userRole'));
		const { t } = this.props;
        return (
            <div>
                <div className="modal_head">{t('attach_card')}</div>
                <form onSubmit={this.handleSubmit} className="m_form d-flex flex-column">



                    <div className="multy_check">
                        <span classNames="label_txt">{t('choose_cards')}</span>
                        {attachCard.cards.map(
                        (item, index)=>
                            <label className='checkbox_lab' key={item.id}>
                                <input type="checkbox" className="form-control" name={item.name}  checked={item.checked} onChange={this.handleChangeMulty}/>
                                <span className="label_txt">{item.name}</span>
                            </label>
                        )}

                    </div>
                    <label>
                        <span className="label_txt"> {userRole ==='operator2' ? t('choose_branch') : t('choose_corporate_client')}  </span>
                        <Select
                            // value={selectedOption}
                            className="select-box"
                            classNamePrefix="react-select"
                            styles={customStyles}
                            onChange={this.handleChange}
                            options={this.props.data1 && this.props.data1.clients}
                            placeholder= {userRole ==='operator2' ? t('choose_branch') : t('choose_corporate_client')}
                        />
                    </label>

                    <div className="but_cont">
                        <button type="submit" className="btn btn-primary block  m-b">{t('assign')}</button>
                        <button type="button" className="btn btn-grey block  m-b"  onClick={this.close_modal}>{t('close')}</button>
                    </div>
                    {message && <div className="global_error">{message.error_description}</div>}
                </form>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {modals, alert} = state
    const data1 =  state.get_admin_attach_card.data1
    return {
        modals,
        alert,
        data1
    };
}

export default connect(mapStateToProps)(withTranslation()(AttachCardAdmin1));
